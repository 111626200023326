// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-translate-instructions-___common__instructions{color:#333333}.src-components-shared-translate-instructions-___common__instructions header{color:#7D7D7D;font-size:1rem;font-weight:500;line-height:1rem}.src-components-shared-translate-instructions-___common__instructions header img{margin-right:0.5rem;vertical-align:bottom}.src-components-shared-translate-instructions-___common__instructions>*:not(:last-child){margin-bottom:1rem}.src-components-shared-translate-instructions-___common__instructions>* img{margin-top:1rem}.src-components-shared-translate-instructions-___common__instructions>* img.src-components-shared-translate-instructions-___common__icon{margin:0 0.5rem;vertical-align:bottom}
`, "",{"version":3,"sources":["webpack://./src/components/shared/translate-instructions/common.scss"],"names":[],"mappings":"AAGA,sEACE,aAAc,CADhB,6EAII,aAAc,CACd,cAAe,CACf,eAAgB,CAChB,gBAAiB,CAPrB,iFAUM,mBAAoB,CACpB,qBAAsB,CAX5B,yFAgBI,kBAAmB,CAhBvB,4EAqBM,eAAgB,CArBtB,yIAyBM,eAAgB,CAChB,qBAAsB","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.instructions {\n  color: #333333;\n\n  header {\n    color: #7D7D7D;\n    font-size: 1rem;\n    font-weight: 500;\n    line-height: 1rem;\n\n    img {\n      margin-right: 0.5rem;\n      vertical-align: bottom;\n    }\n  }\n\n  > *:not(:last-child) {\n    margin-bottom: 1rem;\n  }\n\n  > * {\n    img {\n      margin-top: 1rem;\n    }\n\n    img.icon {\n      margin: 0 0.5rem;\n      vertical-align: bottom;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructions": `src-components-shared-translate-instructions-___common__instructions`,
	"icon": `src-components-shared-translate-instructions-___common__icon`
};
export default ___CSS_LOADER_EXPORT___;
