import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './disclaimer-body.scss';

class DisclaimerText extends PureComponent {
  render() {
    return (
      <span className={styles.disclaimer}>
        <span><span translate="no">ChiStreetWork</span> is the City of Chicago’s infrastructure map portal. The application was created to help 
        facilitate project coordination between city agencies and utilities, and to provide city residents with 
        valuable information about various construction activities and street impacts throughout the City. These 
        activities include construction projects, special events, and roadway moratoriums. The <span translate="no">ChiStreetWork</span> is hosted 
        by the Chicago Department of Transportation, based on data provided by city agencies, and 
        private utilities.</span>
        <span>The site was developed by the City of Chicago and is provided solely for informational purposes. 
        The City makes no representation as to the accuracy, completeness, of the information or its suitability 
        for any purpose. The user agrees that the use of this map portal is undertaken entirely at his own risk. 
        The City disclaims any liability for errors. To the full extent permissible by Federal, State, and Local 
        law, the City disclaims all warranties, express or implied, including, but not limited to, implied warranties 
        or merchantability and fitness for a particular purpose as to the quality, content, accuracy, or completeness 
        of the information, text graphics, links and other items contained on this product. Materials are subject to 
        change without notice from the City. In no event will the City be liable for damages of any kind, 
        including but not limited to indirect, special, consequential, or punitive damages, loss of data, lost 
        profits, business interruption, loss of business information or other pecuniary loss that might arise from the 
        use of this map or the information it contains or may omit.</span>
      </span>
    );
  }
}

DisclaimerText.propTypes = {
  mapRef: PropTypes.object
};

export default DisclaimerText;
