import {
  FILTERS_FETCH_SUCCESS,
  DATE_RANGE_FETCH_SUCCESS,
  SELECT_DATE_RANGE,
  SET_INITIAL_URL_FILTER,
  CLEAR_INITIAL_URL_FILTER
} from '../constants/action-types';
import initialState from '../store/initial-state';

const dialogReducer = (state = initialState.filters, action) => {
  switch (action.type) {
  case FILTERS_FETCH_SUCCESS: {
    return {...state, layers: action.payload};
  }
  case DATE_RANGE_FETCH_SUCCESS: {
    return {...state, dateRanges: action.payload, selectedDateRange: 0};
  }
  case SELECT_DATE_RANGE: {
    return {...state, selectedDateRange: action.index};
  }
  case SET_INITIAL_URL_FILTER: {
    return { ...state, initialUrlFilter: true };
  }
  case CLEAR_INITIAL_URL_FILTER: {
    return { ...state, initialUrlFilter: false };
  }
  default:
    return state;
  }
};

export default dialogReducer;
