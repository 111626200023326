import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import LargeMedia from '../../shared/media/large';
import SmallMedia from '../../shared/media/small';

import { openTranslateDialog } from '../../../actions/ui-actions';

import styles from './translate.scss';

class TranslateButton extends Component {
  render() {
    return (
      <Fragment>
        <LargeMedia>
          <Tooltip title="translate" placement="bottom">
            <Button
              onClick={this.props.openTranslateDialog}
              classes={{ root: classNames(styles.translateButton) }}
            >
              <Icon translate="no" classes={{ root: classNames(styles.translateIcon) }}>translate</Icon>Translate
            </Button>
          </Tooltip>
        </LargeMedia>
        <SmallMedia>
          <Button
            onClick={this.props.openTranslateDialog}
            classes={{ root: classNames(styles.translateButton, styles.translateButtonMobile) }}
          >
            <Icon translate="no">translate</Icon>
          </Button>
        </SmallMedia>
      </Fragment>
    );
  }
}

TranslateButton.propTypes = {
  openTranslateDialog: PropTypes.func
};


export default connect(null, { openTranslateDialog })(TranslateButton);
