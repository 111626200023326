import {
  GEOCODE_SUGGESTION_FETCH_SUCCESS,
  PLACES_SUGGESTION_FETCH_SUCCESS,
  SET_SEARCH_SUGGESTION_TERM,
  SET_SEARCH_SELECTION,
  CLEAR_SEARCH_SUGGESTIONS,
  SET_SEARCH_MARKER,
  SET_SEARCH_FOCUSED,
  SET_SELECTED_LAYER_ELEMENT
} from '../constants/action-types';
import initialState from '../store/initial-state';

const dialogReducer = (state = initialState.search, action) => {
  switch (action.type) {
  case GEOCODE_SUGGESTION_FETCH_SUCCESS: {
    return { ...state, suggestions: { ...state.suggestions, geocoder: action.suggestions } };
  }
  case PLACES_SUGGESTION_FETCH_SUCCESS: {
    return { ...state, suggestions: { ...state.suggestions, places: action.suggestions } };
  }
  case SET_SEARCH_SUGGESTION_TERM: {
    const { searchTerm } = action;
    return { ...state, searchTerm };
  }
  case CLEAR_SEARCH_SUGGESTIONS: {
    return { ...state, searchTerm: null, suggestions: { geocoder: [], places: [] } };
  }
  case SET_SEARCH_MARKER: {
    return { ...state, showMarker: action.visible };
  }
  case SET_SEARCH_FOCUSED: {
    return { ...state, focused: action.focused };
  }
  case SET_SEARCH_SELECTION: {
    return { ...state, selectedLocation: action.location };
  }
  case SET_SELECTED_LAYER_ELEMENT: {
    return { ...state, selectedLayerElement: action.element };
  }
  default:
    return state;
  }
};

export default dialogReducer;
