import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import DotMapsOverlayView from '../../shared/dotmaps-google-map/dotmaps-overlay-view';
import { setCenter, setUserLocation } from '../../../actions/map-actions';
import { getUserLocation } from '../../../selectors/map';
import {setSearchFocused} from '../../../actions/search-actions';
import SmallMedia from '../../shared/media/small';
import LargeMedia from '../../shared/media/large';

import styles from './location-button.scss';

class LocationButton extends Component {
  handleClick = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      this.props.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
      this.props.setUserLocation({lat: pos.coords.latitude, lng: pos.coords.longitude});
      this.props.setSearchFocused(false);
    });
  };

  render() {
    return (
      <Fragment>
        <div
          className={!this.props.search ? styles.button : styles.searchEmbededButton}
          onClick={event => this.handleClick(event)}
        >
          <SmallMedia>
            <div className={this.props.userLocation ? styles.selected : null}><Icon translate="no">my_location</Icon></div>
          </SmallMedia>
          <LargeMedia>
            <Tooltip title="Use my location" placement="bottom">
              <div className={this.props.userLocation ? styles.selected : null}><Icon translate="no">my_location</Icon></div>
            </Tooltip>
          </LargeMedia>
          
        </div>
        {this.props.userLocation &&
          <DotMapsOverlayView
            mapPaneName={'floatPane'}
            position={this.props.userLocation}
            zIndex={20000}
            className={styles.overlay}
          >
            <div className={styles.locationMarker} role="presentation" />
          </DotMapsOverlayView>
        }
      </Fragment>
    );
  }
}

LocationButton.propTypes = {
  mapRef: PropTypes.object,
  search: PropTypes.bool,
  setCenter: PropTypes.func,
  setSearchFocused: PropTypes.func,
  setUserLocation: PropTypes.func,
  userLocation: PropTypes.object
};

const mapStateToProps = state => {
  return {
    userLocation: getUserLocation(state)
  };
};

export default connect(mapStateToProps, {setCenter, setUserLocation, setSearchFocused})(LocationButton);
