/* eslint-disable  */
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Polygon } from 'react-google-maps';

import Point from './point';
import LineSegment from './line-segment';

export default class MapFigure extends PureComponent {
  getPolygonHighlight = () => {
    const { mapStyle } = this.props;
    if (!this.props.highlightable) {
      return mapStyle;
    }
    return { ...mapStyle, fillOpacity: 0.4, strokeWeight: 4};
  }
  render() {
    const { mapElement, clusterSelection, mapStyle, activeMarker, marker, highlighted, selected } = this.props;
    const { id, shape: {type} = {}, position, path, paths, childElements, center } = mapElement;
    const highlight = highlighted || selected; // highlight if highlighted or selected
    let markerIcon = null;
    if (
      (!clusterSelection) ||
      (clusterSelection && clusterSelection.selectionCount == 1 && selected) ||
      highlighted
    ) {
      // Strictly show clustered maker based on highlighted (without selected)
      markerIcon = highlight ? activeMarker : marker
    }
    return (
      <Fragment>
        {type === 'Point' && markerIcon &&
          <Point
            key={`${id}-point`}
            position={position}
            icon={markerIcon}
            options={mapStyle}
            highlighted={highlight}
          />
        }
        {type === 'LineString' &&
          <LineSegment
            key={`${id}-line`}
            path={path}
            options={mapStyle}
            highlighted={highlight}
          />
        }
        {type === 'LineString' && center && markerIcon &&
          <Point
            key={`${id}-point`}
            position={center}
            icon={markerIcon}
            options={mapStyle}
            highlighted={highlight}
          />
        }
        {type === 'Polygon' &&
          <Polygon
            key={`${id}-polygon`}
            paths={paths}
            options={highlight ? this.getPolygonHighlight() : mapStyle}
          />
        }
        {type === 'Polygon' && mapStyle && mapStyle.hasOwnProperty('icons') &&
          <LineSegment
            key={`${id}-outline`}
            path={paths[0]}
            options={mapStyle}
            highlighted={highlight}
          />
        }
        {childElements &&
          childElements.map((mapElement, index) => (
            <MapFigure
              key={`${id}-collection-${index}`}
              {...this.props}
              mapElement={mapElement}
            />
          ))
        }
        { /*
            React-fiber beceomes extreemly unresponsive if we mount many components that do not conatain dom elements
            We put this here to make it more responsive
            The application still stalls for 5 seconds or so after adding many map elements,
            but this is better than the > 20 seconds otherwise
          */
          <div/>
        }
      </Fragment>
    );
  }
}

MapFigure.propTypes = {
  highlighted: PropTypes.bool,
  activeMarker: PropTypes.object,
  layerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mapElement: PropTypes.object,
  mapStyle: PropTypes.object,
  marker: PropTypes.object,
  selected: PropTypes.bool,
  clusterSelection: PropTypes.object
};
