import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import DrawIcon from '../draw-icon';
import MapDrawingTools from './map-drawing-tools';
import NotificationIcon from '../../notifications/notification-icon';

import { getDrawingMode, getMeasureToolActive } from '../../../selectors/map';
import { getNotificationsVisible } from '../../../selectors/notifications';
import { setDrawingMode, setMeasureActive, setMeasureLength } from '../../../actions/map-actions';
import { openNotifications, closeNotifications } from '../../../actions/notification-actions';
import NotificationTooltip from '../../notifications/notification-tooltip';

import MeasureTool from 'measuretool-googlemaps-v3/lib/MeasureTool';
import { MAP } from 'react-google-maps/lib/constants';

import styles from './map-tools.scss';

class MapTools extends Component {
  state = { measureTool: null };

  componentDidMount() {
    // though the library has an option to set showAccumulativeLength false, doing so breaks it.
    // instead of just hiding it, it no longer does the calculations or stores the length at all
    // so I'm leaving it true and hiding it with css instead
    const measureTool = new MeasureTool(this.props.mapRef.context[MAP], {
      showSegmentLength: false,
      showAccumulativeLength: true,
      tooltip: false,
      contextMenu: false,
      unit: 'IMPERIAL'
    });
    measureTool.addListener('measure_change', () => {
      this.props.setMeasureLength(measureTool.lengthText);
    });
    this.setState({measureTool});
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.measureActive && !nextProps.measureActive) {
      this.state.measureTool.end();
    }
    if (!this.props.measureActive && nextProps.measureActive) {
      this.state.measureTool.start();
    }
  }
  setPolygon = () => {
    if (this.props.drawingMode === 'polygon') {
      this.clearSelection();
    } else {
      this.props.setDrawingMode('polygon');
    }
  };
  setCircle = () => {
    if (this.props.drawingMode === 'circle') {
      this.clearSelection();
    } else {
      this.props.setDrawingMode('circle'); 
    }
  };

  clearSelection = () => {
    this.props.setDrawingMode('');
  };

  setDrawingMode = (mode) => {
    this.props.setDrawingMode(mode);
  };

  setMeasurement = () => {
    if (this.props.measureActive) {
      this.props.setMeasureActive(false);
    } else {
      this.props.setMeasureActive(true);
    }
  };
 
  render() {
    const { drawingMode, measureActive, notificationsVisible } = this.props;
    const notificationButtonAction = notificationsVisible ? this.props.closeNotifications : this.props.openNotifications;
    return (
      <div>
        <div className={styles.buttonGroup}>
          <Tooltip title="Notifications" placement="bottom">
            <div className={styles.buttonWrap}>
              <Button classes={{root: styles.left}} onClick={notificationButtonAction}>
                <div className={notificationsVisible ? styles.selected : null}><NotificationIcon selected={notificationsVisible} /></div>
              </Button>
              <NotificationTooltip />
            </div>
          </Tooltip>
          <Tooltip title="Radius search" placement="bottom">
            <Button onClick={this.setCircle}>
              <div className={drawingMode === 'circle' ? styles.selected : null}><Icon translate="no">filter_tilt_shift</Icon></div>
            </Button>
          </Tooltip>
          <Tooltip title="Ruler" placement="bottom">
            <Button onClick={this.setMeasurement}>
              <div className={measureActive ? styles.selected : null}><Icon translate="no">straighten</Icon></div>
            </Button>
          </Tooltip>
          <Tooltip title="Area search" placement="bottom">
            <Button classes={{root: styles.right}} onClick={this.setPolygon}>
              <DrawIcon selected={drawingMode === 'polygon'} />
            </Button>
          </Tooltip>
        </div>
        <MapDrawingTools mapRef={this.props.mapRef} />
      </div>
    );
  }
}

MapTools.propTypes = {
  closeNotifications: PropTypes.func,
  drawingMode: PropTypes.string,
  mapRef: PropTypes.object,
  measureActive: PropTypes.bool,
  mode: PropTypes.string,
  notificationsVisible: PropTypes.bool,
  openNotifications: PropTypes.func,
  setDrawingMode: PropTypes.func,
  setMeasureActive: PropTypes.func,
  setMeasureLength: PropTypes.func
};

const mapStateToProps = state => {
  return {
    measureActive: getMeasureToolActive(state),
    drawingMode: getDrawingMode(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, {
  setDrawingMode,
  setMeasureActive,
  setMeasureLength,
  openNotifications,
  closeNotifications
})(MapTools);
