// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-map-legend-mobile-___map-legend-mobile__legend-title{height:1.5em;padding:1em 0.75em 0;color:rgba(0,0,0,0.8)}.src-components-map-map-legend-mobile-___map-legend-mobile__close-button{position:absolute;font-size:1rem;right:0.5em;margin:0.25em;color:#666666}@media only screen and (min-device-width: 768px){.src-components-map-map-legend-mobile-___map-legend-mobile__close-button{top:0.5em}}
`, "",{"version":3,"sources":["webpack://./src/components/map/map-legend/mobile/map-legend-mobile.scss"],"names":[],"mappings":"AAGA,yEACE,YAAa,CACb,oBAAqB,CACrB,qBAAsB,CACvB,yEAGC,iBAAkB,CAClB,cAAe,CACf,WAAY,CACZ,aAAc,CACd,aAAc,CACd,iDANF,yEAOI,SAAU,CAEb","sourcesContent":["@import '../../../../styles/mixins';\n@import '../../../../styles/vars';\n\n.legend-title {\n  height: 1.5em;\n  padding: 1em 0.75em 0;\n  color: rgba(0,0,0,0.8);\n}\n\n.close-button {\n  position: absolute;\n  font-size: 1rem;\n  right: 0.5em;\n  margin: 0.25em;\n  color: #666666;\n  @media only screen and (min-device-width: $desktop-device-width) {\n    top: 0.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend-title": `src-components-map-map-legend-mobile-___map-legend-mobile__legend-title`,
	"legendTitle": `src-components-map-map-legend-mobile-___map-legend-mobile__legend-title`,
	"close-button": `src-components-map-map-legend-mobile-___map-legend-mobile__close-button`,
	"closeButton": `src-components-map-map-legend-mobile-___map-legend-mobile__close-button`
};
export default ___CSS_LOADER_EXPORT___;
