/* eslint-disable camelcase */
import { mapConfig } from '../constants/component-configs';

const initialState = {
  map: {
    viewport: {
      zoom: mapConfig.defaultZoom,
      center: null,
      bbox: null
    },
    highlight: {},
    streetView: {
      visible: false
    },
    data: {},
    selection: null,
    mapTypeId: 'roadmap',
    traffic: false,
    drawing: {
      mode: '',
      area: null
    },
    measureTool: {
      active: false,
      length: ''
    },
    userLocation: null,
    linkedElement: {},
    linkedElementActive: false,
    requiresDevSecret: false,
    devSecret: null,
    error404Visible: false
  },
  ui: {
    dialog: {
      disclaimerVisible: false,
      dateVisible: false
    },
    detailTray: {
      collapsed: false
    },
    layerFilters: {
      visible: false
    },
    mobileIdentify: {
      index: 0
    },
    mobileLinks: {
      visible: false
    },
    mobileMaxDetails: {
      visible: false
    },
    legend: {
      visible: false
    },
    mobileLayers: {
      visible: false
    },
    drawingOverlay: {
      visible: false
    },
    translateDialog: false
  },
  filters: {
    layers: [],
    dateRanges: [],
    selectedDateRange: null,
    initialUrlFilter: null
  },
  search: {
    loading: false,
    suggestions: {
      geocoder: [],
      places: []
    },
    value: null,
    showMarker: false,
    focused: false,
    selectedLocation: null,
    selectedLayerElement: null
  },
  notifications: {
    visible: false,
    step: 1,
    drawingShape: null,
    shapeError: null,
    dataTypes: [],
    frequency: 2,
    email: null,
    emailError: false,
    name: null,
    success: false,
    paused: false,
    uuid: null,
    tooltipVisible: true
  }
};

export default initialState;
