import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';
import InfoTray from './../info-tray';

import { getMapData } from '../../../selectors/map';
import { getSelectionElements } from '../../../selectors/map-selection';
import { getDetailTray } from '../../../selectors/ui';
import { getNotificationsVisible } from '../../../selectors/notifications';

import { collapseDetailTray, showDetailTray } from '../../../actions/ui-actions';

import styles from './collapsable-info-tray.scss';

class CollapsableInfoTray extends Component {
  componentDidUpdate(prevProps) {
    const { mapSelection } = this.props;
    if (mapSelection !== prevProps.mapSelection) {
      this.props.showDetailTray();
    }
  }

  render() {
    const { detailTray, mapRef, mapSelection } = this.props;
    if (this.props.notificationsVisible) {
      return null;
    }
    return (
      <div className={classNames(
        styles.leftTrayWrap,
        { [styles.leftTrayWrapOpen]: mapSelection && !detailTray.collapsed }
      )} >
        <div className={classNames(
          styles.leftTrayContent,
          { [styles.leftTrayWrapOpen]: mapSelection && !detailTray.collapsed }
        )}>
          {this.props.mapSelection &&
            <Fragment>
              <InfoTray mapRef={mapRef} />
              {!detailTray.collapsed ?
                <div className={styles.leftTrayCollapseControl} onClick={this.props.collapseDetailTray}>
                  <Icon translate="no">arrow_left</Icon>
                </div> :
                <div className={styles.leftTrayCollapseControl} onClick={this.props.showDetailTray} >
                  <Icon translate="no">arrow_right</Icon>
                </div>
              }
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

CollapsableInfoTray.propTypes = {
  collapseDetailTray: PropTypes.func,
  detailTray: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  mapSelection: PropTypes.array,
  notificationsVisible: PropTypes.bool,
  showDetailTray: PropTypes.func
};

const mapStateToProps = state => {
  return {
    detailTray: getDetailTray(state),
    mapData: getMapData(state),
    mapSelection: getSelectionElements(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, { collapseDetailTray, showDetailTray })(CollapsableInfoTray);
