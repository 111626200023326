/* eslint-disable */
import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import classNames from 'classnames';
import styles from './notification-icon.scss';

class NotificationIcon extends Component {
  render() {
    return (
      <SvgIcon classes={{root: classNames(styles.default, {[styles.selected]: this.props.selected, [styles.mobile]: this.props.mobile})}}>
        <g fill="none" fillRule="evenodd" transform="translate(4 2)">
            <path id="a" d="M8 20c1.1 0 2-.9 2-2H6a2 2 0 0 0 2 2zm6-6l2 2v1H0v-1l2-2V9c0-3.08 1.63-5.64 4.5-6.32V2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v.68C12.36 3.36 14 5.93 14 9v5zm-2-2.7V9.7H8.8V6.5H7.2v3.2H4v1.6h3.2v3.2h1.6v-3.2H12z"/>
        </g>
      </SvgIcon>
    );
  }
};

export default NotificationIcon;