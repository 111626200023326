import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { closeNotifications, setNotificationPaused, resetNotificationForm } from '../../../actions/notification-actions';

import styles from './paused-page.scss';


class PausedPage extends Component {
  resume = () => this.props.setNotificationPaused(false);
  startOver = () => {
    this.props.resetNotificationForm();
    this.props.setNotificationPaused(false);
  };
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div>Receive Activity Updates</div>
          <Icon translate="no" onClick={this.props.closeNotifications}>close</Icon>
        </div>
        <div className={styles.content}>
          Do you want to continue where you left off?
        </div>
        <div className={styles.footer}>
          <div>
            <Button onClick={this.resume}>
              YES, CONTINUE
            </Button>
            <Button onClick={this.startOver}>
              START OVER
            </Button>
          </div>
        </div> 
      </div>
    );
  }
}

PausedPage.propTypes = {
  closeNotifications: PropTypes.func,
  resetNotificationForm: PropTypes.func,
  setNotificationPaused: PropTypes.func
};


export default connect(null, {closeNotifications, setNotificationPaused, resetNotificationForm})(PausedPage);
