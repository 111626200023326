import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './toggle.scss';

class FilterToggle extends PureComponent {
  render() {
    const {checked, color, icon, onClick, ...passThroughProps} = this.props;
    const dynamicStyles = {};
    if (checked && color) {
      dynamicStyles.backgroundColor = color;
    }
    return (
      <div {...passThroughProps} >
        <div 
          onClick={onClick}
          style={dynamicStyles}
          className={classNames(styles.toggle, {[styles.defaultActive]: checked})}
        >
          { icon &&
            <img src={icon} className={styles.icon} alt=""/>
          }
        </div>
      </div>
    );
  }
}

FilterToggle.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func
};

export default FilterToggle;
