import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import DotMapsOverlayView from '../../shared/dotmaps-google-map/dotmaps-overlay-view';

import { getViewportData } from '../../../selectors/clusters';
import { getViewport } from '../../../selectors/map';
import { getLabeledPolygonData, getLabels } from '../../../selectors/map-labels';

import { resolveFields } from '../../../utilities/map-data-utilities';

import { updateLabelGrid } from '../../../actions/map-actions';

import styles from './map-labels.scss';

class MapLabels extends Component {
  constructor() {
    super();
    this.state = {};
    this.generateLabelGrid = this.generateLabelGrid.bind(this);
  }

  componentDidMount() {
    this.generateLabelGrid();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.labeledPolygonData !== this.props.labeledPolygonData) {
      this.generateLabelGrid();
    }
  }

  generateLabelGrid() {
    const { labeledPolygonData, viewport: { bbox } } = this.props;
    this.props.updateLabelGrid(bbox, labeledPolygonData);
  }

  render() {
    const { layerLabels, viewportData } = this.props;
    if (!layerLabels || !viewportData) {
      return null;
    }

    const labels = [];
    Object.entries(viewportData).forEach(([layerId, layer]) => {
      if (layerLabels[layerId]) {
        const labelLocations = layerLabels[layerId];
        const { uiStyle: { polygonLabel }, mapStyle: { strokeColor } } = layer;
        layer.list.forEach(element => {
          const { mapElement: { id, attrs } } = element;
          if (id in labelLocations) {
            const lngLats = labelLocations[id];
            lngLats.forEach((lngLat, index) => {
              labels.push({
                key: `${layerId}-${id}-${index}`,
                color: strokeColor,
                label: polygonLabel.label,
                value: resolveFields(polygonLabel.value, attrs),
                labelProps: polygonLabel.labelProps,
                valueProps: polygonLabel.valueProps,
                position: {
                  lng: lngLat[0],
                  lat: lngLat[1]
                }
              });
            });
          }
        });
      }
    });

    return (
      <Fragment>
        {labels.map(({ key, color, label, value, position, labelProps = {}, valueProps = {} }) => (
          <DotMapsOverlayView
            key={key}
            mapPaneName={'floatPane'}
            position={position}
            className={styles.labelWrap}
          >
            <div style={{ color }} className={styles.labelContainer} >
              <div className={styles.label} {...labelProps}>{label}</div>
              <div className={styles.value} {...valueProps}>{value}</div>
            </div>
          </DotMapsOverlayView>
        ))
        }
      </Fragment>
    );
  }
}

MapLabels.propTypes = {
  labeledPolygonData: PropTypes.array,
  layerLabels: PropTypes.object,
  updateLabelGrid: PropTypes.func,
  viewport: PropTypes.object,
  viewportData: PropTypes.object
};

const mapStateToProps = state => {
  return {
    labeledPolygonData: getLabeledPolygonData(state),
    layerLabels: getLabels(state),
    viewport: getViewport(state),
    viewportData: getViewportData(state)
  };
};

export default connect(mapStateToProps, { updateLabelGrid })(MapLabels);
