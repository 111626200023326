import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSelectedPosition } from '../../../selectors/search';
import { getNotificationsVisible } from '../../../selectors/notifications';
import { Marker } from 'react-google-maps';

class SearchMarker extends Component {
  render() {
    const { position, notificationsVisible } = this.props;
    if (!position || notificationsVisible) {
      return null;
    }
    return (
      <div>
        <Marker position={position} />
      </div>
    );
  }
}

SearchMarker.propTypes = {
  notificationsVisible: PropTypes.bool,
  position: PropTypes.object
};

const mapStateToProps = state => {
  return {
    position: getSelectedPosition(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, {})(SearchMarker);
