import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LargeMedia from '../../shared/media/large';
import SmallMedia from '../../shared/media/small';
import InfoDetail from './info-detail';
import InfoIdentify from './info-identify';

import {
  getSelectedElement,
  getSelectionElementsCount,
  getDrawingVisible
} from '../../../selectors/map-selection';
import { getMobileMaxDetails } from '../../../selectors/ui';

import { clearAreaSelection } from '../../../actions/map-actions';

class InfoTray extends Component {
  render() {
    const { mapRef, detailSelection, selectionCount, mobileMaxDetails } = this.props;
    return (
      <Fragment>
        <LargeMedia>
          {detailSelection ?
            <InfoDetail detailData={detailSelection} mapRef={mapRef} /> :
            <InfoIdentify mapRef={mapRef} />
          }
        </LargeMedia>
        <SmallMedia>
          {((selectionCount > 0 && !mobileMaxDetails.visible) || (selectionCount === 0 && this.props.drawingVisible)) &&
            <InfoIdentify mapRef={mapRef} />
          }
          {mobileMaxDetails.visible && detailSelection &&
            <InfoDetail detailData={detailSelection} mapRef={mapRef} />
          }
        </SmallMedia>
      </Fragment>
    );
  }
}

InfoTray.propTypes = {
  clearAreaSelection: PropTypes.func,
  detailSelection: PropTypes.object,
  drawingVisible: PropTypes.bool,
  mapRef: PropTypes.object.isRequired,
  mobileMaxDetails: PropTypes.object,
  selectionCount: PropTypes.number
};

const mapStateToProps = state => {
  return {
    detailSelection: getSelectedElement(state),
    mobileMaxDetails: getMobileMaxDetails(state),
    selectionCount: getSelectionElementsCount(state),
    drawingVisible: getDrawingVisible(state)
  };
};

export default connect(mapStateToProps, { clearAreaSelection })(InfoTray);
