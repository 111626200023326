/* eslint-disable  */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Polyline } from 'react-google-maps';

class LineSegment extends PureComponent {
  render = props => {
    const { highlighted, options: {strokeWeight, ...passThroughOptions}, ...passThroughProps } = this.props;
    if (highlighted) {
      const highlightedStrokeWeight = strokeWeight || 4;
      const innerStrokeWeight = highlightedStrokeWeight + 3;
      const outerStrokeWeight = innerStrokeWeight + 5;
      const options = {
        ...passThroughOptions,
        strokeWeight: highlightedStrokeWeight,
        zIndex: 202
      }
      const innerOptions = {
        clickable: false,
        strokeWeight: innerStrokeWeight,
        zIndex: 201,
        strokeColor: 'white'
      }
      const outerOptions = {
        clickable: false,
        strokeWeight: outerStrokeWeight,
        zIndex: 200,
        strokeColor: '#406EE3',
        strokeOpacity: 0.6
      }
      return (
        <Fragment>
          <Polyline {...passThroughProps} options={options} />
          <Polyline {...passThroughProps} options={innerOptions} />
          <Polyline {...passThroughProps} options={outerOptions} />
        </Fragment>
      )
    }
    return (
      <Polyline {...passThroughProps} options={{strokeWeight, ...passThroughOptions}} />
    )
  }
}

LineSegment.propTypes = {
  higlighted: PropTypes.bool,
  options: PropTypes.object
};

export default LineSegment;
