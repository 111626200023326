import React from 'react';
import { connect } from 'react-redux';
import { withGoogleMap, GoogleMap, TrafficLayer } from 'react-google-maps';
import pure from 'recompose/pure';

import { getViewport } from '../../../selectors/map';

import { mapConfig, desktopMinWidth } from '../../../constants/component-configs';

const GoogleMapsConfig = withGoogleMap(({ defaultViewport, components, center, zoom, onMapLoad, traffic, ...additionalProps }) => {
  const viewportControl = {
    defaultZoom: defaultViewport.zoom,
    defaultCenter: defaultViewport.center
  };
  if (center && zoom) {
    viewportControl.center = center;
    viewportControl.zoom = zoom;
  }
  
  const mapOptions = window.screen.width >= desktopMinWidth ? {...mapConfig.mapOptions} : {...mapConfig.mobileMapOptions};
  return (
    <GoogleMap
      {...additionalProps}
      {...viewportControl}
      options={mapOptions}
      ref={onMapLoad}
    >
      {traffic && <TrafficLayer />}
      {components}
    </GoogleMap>
  );
});

const DotMapsGoogleMap = props => {
  return (
    <GoogleMapsConfig
      {...props}
      loadingElement={<div />}
    />
  );
};

const mapStateToProps = state => {
  return {
    defaultViewport: getViewport(state)
  };
};

export default connect(mapStateToProps, {})(pure(DotMapsGoogleMap));
