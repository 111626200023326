import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import LargeMedia from '../../shared/media/large';
import SmallMedia from '../../shared/media/small';

import ChromeDesktop from './chrome-desktop';
import ChromeMobile from './chrome-mobile';
import ChromeIOS from './chrome-ios';

import Bowser from 'bowser';

import { getTranslateDialogOpen } from '../../../selectors/ui';
import { closeTranslateDialog } from '../../../actions/ui-actions';

import styles from './translate.scss';

class TranslateInstructions extends Component {

  getInstructions = () => {
    const platformInfo = Bowser.parse(window.navigator.userAgent);
    if (platformInfo.os.name === 'iOS') {
      return ChromeIOS;
    }
    if (
      platformInfo.os.name === 'Android' ||
      platformInfo.platform.type === 'mobile'

    ) {
      return ChromeMobile;
    }
    return ChromeDesktop;
  };

  render() {
    const Instructions = this.getInstructions();
    return (
      <Fragment>
        <LargeMedia>
          <Dialog
            open={this.props.translateDialog}
            onClose={this.props.closeTranslateDialog}
          >
            <DialogTitle classes={{ root: styles.title }}>Translate to other languages</DialogTitle>
            <DialogContent classes={{ root: styles.content }}>
              <Instructions />
            </DialogContent>
            <DialogActions classes={{ root: styles.actionButtons }}>
              <Button
                onClick={this.props.closeTranslateDialog}
                disableRipple
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </LargeMedia>
        <SmallMedia>
          <Drawer
            anchor="bottom"
            open={this.props.translateDialog}
            onClose={this.props.closeTranslateDialog}
          >
            <DialogTitle classes={{ root: styles.mobileTitle }}>
              Translate to other languages
              <IconButton size="small" onClick={this.props.closeTranslateDialog}>
                <Icon translate="no">close</Icon>
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: styles.mobileContent }}>
              <Instructions />
            </DialogContent>
          </Drawer>
        </SmallMedia>
      </Fragment>
    );
  }
}

TranslateInstructions.propTypes = {
  closeTranslateDialog: PropTypes.func,
  translateDialog: PropTypes.bool
};

const mapStateToProps = state => ({
  translateDialog: getTranslateDialogOpen(state)
});

export default connect(mapStateToProps, { closeTranslateDialog })(TranslateInstructions);
