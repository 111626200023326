/* global google */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { createPortal } from 'react-dom';

import { MAP } from 'react-google-maps/lib/constants';

export default class MapControl extends PureComponent {
  componentWillMount() {
    this.controlDiv = document.createElement('div');
    this.controlDiv.setAttribute('style', 'font-size: 1rem;'); // don't inherit font size
    if (this.props.className) {
      this.controlDiv.setAttribute('class', this.props.className);
    }
    this.props.mapRef.context[MAP].controls[this.props.position].push(this.controlDiv);
  }

  componentDidUpdate(prevProps) {
    const { className, mapRef } = this.props;
    if (className !== prevProps.className) {
      this.controlDiv.setAttribute('class', className);
      setTimeout(() => {
        google.maps.event.trigger(mapRef.context[MAP], 'resize');
      }, 500);
    }
  }

  componentWillUnmount() {
    const divIndex = this.props.mapRef.context[MAP].controls[this.props.position].getArray().indexOf(this.controlDiv);
    this.props.mapRef.context[MAP].controls[this.props.position].removeAt(divIndex);
  }

  render() {
    return createPortal(this.props.children, this.controlDiv);
  }
}

MapControl.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  mapRef: PropTypes.object,
  position: PropTypes.number
};
