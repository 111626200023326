import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class StaticStreetView extends Component {
  componentDidMount() {
    this.updateStreetView();
  }

  componentDidUpdate() {
    this.updateStreetView();
  }

  updateStreetView() {
    const {apiKey, lat, lng} = this.props;
    const {clientWidth, clientHeight} = this.streetViewDiv;
    const streetViewURL = `https://maps.googleapis.com/maps/api/streetview?size=${clientWidth}x${clientHeight}&location=${lat},${lng}&key=${apiKey}`;
    this.streetViewDiv.setAttribute('style', `background-image: url('${streetViewURL}')`);
  }

  render() {
    const {apiKey, lat, lng, ...additionalProps} = this.props; // eslint-disable-line no-unused-vars
    return (
      <div {...additionalProps} ref={div => {
        this.streetViewDiv = div;
      }} />
    );
  }
}

StaticStreetView.propTypes = {
  apiKey: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
};
