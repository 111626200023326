import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MapSearchAutocomplete from './map-search-autocomplete';
import NotificationIcon from '../notifications/notification-icon';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import TranslateButton from '../shared/translate-button';
import NotificationTooltip from '../notifications/notification-tooltip';


import { setSearchFocused, clearSearchSuggestions } from '../../actions/search-actions';
import { openLayerFilters, hideDrawingOverlay } from '../../actions/ui-actions';
import { setDrawingMode } from '../../actions/map-actions';
import { openNotifications } from '../../actions/notification-actions';

import { getSearchFocused } from '../../selectors/search';

import styles from './top-bar.scss';

class TopBar extends Component {
  clickHandler = () => {
    this.props.setSearchFocused(false);
    this.props.clearSearchSuggestions();
  };
  menuClick = () => {
    this.props.openLayerFilters();
    this.props.setDrawingMode('');
    this.props.hideDrawingOverlay();
  };
  searchClick = () => {
    this.props.setSearchFocused(true);
    this.props.setDrawingMode('');
    this.props.hideDrawingOverlay();
  };
  render() {
    return (
      <Fragment>
        { this.props.focused ?
          <div className={styles.topBarContainer}>
            <div className={styles.backIcon}>
              <Icon translate="no" onClick={this.clickHandler}>arrow_back</Icon>
            </div>
            <MapSearchAutocomplete mapRef={this.props.mapRef} showMyLocation mobile/>
          </div> :
          <div className={styles.topBarContainerClosed}>
            <IconButton onClick={this.menuClick}><Icon translate="no">menu</Icon></IconButton>
            <div className={styles.logo} />
            <TranslateButton />
            <div className={styles.buttonWrap}>
              <IconButton classes={{root: styles.button}} onClick={this.props.openNotifications}><NotificationIcon mobile /></IconButton>
              <NotificationTooltip/>
            </div>
            <IconButton
              classes={{root: classNames(styles.button, styles.right)}}
              onClick={this.searchClick}
            >
              <Icon translate="no">search</Icon>
            </IconButton>
          </div>
        }
      </Fragment>
    );
  }
}

TopBar.propTypes = {
  clearSearchSuggestions: PropTypes.func,
  focused: PropTypes.bool,
  hideDrawingOverlay: PropTypes.func,
  mapRef: PropTypes.object,
  openLayerFilters: PropTypes.func,
  openNotifications: PropTypes.func,
  setDrawingMode: PropTypes.func,
  setSearchFocused: PropTypes.func
};

const mapStateToProps = state => {
  return {
    focused: getSearchFocused(state)
  };
};

export default connect(mapStateToProps, {
  openLayerFilters,
  setSearchFocused,
  clearSearchSuggestions,
  setDrawingMode,
  hideDrawingOverlay,
  openNotifications
})(TopBar);
