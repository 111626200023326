import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MapLegend from '../../map-legend/legend';

import { Carousel } from 'react-responsive-carousel';

import classNames from 'classnames';

import styles from './map-legend-mobile.scss';

export default class MobileCarousel extends PureComponent {
  render() {
    const { layers } = this.props;
    const chunkedFilters = layers.reduce(
      (chunks, element, index) => {
        chunks[Math.floor(index / 6)].push(element);
        return chunks;
      },
      Array.from({ length: Math.ceil(layers.length / 6) }, () => [])
    );
    return (
      <Carousel
        className={classNames(styles.legendCarousel, { [styles.single]: chunkedFilters.length < 2 })}
        showThumbs={false}
      >
        {chunkedFilters.map((chunk, index) => {
          const left = chunk.slice(0, 3);
          const right = chunk.slice(3, 6);
          return (
            <div key={index} className={styles.carouselContent} >
              {left.length > 0 && <MapLegend mapData={left} />}
              {right.length > 0 && <MapLegend mapData={right} />}
            </div>
          );
        })}
      </Carousel>
    );
  }
}

MobileCarousel.propTypes = {
  layers: PropTypes.array
};
