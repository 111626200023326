/* global google */
import React, { Component } from 'react';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapConfig } from '../../../constants/component-configs';
import { setNotificationDrawingShape } from '../../../actions/notification-actions';
import {
  getNotificationsVisible,
  getNotificationsDrawingShape,
  getNotificationsShapeError
} from '../../../selectors/notifications';

import { DRAWING_MANAGER, MAP } from 'react-google-maps/lib/constants';


class FixedDrawingManager extends DrawingManager {
  componentDidUpdate(prevProps) {
    const drawingManager = this.state[DRAWING_MANAGER];
    const map = drawingManager.map;
    drawingManager.setMap(null);
    super.componentDidUpdate(prevProps);
    drawingManager.setMap(map);
  }
}

class NotificationDrawingTools extends Component {
  constructor(props) {
    super(props);
    this.state = { mapsShape: null, active: true };
  }

  componentDidMount = () => {
    if (this.props.shape) {
      const mapsShape = new google.maps.Polygon({
        path: this.props.shape,
        ...mapConfig.notificationDrawingOptions.polygonOptions
      });
      mapsShape.setMap(this.props.mapRef.context[MAP]);
      this.setState({ mapsShape, active: false });
      this.setListeners(mapsShape);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.shape && !this.props.shape) {
      this.state.mapsShape.setMap(null);
      this.setState({ active: true }); // eslint-disable-line react/no-did-update-set-state
    }
    if (prevProps.shapeError !== this.props.shapeError) {
      if (this.props.shapeError) {
        this.state.mapsShape.setOptions({ strokeColor: '#df1717' });
      } else {
        this.state.mapsShape.setOptions({ strokeColor: mapConfig.notificationDrawingOptions.polygonOptions.strokeColor });
      }
    }
  }

  componentWillUnmount = () => {
    if (this.state.mapsShape) {
      this.state.mapsShape.setMap(null);
    }
  };

  setListeners = mapsShape => {
    google.maps.event.addListener(mapsShape.getPath(), 'insert_at', () => {
      const notificationShape = [...mapsShape.getPath().getArray()];
      this.props.setNotificationDrawingShape(notificationShape);
    });
    google.maps.event.addListener(mapsShape.getPath(), 'remove_at', () => {
      const notificationShape = [...mapsShape.getPath().getArray()];
      this.props.setNotificationDrawingShape(notificationShape);
    });
    google.maps.event.addListener(mapsShape.getPath(), 'set_at', () => {
      const notificationShape = [...mapsShape.getPath().getArray()];
      this.props.setNotificationDrawingShape(notificationShape);
    });
  };

  completePolygon = (mapsShape) => {
    if (this.state.mapsShape) {
      this.state.mapsShape.setMap(null);
    }
    this.setState({ mapsShape, active: false });
    const notificationShape = [...mapsShape.getPath().getArray()];
    this.props.setNotificationDrawingShape(notificationShape);
    this.setListeners(mapsShape);
  };

  render() {
    const { active } = this.state;
    return (
      <FixedDrawingManager
        drawingMode={active ? 'polygon' : null}
        options={mapConfig.notificationDrawingOptions}
        onPolygonComplete={this.completePolygon}
      />
    );
  }
}

NotificationDrawingTools.propTypes = {
  mapRef: PropTypes.object,
  setNotificationDrawingShape: PropTypes.func,
  shape: PropTypes.array,
  shapeError: PropTypes.object
};

const mapStateToProps = state => {
  return {
    visible: getNotificationsVisible(state),
    shape: getNotificationsDrawingShape(state),
    shapeError: getNotificationsShapeError(state)
  };
};

export default connect(mapStateToProps, { setNotificationDrawingShape })(NotificationDrawingTools);
