import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { checkUrlSuccess } from '../../../../../utilities/map-utilities';

import styles from './detail-text.scss';

export default class DetailText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hrefValidated: null };
  }

  componentDidMount() {
    this.validateFile();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label || prevProps.value !== this.props.value) {
      this.setState({ hrefValidated: null }); //eslint-disable-line react/no-did-update-set-state
      setImmediate(() => this.validateFile());
    }
  }

  validateFile = () => {
    const { value } = this.props;
    if (value && value.href && value.revalidate_method) {
      checkUrlSuccess(value.href, value.revalidate_method)
        .then(() => this.setState({ hrefValidated: true }))
        .catch(() => this.setState({ hrefValidated: false }));
    }
  };

  toggleShowMore = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { label, notranslateLabel, value, notranslateValue } = this.props;
    const { hrefValidated } = this.state;
    const labelProps = {};
    if (notranslateLabel) {
      labelProps.translate = 'no';
    }
    const valueProps = {};
    if (notranslateValue) {
      valueProps.translate = 'no';
    }

    if (!value) {
      return (
        <div key={label}>
          <div className={styles.detailHeader} {...labelProps}>{label}</div>
          <div className={styles.detailData} {...valueProps}>&ndash;</div>
        </div>
      );
    }
    if (!value.href) {
      return (
        <div key={label}>
          <div className={styles.detailHeader} {...labelProps}>{label}</div>
          <div className={styles.detailData} {...valueProps} dangerouslySetInnerHTML={{ __html: value }} />
        </div>
      );
    }
    if (value.revalidate_method && !hrefValidated) {
      if (hrefValidated === null) {
        return (
          <div key={label}>
            <div className={styles.detailHeader} {...labelProps}>{label}</div>
            <div className={styles.detailData} {...valueProps}>
              {value.placeholder || 'Retrieving File Info...'}
            </div>
          </div>
        );
      } else { // eslint-disable-line no-else-return
        return (
          <div key={label}>
            <div className={styles.detailHeader} {...labelProps}>{label}</div>
            <div className={styles.detailData} {...valueProps}>
              {value.error || '&ndash;'}
            </div>
          </div>
        );
      }
    } else { // eslint-disable-line no-else-return
      const contentDisposition = value['Content-Disposition'] || value['content-disposition'] || '';
      const match = /filename="([^"]*)"/.exec(contentDisposition);
      const filename = (match && match[1]) || value.label || value.href;
      return (
        <div key={label}>
          <div className={styles.detailHeader} {...labelProps}>{label}</div>
          <a href={value.href} target="_blank" rel="noopener noreferrer" className={styles.detailLink} {...valueProps}>
            {filename}
          </a>
        </div>
      );
    }
  }
}

DetailText.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  notranslateLabel: PropTypes.bool,
  notranslateValue: PropTypes.bool,
  value: PropTypes.any
};
