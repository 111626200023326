import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import LargeMedia from '../../../shared/media/large';
import SmallMedia from '../../../shared/media/small';
import IdentifyList from './identify-list';
import MobileIdentify from './mobile-identify';


import { getSelectionElementsCount } from '../../../../selectors/map-selection';

import { clearAreaSelection, setLinkedElementInactive } from '../../../../actions/map-actions';

import styles from './info-identify.scss';

class InfoIdentify extends Component {
  clearSelection = () => {
    this.props.clearAreaSelection();
    this.props.setLinkedElementInactive();
  };

  render() {
    const { mapSelectionCount, mapRef } = this.props;
    return (
      <div className={styles.infoIdentifyContainer} >
        <LargeMedia>
          <div className={styles.identifyTitle}>
            Identify results&nbsp;
            <div className={styles.identifyCount}>
              {mapSelectionCount}
            </div>
          </div>
          {mapSelectionCount === 0 &&
            <div className={styles.noResults}>
              No results identified within the boundaries
              <Button className={styles.clearButton} onClick={this.clearSelection}>
                <div className={styles.clearLabel}>CLEAR BOUNDARIES</div>
              </Button>
            </div>
          }
          <div className={styles.identifyResults}>
            <IdentifyList mapRef={mapRef} />
          </div>
        </LargeMedia>
        <SmallMedia>
          {mapSelectionCount > 0 ?
            <MobileIdentify mapRef={mapRef} /> :
            <div className={styles.noResults}>
              No results identified within the boundaries
              <Button className={styles.clearButton} onClick={this.clearSelection}>
                <div className={styles.clearLabel}>CLEAR BOUNDARIES</div>
              </Button>
            </div>
          }
        </SmallMedia>
        <IconButton
          className={styles.closeButton}
          onClick={this.clearSelection}
        >
          <Icon translate="no">close</Icon>
        </IconButton>
      </div>
    );
  }
}

InfoIdentify.propTypes = {
  clearAreaSelection: PropTypes.func,
  mapRef: PropTypes.object.isRequired,
  mapSelectionCount: PropTypes.number,
  setLinkedElementInactive: PropTypes.func
};

const mapStateToProps = state => {
  return {
    mapSelectionCount: getSelectionElementsCount(state)
  };
};

export default connect(
  mapStateToProps, { clearAreaSelection, setLinkedElementInactive }
)(InfoIdentify);
