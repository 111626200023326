import React, { PureComponent } from 'react';
// import classNames from 'classnames';

import styles from '../common.scss';

import translateChrome from '../../../../static/translate-chrome.svg';
import iosMore from '../../../../static/ios-more.svg';
import gTranslate from '../../../../static/g-translate.svg';
import translateChromeIos1 from '../../../../static/translate-chrome-ios-1.png';
import translateChromeIos2 from '../../../../static/translate-chrome-ios-2.png';

export default class ChromeIOS extends PureComponent {
  render() {
    return (
      <div className={styles.instructions}>
        <header>
          <img src={translateChrome} height="16" width="16" alt="" />Google Chrome
        </header>
        <div>
          1. At the bottom right, tap
          <span>
            <img className={styles.icon} src={iosMore} height="16" width="16" alt="more icon" />
            &gt;
            <img className={styles.icon} src={gTranslate} height="16" width="16" alt="translate icon" />
            Translate
          </span>.<br />
          <img src={translateChromeIos1} height="227" width="168" alt="translate instructions" />
        </div>
        <div>
          2. Select the language you want to translate to.<br />
          <img src={translateChromeIos2} height="152" width="172" alt="language instructions" />
        </div>
        <div>
          3. Tap <span>Translate</span> to apply the changes.
        </div>
      </div>
    );
  }
}
