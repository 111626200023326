// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-map-legend-legend-___map-legend__map-legend{background-color:white;width:15.55rem;max-height:40vh;overflow-y:auto;box-shadow:0 2px 8px 0 rgba(0,0,0,0.3)}@media only screen and (min-device-width: 768px){.src-components-map-map-legend-legend-___map-legend__map-legend{padding:1rem;margin:.5rem 1.25rem;border-radius:0.125rem}}.src-components-map-map-legend-legend-___map-legend__map-legend .src-components-map-map-legend-legend-___map-legend__legend-item{height:2em;padding:0}.src-components-map-map-legend-legend-___map-legend__map-legend .src-components-map-map-legend-legend-___map-legend__legend-item .src-components-map-map-legend-legend-___map-legend__legend-icon{height:1.5em;width:1.5em;margin-right:0.5em}.src-components-map-map-legend-legend-___map-legend__map-legend .src-components-map-map-legend-legend-___map-legend__legend-item .src-components-map-map-legend-legend-___map-legend__legend-text{flex-grow:0;padding:0;line-height:1em}.src-components-map-map-legend-legend-___map-legend__map-legend .src-components-map-map-legend-legend-___map-legend__legend-item .src-components-map-map-legend-legend-___map-legend__legend-text .src-components-map-map-legend-legend-___map-legend__primary{color:rgba(0,0,0,0.8);font-size:.8em;line-height:1.2em}.src-components-map-map-legend-legend-___map-legend__map-legend .src-components-map-map-legend-legend-___map-legend__legend-item .src-components-map-map-legend-legend-___map-legend__empty-state{padding:0;line-height:1em}.src-components-map-map-legend-legend-___map-legend__map-legend .src-components-map-map-legend-legend-___map-legend__legend-item .src-components-map-map-legend-legend-___map-legend__empty-state h3{font-size:0.8125rem;color:rgba(0,0,0,0.6)}.src-components-map-map-legend-legend-___map-legend__map-legend .src-components-map-map-legend-legend-___map-legend__legend-sub-icon{font-size:1.25em;margin-left:0.4em;color:rgba(0,0,0,0.6)}
`, "",{"version":3,"sources":["webpack://./src/components/map/map-legend/legend/map-legend.scss"],"names":[],"mappings":"AAGA,gEACI,sBAAuB,CACvB,cAAe,CACf,eAAgB,CAChB,eAAgB,CAChB,sCAAuC,CAEvC,iDAPJ,gEAQQ,YAAa,CACb,oBAAqB,CACrB,sBAAuB,CA0C9B,CApDD,iIAcQ,UAAW,CACX,SAAU,CAflB,kMAkBY,YAAa,CACb,WAAY,CACZ,kBAAmB,CApB/B,kMAwBY,WAAY,CACZ,SAAU,CACV,eAAgB,CA1B5B,+PA6BgB,qBAAsB,CACtB,cAAe,CACf,iBAAkB,CA/BlC,kMAoCY,SAAU,CACV,eAAgB,CArC5B,qMAwCgB,mBAAoB,CACpB,qBAAsB,CAzCtC,qIA+CQ,gBAAiB,CACjB,iBAAkB,CAClB,qBAAsB","sourcesContent":["@import '../../../../styles/mixins';\n@import '../../../../styles/vars';\n\n.map-legend {\n    background-color: white;\n    width: 15.55rem;\n    max-height: 40vh;\n    overflow-y: auto;\n    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.3);\n\n    @media only screen and (min-device-width: $desktop-device-width) {\n        padding: 1rem;\n        margin: .5rem 1.25rem;\n        border-radius: 0.125rem;\n    }\n\n    .legend-item {\n        height: 2em;\n        padding: 0;\n\n        .legend-icon {\n            height: 1.5em;\n            width: 1.5em;\n            margin-right: 0.5em;\n        }\n\n        .legend-text {\n            flex-grow: 0;\n            padding: 0;\n            line-height: 1em;\n\n            .primary {\n                color: rgba(0,0,0,0.8);\n                font-size: .8em;\n                line-height: 1.2em;\n            }\n        }\n\n        .empty-state {\n            padding: 0;\n            line-height: 1em;\n\n            h3 {\n                font-size: 0.8125rem;\n                color: rgba(0,0,0,0.6);\n            }\n        }\n    }\n\n    .legend-sub-icon {\n        font-size: 1.25em;\n        margin-left: 0.4em;\n        color: rgba(0,0,0,0.6)\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map-legend": `src-components-map-map-legend-legend-___map-legend__map-legend`,
	"mapLegend": `src-components-map-map-legend-legend-___map-legend__map-legend`,
	"legend-item": `src-components-map-map-legend-legend-___map-legend__legend-item`,
	"legendItem": `src-components-map-map-legend-legend-___map-legend__legend-item`,
	"legend-icon": `src-components-map-map-legend-legend-___map-legend__legend-icon`,
	"legendIcon": `src-components-map-map-legend-legend-___map-legend__legend-icon`,
	"legend-text": `src-components-map-map-legend-legend-___map-legend__legend-text`,
	"legendText": `src-components-map-map-legend-legend-___map-legend__legend-text`,
	"primary": `src-components-map-map-legend-legend-___map-legend__primary`,
	"empty-state": `src-components-map-map-legend-legend-___map-legend__empty-state`,
	"emptyState": `src-components-map-map-legend-legend-___map-legend__empty-state`,
	"legend-sub-icon": `src-components-map-map-legend-legend-___map-legend__legend-sub-icon`,
	"legendSubIcon": `src-components-map-map-legend-legend-___map-legend__legend-sub-icon`
};
export default ___CSS_LOADER_EXPORT___;
