/* eslint-disable max-nested-callbacks */
import { createSelector } from 'reselect';

import { getVisibleMapDataList, getViewport, getZoom } from './map';
import { getHighlightedOrSelectedElements } from './map-selection';
import { bboxIntersects } from '../utilities/geometry-utilities';
import {
  createClusters,
  filterClusters,
  createOverlaps,
  mapZoomToClusterPrefix
} from '../utilities/cluster-utilities';

const getClusterableLayers = createSelector(
  [getVisibleMapDataList],
  (dataList) => {
    if (dataList) {
      return dataList.reduce((filtered, data) => {
        if (data.uiStyle.marker && data.list && data.list.length > 0) {
          filtered[data.id] = data.list;
        }
        return filtered;
      }, {});
    }
    return {};
  }
);

const getOverlaps = createSelector(
  [getClusterableLayers],
  layersList => createOverlaps(layersList)
);

const getPrefixLengths = createSelector(
  [getZoom],
  zoom => mapZoomToClusterPrefix(zoom)
);

const getClusters = createSelector(
  [getOverlaps, getPrefixLengths],
  (overlaps, prefixLength) => createClusters(overlaps, prefixLength)
);


const getClustersWithSelections = createSelector(
  [getClusters, getHighlightedOrSelectedElements],
  (clusters, layerElements) => {
    const selectionClusters = {};
    Object.entries(clusters).forEach(([geohash, cluster]) => {
      let selectionCount = 0;
      Object.entries(cluster.items).forEach(([layerId, layer]) => {
        if (layerId in layerElements) {
          const elements = layerElements[layerId];
          layer.forEach(itemId => {
            if (elements[itemId]) {
              selectionCount = selectionCount + 1;
            }
          });
        }
      });
      selectionClusters[geohash] = {
        cluster,
        selectionCount
      };
    });
    return selectionClusters;
  });


export const filterClustersBbox = (clusters, bbox) => (
  filterClusters(clusters, cluster => bboxIntersects(cluster.bbox, bbox))
);

export const getVisibleClusters = createSelector(
  [getClustersWithSelections, getViewport],
  (selectionClusters, { bbox }) => {
    if (!bbox) {
      return {};
    }
    return filterClusters(selectionClusters, ({ cluster }) => bboxIntersects(cluster.bbox, bbox));
  }
);

const getClusteredData = createSelector(
  [getClustersWithSelections, getVisibleMapDataList, getPrefixLengths],
  (clusterSelections, dataList, prefixLength) => {
    const annotatedData = {};
    dataList.forEach(layerData => {
      const annotatedList = [];
      layerData.list.forEach(element => {
        const clusterPrefix = element.geohash && element.geohash.substring(0, prefixLength);
        annotatedList.push({ mapElement: element, clusterSelection: clusterPrefix && clusterSelections[clusterPrefix] || null });
      });
      annotatedData[layerData.id] = { ...layerData, list: annotatedList };
    });
    return annotatedData;
  }
);

export const getViewportData = createSelector(
  [getClusteredData, getViewport],
  (data, { bbox }) => {
    const filteredData = {};
    let filterFunction = () => false;
    if (bbox && bbox.length === 4) {
      filterFunction = ({ mapElement }) => bboxIntersects(mapElement.bbox, bbox);
    }
    if (data) {
      Object.entries(data).forEach(([layerId, layerData]) => {
        filteredData[layerId] = {
          ...layerData,
          list: layerData.list.filter(filterFunction)
        };
      });
    }
    return filteredData;
  }
);
