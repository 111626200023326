import React, { Component } from 'react';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapConfig } from '../../../../constants/component-configs';
const { drawingManager } = mapConfig;
import { setDrawingMode, selectDrawingArea, selectDrawingPolygonArea, clearAreaSelection} from '../../../../actions/map-actions';
import { getDrawingMode } from '../../../../selectors/map';
import { getDrawingVisible } from '../../../../selectors/map-selection';

import { DRAWING_MANAGER } from 'react-google-maps/lib/constants';


class FixedDrawingManager extends DrawingManager {
  componentDidUpdate(prevProps) {
    const drawingManager_ = this.state[DRAWING_MANAGER];
    const map = drawingManager_.map;
    drawingManager_.setMap(null);
    super.componentDidUpdate(prevProps);
    drawingManager_.setMap(map);
  }
}

class MapDrawingTools extends Component {
  state = { mapsShape: null, active: false };

  componentWillReceiveProps(nextProps) {
    if (this.props.drawingMode !== nextProps.drawingMode) {
      this.setState({ active: true });
    }

    if (
      this.state.mapsShape &&
      this.props.drawingVisible !== nextProps.drawingVisible &&
      !nextProps.drawingVisible
    ) {
      this.clearShape();
    }

    if (
      this.state.active === false &&
      this.props.drawingMode !== '' &&
      nextProps.drawingMode === ''
    ) {
      this.props.clearAreaSelection();
    }
  }

  clearShape = () => {
    this.state.mapsShape.setMap(null);
    this.setState({ mapsShape: null, active: false });
    this.props.setDrawingMode('');
  };

  completeCircle = (mapsShape) => {
    if (this.state.mapsShape) {
      this.state.mapsShape.setMap(null);
    }
    this.setState({ mapsShape, active: false });
    this.props.selectDrawingArea(mapsShape.center, this.props.mapRef, mapsShape.radius, true);
  };

  completePolygon = (mapsShape) => {
    if (this.state.mapsShape) {
      this.state.mapsShape.setMap(null);
    }
    this.setState({ mapsShape, active: false });
    this.props.selectDrawingPolygonArea(mapsShape.getPath().getArray());
  };

  
  render() {
    const { drawingMode } = this.props;
    const { active } = this.state;
    return (
      <div>
        {Boolean(drawingMode.length) && active &&
          <FixedDrawingManager
            drawingMode={drawingMode}
            options={drawingManager}
            onPolygonComplete={this.completePolygon}
            onCircleComplete={this.completeCircle}
          />
        }
      </div>
    );
  }
}

MapDrawingTools.propTypes = {
  clearAreaSelection: PropTypes.func,
  drawingMode: PropTypes.string,
  drawingVisible: PropTypes.bool,
  mapRef: PropTypes.object,
  mode: PropTypes.string,
  selectDrawingArea: PropTypes.func,
  selectDrawingPolygonArea: PropTypes.func,
  setDrawingMode: PropTypes.func
};

const mapStateToProps = state => {
  return {
    drawingMode: getDrawingMode(state),
    drawingVisible: getDrawingVisible(state)
  };
};

export default connect(mapStateToProps, {
  setDrawingMode, selectDrawingArea, selectDrawingPolygonArea, clearAreaSelection
})(MapDrawingTools);
