// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Provider } from 'react-redux';
// import routes from '../routes';
// import { Router } from 'react-router';

// export default class Root extends Component {
//   render() {
//     const { store, history } = this.props;
//     console.log('root')
//     console.log(store)
//     console.log(history)
//     console.log(routes)
//     return (
//       <Provider store={store}>
//         <Router history={history} routes={routes} />
//       </Provider>
//     );
//   }
// }

// Root.propTypes = {
//   store: PropTypes.object.isRequired,
//   history: PropTypes.object.isRequired
// };

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};
