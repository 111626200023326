/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/no-static-element-interactions, react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import MobileDrawingTools from './mobile-drawing-tools';
import ConfirmationPage from '../confirmation-page';
import {
  getNotificationsVisible,
  getNotificationsDrawingShape,
  getNotificationsStep,
  getNotificationsDataTypes,
  getNotificationsFrequency,
  getNotificationsEmail,
  getNotificationsEmailError,
  getNotificationsName,
  getNotificationsSuccess,
  getNotificationsShapeError
} from '../../../selectors/notifications';
import {
  closeNotifications,
  setNotificationDrawingShape,
  setNotificationStep,
  setNotificationDataTypes,
  setNotificationFrequency,
  setNotificationEmail,
  setNotificationName,
  saveNotification,
  resetNotificationForm
} from '../../../actions/notification-actions';

import styles from './mobile-notifications.scss';

class MobileNotifications extends Component {
  state = { emailTimeout: null, email: null, emailValidated: false, overlayVisible: true, shape: null };

  nextStep = () => {
    const { step } = this.props;
    if (step === 1) {
      this.setState({ overlayVisible: true });
    }
    this.props.setNotificationStep(step + 1);
  };
  prevStep = () => {
    const { step } = this.props;
    if (step === 2 || step === 3) {
      this.setState({ overlayVisible: true });
    }
    this.props.setNotificationStep(step - 1);
  };
  setFrequency = event => this.props.setNotificationFrequency(event.target.value);
  setName = event => this.props.setNotificationName(event.target.value);

  setEmail = event => {
    const email = event.target.value;
    if (this.state.emailTimeout) {
      clearTimeout(this.state.emailTimeout);
    }
    this.setState({
      email,
      emailValidated: false,
      emailTimeout: setTimeout(() => {
        this.setState({ emailValidated: true });
        this.props.setNotificationEmail(email);
      }, 500)
    });
  };

  save = () => {
    const { shape, dataTypes, frequency, email, name } = this.props;
    this.props.saveNotification(shape, dataTypes, frequency, email, name);
  };

  handleClose = () => {
    this.setState({
      overlayVisible: true
    });
    this.props.closeNotifications();
    this.props.resetNotificationForm();
  };

  setType = event => {
    const { value, checked } = event.target;
    const { dataTypes } = this.props;
    let types = [...dataTypes];
    if (checked && dataTypes.indexOf(value) < 0) {
      types.push(value);
    } else if (!checked && dataTypes.indexOf(value) > -1) {
      types = dataTypes.filter(dataType => dataType !== value);
    }
    this.props.setNotificationDataTypes(types);
  };

  checkStepComplete = () => {
    const { overlayVisible } = this.state;
    const { step, shape, dataTypes, frequency, email, emailError, shapeError } = this.props;
    if (step >= 1 && overlayVisible) {
      return false;
    } else if (step >= 2 && (!shape || shapeError)) {
      return false;
    } else if (step >= 3 && dataTypes.length === 0) {
      return false;
    } else if (step >= 4 && (frequency === null || email === null || emailError)) {
      return false;
    }
    return true;
  };

  hideDrawingOverlay = () => {
    this.setState({ overlayVisible: false });
  };

  clearShape = () => {
    this.props.setNotificationDrawingShape(null);
  };

  getDataTypesList = () => {
    const [...dataTypes] = this.props.dataTypes;
    const last = dataTypes.pop();
    if (dataTypes.length) {
      let types = [];
      if (dataTypes.length > 1) {
        types = dataTypes.map(dataType => {
          return <div key={dataType}><span>{dataType}</span>,&nbsp;</div>;
        });
      } else {
        types.push(<div key={dataTypes[0]}><span>{dataTypes[0]}</span>&nbsp;</div>);
      }
      types.push(<div key={last}>and&nbsp;<span>{last}</span></div>);
      return types;
    }
    return <div key={last}><span>{last}</span></div>;
  };

  render() {
    if (!this.props.visible) {
      return null;
    }
    const disabled = !this.checkStepComplete();
    const { shape, step, dataTypes, frequency, email, name, emailError, shapeError } = this.props;
    const frequencyOptions = { 1: 'Daily', 2: 'Weekly', 3: 'Monthly' };
    if (!this.props.success) {
      return (
        <div className={styles.container}>
          <div className={styles.header}>
            <Icon translate="no" onClick={this.handleClose}>close</Icon>
            <div className={styles.title}>Receive Activity Updates</div>
          </div>
          <div className={styles.subheader}>
            <div className={styles.controls}>
              {step === 1 &&
                <div className={styles.control} />
              }
              {step === 2 && (shape || shapeError) &&
                <Button className={styles.control} onClick={this.clearShape}>Clear</Button>
              }
              {(step > 2 || step === 2 && !shape && !shapeError) &&
                <Button className={styles.control} onClick={this.prevStep}>Back</Button>
              }
              <div className={styles.steps}>
                Step <span>{step}</span> of <span>5</span>
              </div>
              {step < 5 &&
                <Button
                  classes={{ disabled: styles.disabled }}
                  className={styles.control}
                  disabled={disabled}
                  onClick={this.nextStep}
                  style={{ color: !disabled ? '#4285f4' : '' }}>
                  Next
                </Button>
              }
              {step === 5 &&
                <div className={styles.control} />
              }
            </div>
            {shapeError &&
              <div className={styles.errorNotification}>
                <Icon translate="no" className={styles.errorIcon}>error</Icon>
                {shapeError.area &&
                  <div>
                    The area you drew is too large (<span className={styles.data}>{shapeError.area}</span>).<br />
                    Please draw an area smaller than <span className={styles.data}>100 acre</span>.
                  </div>
                }
              </div>
            }
          </div>
          {step === 1 && this.state.overlayVisible &&
            <Div100vh style={{ height: 'calc(100rvh - 6.5rem' }} className={styles.overlayContainer}>
              <div className={styles.overlay} onClick={this.hideDrawingOverlay}>
                <Icon translate="no">pan_tool</Icon>
                <label>Pan and zoom to an area you care about.</label>
              </div>
            </Div100vh>
          }
          {step === 1 && !this.state.overlayVisible &&
            <Div100vh style={{ height: 'calc(100rvh - 6.5rem' }} className={styles.outlineContainer}>
              <div className={styles.outline} />
            </Div100vh>
          }
          {step === 2 && !shape && this.state.overlayVisible &&
            <Div100vh style={{ height: 'calc(100rvh - 6.5rem' }} className={styles.overlayContainer}>
              <div className={styles.overlay} onClick={this.hideDrawingOverlay}>
                <Icon translate="no">touch_app</Icon>
                <label>Draw an area on the map to see results.</label>
              </div>
            </Div100vh>
          }
          {step === 3 &&
            <div className={styles.content}>
              <div className={styles.title}>Notify me about:</div>
              <div className={styles.selectionBlock}>
                <div className={styles.selectionRow}>
                  <label>Road closures</label>
                  <Checkbox
                    onChange={event => this.setType(event)}
                    value={'road closures'}
                    checked={dataTypes.indexOf('road closures') > -1}
                    style={{ color: dataTypes.indexOf('road closures') > -1 ? '#4285f4' : '' }} />
                </div>
                <div className={styles.selectionRow}>
                  <label>Permits</label>
                  <Checkbox
                    onChange={event => this.setType(event)}
                    value={'permits'}
                    checked={dataTypes.indexOf('permits') > -1}
                    style={{ color: dataTypes.indexOf('permits') > -1 ? '#4285f4' : '' }} />
                </div>
                <div className={styles.selectionRow}>
                  <label>Events</label>
                  <Checkbox
                    onChange={event => this.setType(event)}
                    value={'events'}
                    checked={dataTypes.indexOf('events') > -1}
                    style={{ color: dataTypes.indexOf('events') > -1 ? '#4285f4' : '' }} />
                </div>
              </div>
            </div>
          }
          {step === 4 &&
            <div className={styles.content}>
              <div className={styles.title}>Email me</div>
              <div className={styles.selectionBlock}>
                <div key={1} className={styles.selectionRow}>
                  <label>Daily</label>
                  <Radio
                    checked={frequency === 1}
                    onChange={event => this.setFrequency(event)}
                    value={1}
                    name="frequency-radio"
                    style={{ color: frequency === 1 ? '#4285f4' : '' }}
                  />
                </div>
                <div key={2} className={styles.selectionRow}>
                  <label>Weekly</label>
                  <Radio
                    checked={frequency === 2}
                    onChange={event => this.setFrequency(event)}
                    value={2}
                    name="frequency-radio"
                    style={{ color: frequency === 2 ? '#4285f4' : '' }}
                  />
                </div>
                <div key={3} className={styles.selectionRow}>
                  <label>Monthly</label>
                  <Radio
                    checked={frequency === 3}
                    onChange={event => this.setFrequency(event)}
                    value={3}
                    name="frequency-radio"
                    style={{ color: frequency === 3 ? '#4285f4' : '' }}
                  />
                </div>
              </div>
              <div className={styles.userInfo}>
                <div className={styles.userRow}>
                  <label>Email address</label>
                  <Input
                    classes={{ root: styles.input }}
                    onChange={event => this.setEmail(event)}
                    value={this.state.email}
                    placeholder="example@domain.com"
                    error={emailError}
                    fullWidth />
                  {emailError &&
                    <FormHelperText className={styles.error} id="email-error-text">Enter a valid email</FormHelperText>
                  }
                </div>
                <div className={styles.userRow}>
                  <label>Name this area <span>(Optional)</span></label>
                  <Input
                    classes={{ root: styles.input }}
                    onChange={event => this.setName(event)}
                    value={name}
                    placeholder="Ex. Near home, near work..."
                    fullWidth />
                </div>
              </div>
            </div>
          }
          {step === 5 &&
            <div className={styles.content}>
              <div className={styles.summary}>
                <div className={styles.list}>
                  <div>In summary, you&apos;ll receive</div>
                  <ul>
                    <li><div><span>{frequencyOptions[frequency]}</span>&nbsp;notifications</div></li>
                    <li><div>About&nbsp;{this.getDataTypesList()}&nbsp;in this area
                      {name && <div>&nbsp;(<span>{name}</span>)</div>}</div></li>
                    <li><div>At&nbsp;<span>{email}</span></div></li>
                  </ul>
                </div>
                <Button
                  variant="flat"
                  classes={{ root: styles.save }}
                  color="secondary"
                  onClick={this.save}
                >Save</Button>
              </div>
            </div>
          }
          <MobileDrawingTools mapRef={this.props.mapRef} active={step === 2 && !shape} />
        </div>
      );
    }
    return <ConfirmationPage />;
  }
}

MobileNotifications.propTypes = {
  closeNotifications: PropTypes.func,
  dataTypes: PropTypes.array,
  email: PropTypes.string,
  emailError: PropTypes.bool,
  frequency: PropTypes.number,
  frequencyOptions: PropTypes.array,
  mapRef: PropTypes.object,
  name: PropTypes.string,
  resetNotificationForm: PropTypes.func,
  saveNotification: PropTypes.func,
  setNotificationDataTypes: PropTypes.func,
  setNotificationDrawingShape: PropTypes.func,
  setNotificationEmail: PropTypes.func,
  setNotificationFrequency: PropTypes.func,
  setNotificationName: PropTypes.func,
  setNotificationStep: PropTypes.func,
  shape: PropTypes.array,
  shapeError: PropTypes.object,
  step: PropTypes.number,
  success: PropTypes.bool,
  visible: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    visible: getNotificationsVisible(state),
    shape: getNotificationsDrawingShape(state),
    step: getNotificationsStep(state),
    dataTypes: getNotificationsDataTypes(state),
    frequency: getNotificationsFrequency(state),
    email: getNotificationsEmail(state),
    emailError: getNotificationsEmailError(state),
    name: getNotificationsName(state),
    success: getNotificationsSuccess(state),
    shapeError: getNotificationsShapeError(state)
  };
};

export default connect(mapStateToProps, {
  closeNotifications,
  setNotificationDrawingShape,
  setNotificationStep,
  setNotificationDataTypes,
  setNotificationFrequency,
  setNotificationEmail,
  setNotificationName,
  saveNotification,
  resetNotificationForm
})(MobileNotifications);
