import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { debounce } from 'lodash';

import Icon from '@material-ui/core/Icon';
import Select, { components } from 'react-select';

import LocationButton from '../../map/location-button';

import {
  fetchGeocodeSuggestions,
  setSearchTerm,
  setSearchSelection,
  clearSearchSuggestions,
  setSearchFocused,
  clearSearchSelection
} from '../../../actions/search-actions';
import { clearInitialUrlFilter } from '../../../actions/filter-actions';
import {
  getSelection,
  getSuggestions,
  getSearchTerm,
  getSearchFocused,
  getLinkedElement
} from '../../../selectors/search';
import {
  getInitialUrlFilter
} from '../../../selectors/filters';

import { centerOnBoundingBox } from '../../../utilities/map-utilities';

import styles from './map-search-autocomplete.scss';

const ident = value => value;

class MapSearchAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = { input: null };
    this.getOptions = debounce(this.getOptions.bind(this), 500);

    this.changeValues = this.changeValues.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.onClose = this.onClose.bind(this);
    this.nullRenderer = this.nullRenderer.bind(this);
    this.controlRenderer = this.controlRenderer.bind(this);
    this.dropDownRenderer = this.dropDownRenderer.bind(this);
    this.clearRenderer = this.clearRenderer.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    const { mobile, selection } = this.props;
    if (mobile && selection && selection.label) {
      this.getOptions(selection.label);
    }
  }

  componentDidUpdate(prevProps) {
    const { linkedElement } = this.props;
    if (prevProps.linkedElement !== linkedElement) {
      this.changeValues(linkedElement, '');
    }
  }

  changeValues = (selected, { action }) => {
    this.setState({ input: null });
    const { mapRef } = this.props;
    if ((action === 'clear' || action === 'select-option') && this.props.initialUrlFilter) {
      this.props.clearInitialUrlFilter();
    }
    if (selected) {
      const {length = null, bbox} = selected;
      if (length !== null) {
        return;
      }
      this.props.setSearchSelection(selected);
      if (bbox && mapRef) {
        centerOnBoundingBox(bbox, mapRef);
      }
    } else {
      this.props.clearSearchSelection();
    }
  };

  inputChange = (value, { action }) => {
    // console.log('action')
    if (action === 'input-change') {
      this.setState({ input: value });
      this.props.clearSearchSuggestions();
      if (value) {
        this.getOptions(value);
        return value;
      }
      this.props.clearSearchSelection();
    }
    if (action === 'input-blur') {
      this.setState({ input: null });
    }
    return null;
  };

  getOptions = (value) => {
    this.props.fetchGeocodeSuggestions(value);
    this.props.setSearchTerm(value);
  };

  onClose = () => {
    if (!this.props.mobile) {
      this.props.clearSearchSuggestions();
      this.props.setSearchFocused(false);
    }
  };

  nullRenderer = () => {
    return null;
  };

  controlRenderer = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        {this.props.showMagnifier &&
          <div className={styles.searchIcon}><Icon translate="no">search</Icon></div>
        }
        {children}
      </components.Control>
    );
  };

  dropDownRenderer = () => {
    if (this.props.showMyLocation) {
      return <div className={styles.locationPad} />;
    }
    return null;
  };

  clearRenderer = ({ innerProps }) => {
    const { showClearIcon, selection } = this.props;
    if (showClearIcon) {
      return (
        <div
          {...innerProps}
          className={styles.clearIcon}
        >
          {selection.type === 'Wards' ?
            <div>CLEAR</div> :
            <Icon translate="no">cancel</Icon>
          }
        </div>
      );
    }
    return null;
  };

  onFocus = () => (this.props.setSearchFocused(true));
  onBlur = () => (!this.props.mobile && this.props.setSearchFocused(false));

  render() {
    const { suggestions = null, selection, searchTerm, landing, mobile, focused } = this.props;
    const { input } = this.state;
    const loading = Boolean(searchTerm && suggestions === null);
    const menu = input && !loading && suggestions && !mobile;
    const inputValue = (
      input !== null ? input : selection && selection.label || ''
    );

    return (
      <div className={
        classNames(
          styles.mapSearchAutocompleteContainer,
          {
            [styles.landingSearchContainer]: landing,
            [styles.mobileSearchInput]: mobile,
            [styles.hasMenu]: menu
          }
        )
      }>
        <Select
          autoFocus={mobile || focused} // eslint-disable-line jsx-a11y/no-autofocus
          backspaceRemovesValue={false}
          blurInputOnSelect
          classNamePrefix={styles.mapSearchPrefix}
          components={{
            Control: this.controlRenderer,
            DropdownIndicator: this.dropDownRenderer,
            IndicatorSeparator: this.nullRenderer,
            ClearIndicator: this.clearRenderer
          }}
          placeholder={<div>Address, Neighborhood, ZIP code,<span translate="no">&nbsp;Ward</span></div>}
          noOptionsMessage={() => 'No results found'}
          isLoading={loading}
          isClearable={selection}
          menuIsOpen={menu}
          options={suggestions || []}
          formatOptionLabel={
            ({ label, props }) => {
              return (<span {...props}>{label}</span>);
            }
          }
          filterOption={ident}
          value={selection}
          onChange={this.changeValues}
          inputValue={inputValue}
          onInputChange={this.inputChange}
          onMenuClose={this.onClose}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {this.props.showMyLocation &&
          <div className={styles.locationOverlay}>
            <LocationButton search />
          </div>
        }
      </div>
    );
  }
}

MapSearchAutocomplete.propTypes = {
  clearInitialUrlFilter: PropTypes.func,
  clearSearchSelection: PropTypes.func,
  clearSearchSuggestions: PropTypes.func,
  fetchGeocodeSuggestions: PropTypes.func,
  focused: PropTypes.bool,
  initialUrlFilter: PropTypes.bool,
  landing: PropTypes.bool,
  linkedElement: PropTypes.object,
  mapRef: PropTypes.object,
  mobile: PropTypes.bool,
  searchTerm: PropTypes.string,
  selection: PropTypes.object,
  setSearchFocused: PropTypes.func,
  setSearchSelection: PropTypes.func,
  setSearchTerm: PropTypes.func,
  showClearIcon: PropTypes.bool,
  showMagnifier: PropTypes.bool,
  showMyLocation: PropTypes.bool,
  suggestions: PropTypes.array,
  value: PropTypes.string
};

const mapStateToProps = state => {
  return {
    linkedElement: getLinkedElement(state),
    searchTerm: getSearchTerm(state),
    selection: getSelection(state),
    suggestions: getSuggestions(state),
    focused: getSearchFocused(state),
    initialUrlFilter: getInitialUrlFilter(state)
  };
};

export default connect(mapStateToProps, {
  fetchGeocodeSuggestions,
  setSearchTerm,
  setSearchSelection,
  clearSearchSuggestions,
  setSearchFocused,
  clearSearchSelection,
  clearInitialUrlFilter
})(MapSearchAutocomplete);

