// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-translate-button-___translate__translate-button{min-width:2.375rem;height:2.375rem;padding:0.625rem;border-radius:3px;font-weight:normal;color:rgba(0,0,0,0.4);text-transform:none;background-color:#fff;cursor:pointer}@media only screen and (min-device-width: 768px){.src-components-shared-translate-button-___translate__translate-button{box-shadow:0 2px 4px 1px rgba(183,183,183,0.5)}}.src-components-shared-translate-button-___translate__translate-button .src-components-shared-translate-button-___translate__translate-icon{margin-right:0.5rem;font-size:1rem;color:#4284F4}.src-components-shared-translate-button-___translate__translate-button:hover{background-color:#fff}.src-components-shared-translate-button-___translate__translate-button.src-components-shared-translate-button-___translate__translate-button-mobile{background-color:transparent;color:white}
`, "",{"version":3,"sources":["webpack://./src/components/shared/translate-button/translate.scss"],"names":[],"mappings":"AAGA,uEACE,kBAAmB,CACnB,eAAgB,CAChB,gBAAiB,CACjB,iBAAkB,CAClB,kBAAmB,CACnB,qBAAyB,CACzB,mBAAoB,CACpB,qBAAsB,CACtB,cAAe,CAEf,iDAXF,uEAYI,8CAAkD,CAOrD,CAnBD,4IAeI,mBAAoB,CACpB,cAAe,CACf,aAAc,CACf,6EAID,qBAAsB,CACvB,oJAIC,4BAA6B,CAC7B,WAAY","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.translate-button {\n  min-width: 2.375rem;\n  height: 2.375rem;\n  padding: 0.625rem;\n  border-radius: 3px;\n  font-weight: normal;\n  color: rgba(0, 0, 0, 0.4);\n  text-transform: none;\n  background-color: #fff;\n  cursor: pointer;\n\n  @media only screen and (min-device-width: $desktop-device-width) {\n    box-shadow: 0 2px 4px 1px rgba(183, 183, 183, 0.5);\n  }\n  .translate-icon {\n    margin-right: 0.5rem;\n    font-size: 1rem;\n    color: #4284F4;\n  }\n}\n\n.translate-button:hover {\n  background-color: #fff;\n}\n\n\n.translate-button.translate-button-mobile {\n  background-color: transparent;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"translate-button": `src-components-shared-translate-button-___translate__translate-button`,
	"translateButton": `src-components-shared-translate-button-___translate__translate-button`,
	"translate-icon": `src-components-shared-translate-button-___translate__translate-icon`,
	"translateIcon": `src-components-shared-translate-button-___translate__translate-icon`,
	"translate-button-mobile": `src-components-shared-translate-button-___translate__translate-button-mobile`,
	"translateButtonMobile": `src-components-shared-translate-button-___translate__translate-button-mobile`
};
export default ___CSS_LOADER_EXPORT___;
