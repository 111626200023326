/* eslint-disable id-length */
//
// Utility methods to process markers.
//
// The old version of this code used Ramda for array and object processing,
// however since this is a critical part of the code, all that code was converted
// to plain Javascript which has better performance (i.e. Object.keys() instead of
// Ramda's keys(), Object.values() vs values(), Object.map, using Set(), etc).
import { GOOGLE_MAPS_MAX_ZOOM, PROXIMITY_CLUSTER_MAX_ZOOM } from '../constants/google-maps';
import { bboxCombine, getBboxCenter } from '../utilities/geometry-utilities';

export const mapZoomToClusterPrefix = zoom => {
  let tempZoom = zoom;
  // If we are above the proximity cluster's max zoom level
  // adjust zoom to maps max zoom to surpress proximity clustering
  if (zoom > PROXIMITY_CLUSTER_MAX_ZOOM) {
    tempZoom = GOOGLE_MAPS_MAX_ZOOM;
  }
  return Math.ceil((tempZoom - 0.5) / 2.5);
};

export const getMaxPrefix = () => mapZoomToClusterPrefix(GOOGLE_MAPS_MAX_ZOOM);

export const createOverlaps = (layerLists = {}, prefixLength) => {
  const overlaps = {};
  const maxPrefix = prefixLength || getMaxPrefix();
  Object.entries(layerLists).forEach(([layerId, layerList]) => {
    layerList.forEach(item => {
      if (item.geohash) {
        const hash = item.geohash.substring(0, maxPrefix);
        const {lng, lat} = item.center;
        if (!overlaps[hash]) {
          overlaps[hash] = {
            markerBbox: [lng, lat, lng, lat],
            bbox: item.bbox,
            count: 1,
            items: {[layerId]: [item.id]}
          };
        } else {
          const overlap = overlaps[hash];
          overlap.markerBbox = bboxCombine(overlap.markerBbox, [lng, lat, lng, lat]);
          overlap.bbox = bboxCombine(overlap.bbox, item.bbox);
          overlap.count = overlap.count + 1;
          if (!overlap.items[layerId]) {
            overlap.items[layerId] = [item.id];
          } else {
            overlap.items[layerId].push(item.id);
          }
        }
      }
    });
  });
  return overlaps;
};

export const filterClusters = (clusters, validFunc) => {
  const validClusters = {};
  Object.entries(clusters).forEach(([geohash, cluster]) => {
    if (validFunc(cluster)) {
      validClusters[geohash] = cluster;
    }
  });
  return validClusters;
};

const markerSizes = {
  1: 'sm',
  2: 'md',
  3: 'lg',
  4: 'xl',
  5: 'max'
};

export const getClusterMarkerClasses = ({count, overlaps}) => {
  // Increase the diameter and font depending on the items count:
  const digits = count.toString().length;
  return [
    markerSizes[Math.max(1, Math.min(5, digits))],
    overlaps.length > 1 ? 'cluster' : 'overlap'
  ];
};

export const createClusters = (overlaps, prefixLength) => {
  const clusters = {};
  Object.entries(overlaps).forEach(([geohash, overlap]) => {
    const prefix = geohash.substring(0, prefixLength);
    if (!clusters[prefix]) {
      clusters[prefix] = {
        markerBbox: overlap.markerBbox,
        bbox: overlap.bbox,
        count: overlap.count,
        overlaps: [overlap],
        items: {...overlap.items}
      };
    } else {
      const cluster = clusters[prefix];
      cluster.markerBbox = bboxCombine(cluster.markerBbox, overlap.markerBbox);
      cluster.bbox = bboxCombine(cluster.bbox, overlap.bbox);
      cluster.count = cluster.count + overlap.count;
      cluster.overlaps.push(overlap);
      Object.entries(overlap.items).forEach(([layerId, elementIds]) => {
        if (cluster.items[layerId]) {
          cluster.items[layerId] = [...cluster.items[layerId], ...elementIds];
        } else {
          cluster.items[layerId] = elementIds;
        }
      });
    }
  });

  const filteredClusters = filterClusters(clusters, cluster => cluster.count > 1);
  Object.values(filteredClusters).forEach(cluster => {
    const markerBboxCenter = getBboxCenter(cluster.markerBbox).geometry.coordinates;
    cluster.marker = {
      lng: markerBboxCenter[0],
      lat: markerBboxCenter[1]
    };
    const bboxCenter = getBboxCenter(cluster.bbox).geometry.coordinates;
    cluster.center = {
      lng: bboxCenter[0],
      lat: bboxCenter[1]
    };
    cluster.classes = getClusterMarkerClasses(cluster);
  });
  return filteredClusters;
};
