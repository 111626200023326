import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationIcon from '../notification-icon';

import { getNotificationsVisible, getNotificationsTooltipVisible } from '../../../selectors/notifications';
import { closeNotificationTooltip } from '../../../actions/notification-actions';

import styles from './notification-tooltip.scss';

class NotificationTooltip extends Component {
  render() {
    if (this.props.notificationsVisible || !this.props.tooltipVisible) {
      return null;
    }
    return (
      <div className={styles.container}>
        <div>
          <div className={styles.header}>Get notifications for your area</div>
          <div className={styles.content}>
            Now you can get notified of what’s happening in your area. Click the bell
            <span translate="no" className={styles.bell}>(<NotificationIcon mobile />)</span>
            icon to get started.
          </div>
        </div>
        <div className={styles.footer}>
          <span onClick={this.props.closeNotificationTooltip}>Got it</span>
        </div>
      </div>
    );
  }
}

NotificationTooltip.propTypes = {
  closeNotificationTooltip: PropTypes.func,
  notificationsVisible: PropTypes.bool,
  tooltipVisible: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    notificationsVisible: getNotificationsVisible(state),
    tooltipVisible: getNotificationsTooltipVisible(state)
  };
};

export default connect(mapStateToProps, {closeNotificationTooltip})(NotificationTooltip);
