import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import SmallMedia from '../../shared/media/small';
import LargeMedia from '../../shared/media/large';

import { setMapTypeId, toggleTraffic } from '../../../actions/map-actions';
import { getMapTypeId, getTraffic } from '../../../selectors/map';

import styles from './map-types.scss';

class MapTypes extends Component {
  setRoadmap = () => this.props.setMapTypeId('roadmap');
  setHybrid = () => this.props.setMapTypeId('hybrid');
  toggleTraffic = () => this.props.toggleTraffic();
  
  render() {
    const { mapTypeId, traffic } = this.props;
    return (
      <Fragment>
        <SmallMedia>
          <div className={styles.mobileButtonGroup}>
            <div className={styles.button} onClick={this.setRoadmap}>
              <div className={mapTypeId === 'roadmap' ? styles.selected : null}><Icon translate="no">map</Icon></div>
              <label>Default</label>
            </div>
            <div className={styles.button} onClick={this.setHybrid}>
              <div className={mapTypeId === 'hybrid' ? styles.selected : null}><Icon translate="no">satellite</Icon></div>
              <label>Satellite</label>
            </div>
            <div className={styles.button} onClick={this.toggleTraffic}>
              <div className={traffic ? styles.selected : null}><Icon translate="no">traffic</Icon></div>
              <label>Traffic</label>
            </div>
          </div>
        </SmallMedia>
        <LargeMedia>
          <div className={styles.buttonGroup}>
            <Tooltip title="Satellite" placement="bottom">
              <Button classes={{root: styles.left}} onClick={this.setHybrid}>
                <div className={mapTypeId === 'hybrid' ? styles.selected : null}><Icon translate="no">satellite</Icon></div>
              </Button>
            </Tooltip>
            <Tooltip title="Map" placement="bottom">
              <Button onClick={this.setRoadmap}>
                <div className={mapTypeId === 'roadmap' ? styles.selected : null}><Icon translate="no">map</Icon></div>
              </Button>
            </Tooltip>
            <Tooltip title="Traffic" placement="bottom">
              <Button classes={{root: styles.right}} onClick={this.toggleTraffic}>
                <div className={traffic ? styles.selected : null}><Icon translate="no">traffic</Icon></div>
              </Button>
            </Tooltip>
          </div>
        </LargeMedia>
      </Fragment>
    );
  }
}

MapTypes.propTypes = {
  mapTypeId: PropTypes.string,
  setMapTypeId: PropTypes.func,
  toggleTraffic: PropTypes.func,
  traffic: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    mapTypeId: getMapTypeId(state),
    traffic: getTraffic(state)
  };
};

export default connect(mapStateToProps, {setMapTypeId, toggleTraffic})(MapTypes);
