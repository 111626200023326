import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import OverlapCountMarker from './overlap-count-marker';

import { getVisibleClusters } from '../../../selectors/clusters';
import { getNotificationsVisible } from '../../../selectors/notifications';

class MarkerOverlaps extends Component {
  render() {
    const { clusters, mapRef } = this.props;
    if (this.props.notificationsVisible) {
      return null;
    }
    return (
      <Fragment>
        {Object.entries(clusters).map(([prefix, { selectionCount, cluster }]) => (
          <OverlapCountMarker
            key={prefix}
            prefix={prefix}
            cluster={cluster}
            selectionCount={selectionCount}
            mapRef={mapRef}
          />
        ))}
      </Fragment>
    );
  }
}

MarkerOverlaps.propTypes = {
  clusters: PropTypes.object,
  mapRef: PropTypes.object,
  notificationsVisible: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    clusters: getVisibleClusters(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MarkerOverlaps);
