import React from 'react';
import styles from './error-404.scss';

const Error404 = () => (
  <div className={styles.container}>
    <div className={styles.title}>Error</div>
    <div><img src="https://storage.googleapis.com/dotmaps-public-chicago/branding/404.svg" alt="" /></div>
    <div className={styles.content}>We’re sorry, but the page you’re looking for could not be found.</div>
    <div><a href="/map">Go to Homepage</a></div>
  </div>
);

export default Error404;
