import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import getReducers from '../reducers';

export const history = createBrowserHistory();

function configureStoreProd(initialState) {
  return configureStore({
    reducer: getReducers(history),
    preloadedState: initialState,
    middleware: getDefaultMiddleware => ([
      ...getDefaultMiddleware(),
      routerMiddleware(history)
    ]),
    devTools: false
  });
}

function configureStoreDev(initialState) {
  const sanitizeMapDataList = (state) => ({
    ...state,
    map: {
      ...state.map,
      data: Object.keys(state.map.data).reduce(
        (acc, key) => {
          acc[key] = {
            ...state.map.data[key],
            list: state.map.data[key].list.slice(0, 10)
          };
          return acc;
        },
        {}
      )
    }
  });

  const store = configureStore({
    reducer: getReducers(history),
    preloadedState: initialState,
    middleware: getDefaultMiddleware => ([
      ...getDefaultMiddleware({
        immutableCheck: {
          ignoredPaths: ['map.data']
        }
      }),
      routerMiddleware(history)
    ]),
    devTools: {
      stateSanitizer: state => sanitizeMapDataList(state)
    }
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const hotCreateRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(hotCreateRootReducer(history));
    });
  }
  return store;
}

const configureStore_ = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore_;
