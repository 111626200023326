// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-disclaimer-disclaimer-body-___disclaimer-body__disclaimer{flex-grow:1;font-size:.875em;line-height:1.3125rem;color:#333}.src-components-disclaimer-disclaimer-body-___disclaimer-body__disclaimer>*:not(:last-child){display:block;margin-bottom:1em}
`, "",{"version":3,"sources":["webpack://./src/components/disclaimer/disclaimer-body/disclaimer-body.scss"],"names":[],"mappings":"AAGA,0EACI,WAAY,CACZ,gBAAiB,CACjB,qBAAsB,CACtB,UAAW,CAJf,6FAOQ,aAAc,CACd,iBAAkB","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.disclaimer {\n    flex-grow: 1;\n    font-size: .875em;\n    line-height: 1.3125rem;\n    color: #333;\n\n    & > *:not(:last-child) {\n        display: block;\n        margin-bottom: 1em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": `src-components-disclaimer-disclaimer-body-___disclaimer-body__disclaimer`
};
export default ___CSS_LOADER_EXPORT___;
