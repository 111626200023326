import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DisclaimerText from './disclaimer-body';

import { getDialog } from '../../selectors/ui';

import { closeDisclaimerDialog } from '../../actions/ui-actions';

import styles from './disclaimer.scss';

class Disclaimer extends Component {
  render() {
    return (
      <Dialog
        open={this.props.dialog.disclaimerVisible}
        onClose={this.props.closeDisclaimerDialog}
        keepMounted
      >
        <DialogTitle classes={{ root: styles.title }}>Disclaimer</DialogTitle>
        <DialogContent classes={{ root: styles.content }}>
          <DialogContentText>
            <DisclaimerText />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: styles.actionButtons }}>
          <Button
            onClick={this.props.closeDisclaimerDialog}
            disableRipple
          >
            I UNDERSTAND
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

Disclaimer.propTypes = {
  closeDisclaimerDialog: PropTypes.func,
  dialog: PropTypes.object
};

const mapStateToProps = state => {
  return {
    dialog: getDialog(state)
  };
};

export default connect(mapStateToProps, { closeDisclaimerDialog })(Disclaimer);
