import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SubLegend from '../sub-legend';

import { getLegend } from '../../../../selectors/ui';
import { getNotificationsVisible } from '../../../../selectors/notifications';

import { openLegend, closeLegend } from '../../../../actions/ui-actions';
import { getNoTranslate } from '../../../../utilities/map-data-utilities';

import styles from './map-legend.scss';

class MapLegend extends Component {
  componentDidMount() {
    const { defaultOpen } = this.props;
    if (defaultOpen) {
      this.props.openLegend();
    }
  }

  render() {
    const { legend, mapData } = this.props;
    if (legend.visible && !this.props.notificationsVisible) {
      let emptyList = true;
      return (
        <List classes={{ root: styles.mapLegend }} >
          {mapData.map(data => {
            if (data.uiStyle.showInLegend) {
              emptyList = false;
              const notranslate = getNoTranslate('label', data);
              const textProps = {};
              if (notranslate) {
                textProps.translate = 'no';
              }
              return (
                <ListItem key={data.id} classes={{ root: styles.legendItem }}>
                  <ListItemIcon
                    classes={{root: styles.legendIcon}}
                  >
                    {data.uiStyle.legendIcon &&
                      <img src={data.uiStyle.legendIcon} className={styles.legendIcon} alt=""/>
                    }
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      root: styles.legendText,
                      primary: styles.primary
                    }}
                    primary={data.uiStyle.legendLabel || data.label}
                    {...textProps}
                  />
                  {data.uiStyle.subLegendItems &&
                    <SubLegend
                      classes={{ root: styles.legendSubIcon }}
                      label={data.uiStyle.subLegendLabel}
                      items={data.uiStyle.subLegendItems}
                    />
                  }
                </ListItem>
              );
            }
            return null;
          }
          )}
          {emptyList &&
            <ListItem classes={{ root: styles.legendItem }}>
              <ListItemText
                classes={{ root: styles.emptyState }}
                primary="No legend to show"
              />
            </ListItem>
          }
        </List>
      );
    }
    return null;
  }
}

MapLegend.propTypes = {
  closeLegend: PropTypes.func,
  defaultOpen: PropTypes.bool,
  legend: PropTypes.object,
  mapData: PropTypes.array,
  notificationsVisible: PropTypes.bool,
  openLegend: PropTypes.func
};

const mapStateToProps = state => {
  return {
    legend: getLegend(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, { openLegend, closeLegend })(MapLegend);
