/* global google */
/* eslint-disable id-length */
import _ from 'lodash';
import invariant from 'invariant';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const eventMap = {};

const updaterMap = {};

import { componentDidMount, componentDidUpdate, componentWillUnmount } from 'react-google-maps/lib/utils/MapChildHelper';

import { getOffsetOverride, getLayoutStyles } from './OverlayViewHelper';

import { MAP, ANCHOR, OVERLAY_VIEW } from 'react-google-maps/lib/constants';

export const __jscodeshiftPlaceholder__ = `{
  "eventMapOverrides": { 
  },
  "getInstanceFromComponent": "this.state[OVERLAY_VIEW]"
}`;

import { addOverlayListeners, removeOverlayListeners } from './events';

/**
 * @url https://developers.google.com/maps/documentation/javascript/3.exp/reference#OverlayView
 */
export class OverlayView extends React.PureComponent {
  /*
   * @url https://developers.google.com/maps/documentation/javascript/3.exp/reference#OverlayView
   */
  constructor(props, context) {
    super(props, context);
    const overlayView = new google.maps.OverlayView();
    // You must implement three methods: onAdd(), draw(), and onRemove().
    overlayView.onAdd = _.bind(this.onAdd, this);
    overlayView.draw = _.bind(this.draw, this);
    overlayView.onRemove = _.bind(this.onRemove, this);
    this.onPositionElement = _.bind(this.onPositionElement, this);
    // You must call setMap() with a valid Map object to trigger the call to
    // the onAdd() method and setMap(null) in order to trigger the onRemove() method.
    overlayView.setMap(this.context[MAP]);
    this.state = {
      [OVERLAY_VIEW]: overlayView
    };
  }

  componentDidMount() {
    componentDidMount(this, this.state[OVERLAY_VIEW], eventMap);
  }

  componentDidUpdate(prevProps) {
    componentDidUpdate(this, this.state[OVERLAY_VIEW], eventMap, updaterMap, prevProps);
    _.delay(this.state[OVERLAY_VIEW].draw);

    if (this.containerElement) {
      this.containerElement.style.transform = this.props.transform;
      this.containerElement.style.msTransform = this.props.msTransform;
      this.containerElement.style.zIndex = this.props.zIndex;
      this.containerElement.className = this.props.className;
    }
  }

  componentWillUnmount() {
    componentWillUnmount(this);
    const overlayView = this.state[OVERLAY_VIEW];
    if (overlayView) {
      overlayView.setMap(null);
      // You must implement three methods: onAdd(), draw(), and onRemove().
      overlayView.onAdd = null;
      overlayView.draw = null;
      overlayView.onRemove = null;
    }
  }

  onAdd() {
    this.containerElement = document.createElement('div');
    const style = {
      position: 'absolute',
      // Set a default cursor for overlays, so it's not defined by background map elements.
      cursor: 'default',
      ...(this.props.style || {})
    };
    _.assign(this.containerElement.style, style);

    this.containerElement.className = this.props.className;

    addOverlayListeners(this.containerElement);
  }
  
  draw() {
    const { mapPaneName } = this.props;
    invariant(!!mapPaneName, 'OverlayView requires either props.mapPaneName or props.defaultMapPaneName but got %s', mapPaneName);
    // https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapPanes
    const mapPanes = this.state[OVERLAY_VIEW].getPanes();
    if (mapPanes) {
      const mapPane = mapPanes[mapPaneName];
      if (mapPane) {
        mapPane.appendChild(this.containerElement);
      }
    }

    if (this.containerElement) {
      ReactDOM.unstable_renderSubtreeIntoContainer(this, this.props.children, this.containerElement, this.onPositionElement);
    }
  }

  onPositionElement() {
    // https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapCanvasProjection
    const mapCanvasProjection = this.state[OVERLAY_VIEW].getProjection();

    const offset = {
      x: 0,
      y: 0,
      ...getOffsetOverride(this.containerElement, this.props)
    };
    const layoutStyles = getLayoutStyles(mapCanvasProjection, offset, this.props);
    const { style } = this.props;
    _.assign(this.containerElement.style, { ...layoutStyles, ...style });
  }

  onRemove() {
    if (this.containerElement) {
      removeOverlayListeners(this.containerElement);
      this.containerElement.parentNode.removeChild(this.containerElement);
      ReactDOM.unmountComponentAtNode(this.containerElement);
    }
    this.containerElement = null;
    window.isOverlayViewOnFocus = false;
  }

  render() {
    return false;
  }
}

OverlayView.propTypes = {
  __jscodeshiftPlaceholder__: PropTypes.func,
  bounds: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  getPixelPositionOffset: PropTypes.func,
  mapPaneName: PropTypes.string,
  msTransform: PropTypes.string,
  position: PropTypes.object,
  style: PropTypes.object,
  transform: PropTypes.string,
  zIndex: PropTypes.number
};

OverlayView.contextTypes = {
  [MAP]: PropTypes.object,
  [ANCHOR]: PropTypes.object
};

export default OverlayView;

