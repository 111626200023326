/* eslint-disable no-use-before-define */
import axios from 'axios';
import * as Ramda from 'ramda';
import { isOldIE } from './browser-detect';
import { bboxToGoogleMapsBounds } from './geometry-utilities';
import distance from '@turf/distance';
import { polygon } from '@turf/helpers';

export const zipCoordinatePair = Ramda.zipObj(['lng', 'lat']);
// zipCoordinatePair([-141, 35]) will return { lat: 35, lng: -141}

export const generatePath = coordinates => coordinates.map(zipCoordinatePair);

export const getPointShapeCoords = shape => {
  return zipCoordinatePair(shape.coordinates);
};

const optimizePoint = ({ center, shape, ...data }) => ({
  ...data,
  shape,
  center: center ? getPointShapeCoords(center) : null,
  position: getPointShapeCoords(shape)
});

const optimizePath = ({ center, shape, ...data }) => ({
  ...data,
  shape,
  center: center ? getPointShapeCoords(center) : null,
  path: generatePath(shape.coordinates)
});

const optimizePolygon = ({ center, shape, ...data }) => ({
  ...data,
  shape,
  center: center ? getPointShapeCoords(center) : null,
  paths: shape.coordinates.map(generatePath)
});

const optimizeMulti = ({ id, shape, ...data }) => {
  const type = shape.type.slice(5);
  return {
    id,
    ...data,
    shape,
    childElements: shape.coordinates.map(
      coordinates => ({
        id,
        shape: {type},
        ...optimizeDataForMap({shape: {type, coordinates}})
      })
    )
  };
};

const optimizeCollection = ({id, shape, ...data}) => ({
  id,
  ...data,
  shape,
  childElements: shape.geometries.map(
    (type, coordinates) => ({
      id,
      shape: {type},
      ...optimizeDataForMap({shape: {type, coordinates}})
    })
  )
});

export const optimizeDataForMap = Ramda.cond([
  [Ramda.pathEq('Point', ['shape', 'type']), optimizePoint],
  [Ramda.pathEq('LineString', ['shape', 'type']), optimizePath],
  [Ramda.pathEq('Polygon', ['shape', 'type']), optimizePolygon],
  [Ramda.pathEq('GeometryCollection', ['shape', 'type']), optimizeCollection],
  [Ramda.pathSatisfies(type => type.startsWith('Multi'), ['shape', 'type']), optimizeMulti]
]);

export const getMapIcon = (url, active = false) => {
  if (url) {
    const icon = {
      anchor: { x: active ? 18 : 15, y: active ? 32 : 15 },  // eslint-disable-line id-length
      url
    };
  
    if (isOldIE()) {
      // Specify the scaled size to avoid the 'InvalidStateError' on Google map's marker.js,
      // see this for more information:
      //
      //     https://stackoverflow.com/questions/27261346/custom-svg-markers-wont-display-in-ie-11
      //
      // We must also set the optimized property, but that's part of the Marker options,
      // not the icon ones (I added that where we define each Marker).
      //
      // It's better to only do this for IE, since this changes the Marker icon size slightly
      // (only 1px or 2), and it's better to not change anything for Chrome/Safari.
      icon.scaledSize = { width: 24, height: 24 };
    }
  
    return icon;
  }
  return null;
};

export const checkUrlSuccess = (url, method = 'GET') => {
  return axios({
    method: method.toLowerCase(),
    url
  }).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    throw new Error('Request failed with non OK response');
  });
};

export const centerOnBoundingBox = (bbox, mapRef) => {
  const bounds = bboxToGoogleMapsBounds(bbox);
  mapRef.panToBounds(bounds);
  mapRef.fitBounds(bounds, 0);
};

export const pixelDistanceToMeters = (latLng, googleMap, px) => {
  const zoomLevel = Math.max(13, googleMap.getZoom()); // Treat zoom 13 as minimum for pixelRadius
  const radiusOffset = px / Math.pow(2, zoomLevel); // 1 point per pixel at zoom 0 halving for each zoom level higher.
  const projection = googleMap.getProjection();
  if (projection) {
    const offsetPixels = projection.fromLatLngToPoint(latLng);
    // eslint-disable-next-line id-length
    offsetPixels.x = offsetPixels.x + radiusOffset;
    const offsetLatLng = projection.fromPointToLatLng(offsetPixels);
    const {lng, lat} = latLng.toJSON();
    const {lng: offLng, lat: offLat} = offsetLatLng.toJSON();
    return distance([lng, lat], [offLng, offLat], {units: 'meters'});
  }
  return 0;
};

const closeRing = points => [...points, points[0]];

export const pointToArray = point => [point.lng(), point.lat()];

export const mapsPolygonToFeature = shape => polygon([closeRing(shape.map(pointToArray))]);
