const JS_TEMPLATE_WRAP = /^([^`]*)`([^`]*)`$/;
const JS_TEMPLATE_RE = /\${([^.}]*)\.?[^}]*}/g;


export const getNoTranslateLabel = (fieldLabel, layerData) => {
  const { uiStyle: { notranslate = [] } } = layerData;
  return notranslate.indexOf(`label:${fieldLabel}`) >= 0;
};

export const getNoTranslate = (fieldLabel, layerData) => {
  const { uiStyle: { notranslate = [] } } = layerData;
  return notranslate.indexOf(fieldLabel) >= 0;
};

const getField = (field, attributes, mapping) => {
  const value = attributes[field];
  return (mapping && mapping[field] && mapping[field][value]) || value;
};

const templateTags = {
  '': (attributes, mapping, strings, ...keys) => {
    const result = [strings[0]];
    keys.forEach((key, index) => {
      result.push(getField(key, attributes, mapping) || '', strings[index + 1]);
    });
    return result.join('');
  },
  isodate: (attributes, mapping, strings, key) => {
    return getField(key, attributes, mapping).split('T')[0];
  },
  concat: (attributes, mapping, strings, ...keys) => {
    const delim = strings[0] || ', ';
    const values = [];
    keys.forEach(key => {
      const value = getField(key, attributes, mapping);
      if (value) {
        values.push(value);
      }
    });
    return values.join(delim);
  }
};

export const resolveFields = (fields, attributes, mapping) => {
  if (typeof fields === 'string' || fields instanceof String) {
    const match = fields.match(JS_TEMPLATE_WRAP);
    if (match) {
      const tag = match[1];
      const template = match[2];
      const strings = [];
      const keys = [];
      template.split(JS_TEMPLATE_RE).forEach((value, index) => {
        if (index % 2) {
          keys.push(value);
        } else {
          strings.push(value);
        }
      });
      if (templateTags[tag]) {
        return templateTags[tag](attributes, mapping, strings, ...keys);
      }
      return templateTags[''](attributes, mapping, strings, ...keys);
    }
    return getField(fields, attributes, mapping);
  }
  const data = {};
  Object.entries(fields).forEach(([key, value]) => {
    data[key] = resolveFields(value, attributes, mapping);
  });
  return data;
};

export const getTitles = (mapData, detailData) => {
  const titles = [];
  const { data: { attrs }, layerData } = detailData;
  let filterId = layerData.id;
  while (filterId) {
    const data = mapData[filterId];
    const notranslate = getNoTranslate('label', data);
    if (data.label) {
      const title = { value: data.label, props: {} };
      if (notranslate) {
        title.props.translate = 'no';
      }
      titles.push(title);
    }
    filterId = data.parentId;
  }
  titles.reverse();
  const titleField = layerData.aliases.filter(([, label]) => label === 'title')[0];
  if (titleField) {
    const notranslate = getNoTranslate('title', layerData);
    const title = { value: resolveFields(titleField[0], attrs), props: {} };
    if (notranslate) {
      title.props.translate = 'no';
    }
    titles.push(title);
  }
  return titles;
};
