import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import SmallMedia from '../shared/media/small';
import FilterCard from './filter-card';
import InfoPopup from './info-popup';

import { getFilters, getDateRanges, getSelectedDateRangeIndex, getInitialUrlFilter } from '../../selectors/filters';
import { getlayerFilters } from '../../selectors/ui';
import { getStreetView } from '../../selectors/map';
import { getSearchFocused } from '../../selectors/search';

import {
  setInitialUrlFilter,
  clearInitialUrlFilter,
  fetchFilterStructure,
  fetchDateRanges,
  selectDateRange
} from '../../actions/filter-actions';
import { openLayerFilters, closeLayerFilters, openDisclaimerDialog } from '../../actions/ui-actions';

import styles from './filter-tray.scss';

class FilterTray extends Component {
  componentWillMount() {
    if (this.props.filters.length === 0) {
      this.props.fetchFilterStructure();
      this.props.fetchDateRanges();
    }
  }

  componentDidMount() {
    if (this.props.defaultOpen) {
      this.props.openLayerFilters();
    }

    const searchParams = window.location.search ? window.location.search.substr(1).split('=') : null;
    if (searchParams) {
      this.props.setInitialUrlFilter();
    } else {
      this.props.clearInitialUrlFilter();
    }
  }

  render() {
    const {
      layerFilters, dateRanges, selectedDateRange, streetView, initialUrlFilter
    } = this.props;
    if (initialUrlFilter === null) {
      return null;
    }
    return (
      <div className={classNames(styles.filterTrayWrap, { [styles.filterHidden]: streetView.visible })}>
        {layerFilters.visible &&
          <div className={styles.filterModalOverlay} onClick={this.props.closeLayerFilters} />
        }
        <div className={classNames(
          styles.filterTrayContainer, { [styles.filterTrayContainerOpen]: layerFilters.visible }
        )}>
          <div
            className={classNames(styles.filterHeader, { [styles.filterHeaderClosed]: !layerFilters.visible })}
            onClick={!layerFilters.visible ? this.props.openLayerFilters : null}
          >
            <div
              className={classNames(styles.filterHeaderOverlay, { [styles.filterHeaderOverlayOpen]: layerFilters.visible })}
            />
            {layerFilters.visible ?
              <div className={styles.headerText}>

                <div className={styles.logoContainer}>
                  <a href="https://www.cityofchicago.org/city/en/depts/cdot.html" target="_blank" rel="noopener noreferrer">
                    <div className={styles.logo} />
                  </a>
                </div>
                <div className={styles.text} translate="no">ChiStreetWork</div>
                {selectedDateRange !== null &&
                  <div className={styles.selectWrap}>
                    <Select
                      value={selectedDateRange}
                      onChange={(event) => this.props.selectDateRange(event.target.value)}
                      input={
                        <Input
                          name={'Date Range'}
                          id={'dateRange'}
                          classes={{
                            root: styles.selectRoot,

                            underline: styles.selectUnderline,
                            input: styles.selectInput
                          }}
                        />
                      }
                      classes={{
                        icon: styles.selectIcon
                      }}
                    >
                      {dateRanges.map((dateRange, index) => (
                        <MenuItem key={index} value={index}>{dateRange.description}</MenuItem>
                      ))};
                    </Select>
                    <InfoPopup label="Date" className={styles.infoIcon} >
                      Projects, permits, and events shown in this map are within the selected date range. To view additional future projects, adjust the date as needed.
                    </InfoPopup>
                  </div>
                }
              </div> :
              <div className={styles.menuButton}>
                <IconButton><Icon translate="no">menu</Icon></IconButton>
              </div>
            }
          </div>
          <div className={styles.filterContents}>
            {this.props.filters.length > 0 &&
              this.props.filters.map(filter => {
                return (
                  <div key={filter.id}>
                    <FilterCard {...filter} />
                  </div>
                );
              })}
          </div>
          {layerFilters.visible &&
            <Fragment>
              <div className={styles.filterFooter}>
                <div className={styles.linksContainer}>
                  <div className={styles.linkButton} onClick={this.props.openDisclaimerDialog}>
                    <span className={styles.filterFooterText}>Disclaimer</span>
                  </div>|
                  <div className={styles.linkButton}>
                    <a href="https://www.chicago.gov/city/en/general/privacy.html" target="_blank" rel="noopener noreferrer">
                      <span className={styles.filterFooterText}>Privacy policy</span>
                    </a>
                  </div>|
                  <div className={styles.linkButton}>
                    <a href="https://goo.gl/forms/Vq5BQt8vHnFmjdDI2" target="_blank" rel="noopener noreferrer">
                      <span className={styles.filterFooterText}>Send Feedback</span>
                    </a>
                  </div>
                </div>
              </div>
              <SmallMedia>
                <div className={styles.filterClose} onClick={this.props.closeLayerFilters}>SHOW MAP</div>
              </SmallMedia>
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

FilterTray.propTypes = {
  clearInitialUrlFilter: PropTypes.func,
  closeLayerFilters: PropTypes.func,
  dateRanges: PropTypes.array,
  defaultOpen: PropTypes.bool,
  fetchDateRanges: PropTypes.func,
  fetchFilterStructure: PropTypes.func,
  filters: PropTypes.array,
  initialUrlFilter: PropTypes.bool,
  layerFilters: PropTypes.object,
  openDisclaimerDialog: PropTypes.func,
  openLayerFilters: PropTypes.func,
  selectDateRange: PropTypes.func,
  selectedDateRange: PropTypes.number,
  setInitialUrlFilter: PropTypes.func,
  streetView: PropTypes.object
};

const mapStateToProps = state => {
  return {
    dateRanges: getDateRanges(state),
    filters: getFilters(state),
    initialUrlFilter: getInitialUrlFilter(state),
    layerFilters: getlayerFilters(state),
    selectedDateRange: getSelectedDateRangeIndex(state),
    streetView: getStreetView(state),
    searchFocused: getSearchFocused(state)
  };
};

export default connect(
  mapStateToProps,
  {
    setInitialUrlFilter,
    clearInitialUrlFilter,
    fetchFilterStructure,
    fetchDateRanges,
    selectDateRange,
    openLayerFilters,
    closeLayerFilters,
    openDisclaimerDialog
  }
)(FilterTray);
