// Helper functions to handle events on the overlay.
//
// We need the overlay to cancel all events (like scrolling, wheel and others),
// so if we click on it, we'll execute the overlay onClick handler and we don't pass
// this event to any element below it.
//
// However there's a problem, if we also cancel click events, they never will reach the overlay.
// So we let them pass. But that brings another problem, the click may hit a map
// marker which is covered by this overlay, opening another project or event in the tray.
//
// We think that we have the map as the base layer, then our overaly, so clicks should hit
// the overlay only, thus cancelling clicks would not make them reach the map, but that's
// not what's happening, here's the real order for a click event:
//
//     overlay => map markers => overlay
//
// The event hits first the overlay, which is just a div tag, then the map markers and the
// overlay components last.
//
// This happens, because React doesn't play well with other Javascript code which uses the DOM
// events. React attaches a single event listener to the 'document' (because of the different
// behavior of different browsers, they found it was better to handle it that way).
//
// There are several Github issues and pull requests for the official React project:
//
//     https://github.com/facebook/react/pull/7088
//     https://github.com/facebook/react/pull/8117
//     https://github.com/facebook/react/issues/8693
//
// about this problem, and they are all rejected or closed, since they won't change the current
// event system.
//
// Some sites also explain the problem, and there are libraries to solve it:
//
//     https://medium.com/@ericclemmons/react-event-preventdefault-78c28c950e46
//     https://github.com/erikras/react-native-listener
//
// But we cannot use that, since we need control over the onClick handlers, and components
// like material-ui Tabs, don't expose those handlers.
//
// Thus, there's no "correct" way to solve it, just workarounds, so it's correct to use
// a flag like window.isOverlayViewOnFocus to solve this problem.
//
//
// IMPORTANT:
//
// All this code was thought to workaround the event bubbling problems and click events
// for info-windows. We no longer use info-windows, but the map still uses an overlap
// to display the overlap counters (all the projects that have markers in a single position).
// Since we cannot draw a map marker with a number, an overlap was used (it looks like a
// marker, but has a white background and a number on it).
//
// Thus, although we got rid of the info-windows, we still need this code to avoid problems
// with the overlap counter marker and any marker behind it.
//

// This version is updated for cluster markers, to allow allmost all events to go to the map
// so it can be interacted with even when the mouse is on a marker
// The one exception is that map clicks are disabled for a short time after a mouseup event on a marker
// This ensure that if a click occurs on a marker, then no map clicks can occur at the same time.
// Previously we used mouse exit to re-enable map clicks, however this event does not occur on mobile
// so it is not workable.
let potentialOverlayClick = false;
const endEvents = new Set(['mouseup', 'touchend', 'touchcancel', 'pointerup']);
const cancelHandler = (event) => {
  // touchend events are being fired in the wrong order compared to google map's click event
  // So we disable map clicks for the duration of the whole touch.
  if (event.type === 'touchstart') {
    potentialOverlayClick = true;
  }

  // Cancel any clicks immediately after a mouse up inside of an overlay.
  // Click events always trigger after a mouse up event, so we can pre-empt it during the mouse up
  if (endEvents.has(event.type)) {
    potentialOverlayClick = true;
    setTimeout(() => {
      // Re enable clicks after a short time.
      potentialOverlayClick = false;
    }, 10);
  }
};

const events = [
  'mouseup'
];
if (window.TouchEvent) {
  events.push('touchstart');
  events.push('touchend');
  events.push('touchcancel');
}
if (window.PointerEvent) {
  events.push('pointerup');
}

export const addOverlayListeners = container => events.forEach(event => container.addEventListener(event, cancelHandler));

export const removeOverlayListeners = container => events.forEach(event => container.removeEventListener(event, cancelHandler));

export const ignoreIfOverlay = wrapped => (...args) => {
  if (!potentialOverlayClick) {
    wrapped(...args);
  }
};
