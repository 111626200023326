// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-mobile-search-results-___mobile-search-results__results-container{width:100%;height:100%;padding:1em;overflow-y:scroll;background-color:#fff;z-index:1000}.src-components-map-mobile-search-results-___mobile-search-results__results-container .src-components-map-mobile-search-results-___mobile-search-results__result-item{margin-bottom:1.25em;color:rgba(0,0,0,0.8)}.src-components-map-mobile-search-results-___mobile-search-results__results-container .src-components-map-mobile-search-results-___mobile-search-results__result-item .src-components-map-mobile-search-results-___mobile-search-results__main{margin-bottom:.25em}.src-components-map-mobile-search-results-___mobile-search-results__results-container .src-components-map-mobile-search-results-___mobile-search-results__result-item .src-components-map-mobile-search-results-___mobile-search-results__secondary{color:rgba(0,0,0,0.6);font-size:.875em}.src-components-map-mobile-search-results-___mobile-search-results__results-container .src-components-map-mobile-search-results-___mobile-search-results__header{color:rgba(0,0,0,0.8);font-size:.9375em;margin-bottom:1em;height:1.5em;font-weight:500}
`, "",{"version":3,"sources":["webpack://./src/components/map/mobile-search-results/mobile-search-results.scss"],"names":[],"mappings":"AAGA,sFACI,UAAW,CACX,WAAY,CACZ,WAAY,CACZ,iBAAkB,CAClB,qBAAsB,CACtB,YAAa,CANjB,sKASQ,oBAAqB,CACrB,qBAAsB,CAV9B,+OAaY,mBAAoB,CAbhC,oPAiBY,qBAAsB,CACtB,gBAAiB,CAlB7B,iKAuBQ,qBAAsB,CACtB,iBAAkB,CAClB,iBAAkB,CAClB,YAAa,CACb,eAAgB","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.results-container {\n    width: 100%;\n    height: 100%;\n    padding: 1em;\n    overflow-y: scroll;\n    background-color: #fff;\n    z-index: 1000;\n\n    .result-item {\n        margin-bottom: 1.25em;\n        color: rgba(0,0,0,0.8);\n\n        .main {\n            margin-bottom: .25em;\n        }\n\n        .secondary {\n            color: rgba(0,0,0,0.6);\n            font-size: .875em;\n        }\n    }\n\n    .header {\n        color: rgba(0,0,0,0.8);\n        font-size: .9375em;\n        margin-bottom: 1em;\n        height: 1.5em;\n        font-weight: 500;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"results-container": `src-components-map-mobile-search-results-___mobile-search-results__results-container`,
	"resultsContainer": `src-components-map-mobile-search-results-___mobile-search-results__results-container`,
	"result-item": `src-components-map-mobile-search-results-___mobile-search-results__result-item`,
	"resultItem": `src-components-map-mobile-search-results-___mobile-search-results__result-item`,
	"main": `src-components-map-mobile-search-results-___mobile-search-results__main`,
	"secondary": `src-components-map-mobile-search-results-___mobile-search-results__secondary`,
	"header": `src-components-map-mobile-search-results-___mobile-search-results__header`
};
export default ___CSS_LOADER_EXPORT___;
