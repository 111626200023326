const getEnvironmentUrl = () => {
  switch (process.env.NODE_ENV) {
  case 'production':
    return '';
  default:
    return 'https://dev-sada.chicitymap.org';
  }
};

const protocol = window.location.protocol;

export const BASE_URL = getEnvironmentUrl();
export const BASE_API_URL = `${BASE_URL}/publicapi`;
export const FORMAT = '?format=json';
export const BUCKET_ENDPOINT = `${protocol}//storage.googleapis.com/dotmaps-public-chicago`;

export const buildFullUrl = relativeUrl => `${BASE_URL}${relativeUrl}`;
export const getApiRequestUrl = (type, format = FORMAT) => `${BASE_API_URL}/${type}/${format}`;
export const getDirectionsUrl = (lat, lng) => `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;

