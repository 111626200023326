import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import { getMeasureToolLength, getMeasureToolActive } from '../../../selectors/map';
import { setMeasureActive } from '../../../actions/map-actions';

import styles from './measurement-card.scss';

class MeasurementCard extends Component {
  closeMeasurementCard = () => this.props.setMeasureActive(false);
  render() {
    if (this.props.active) {
      return (
        <Card classes={{root: styles.container}}>
          <Icon translate="no" classes={{root: styles.closeIcon}} onClick={this.closeMeasurementCard}>close</Icon>
          <label>Total Length:</label>&nbsp;
          <div>{this.props.length}</div>
        </Card>
      );
    }
    return null;
  }
}

MeasurementCard.propTypes = {
  active: PropTypes.bool,
  length: PropTypes.string,
  setMeasureActive: PropTypes.func
};

const mapStateToProps = state => {
  return {
    length: getMeasureToolLength(state),
    active: getMeasureToolActive(state)
  };
};

export default connect(mapStateToProps, {setMeasureActive})(MeasurementCard);
