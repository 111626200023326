import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NotificationDrawingTools from './notification-drawing-tools';
import ConfirmationPage from './confirmation-page';
import PausedPage from './paused-page';
import {
  getNotificationsVisible,
  getNotificationsDrawingShape,
  getNotificationsShapeError,
  getNotificationsStep,
  getNotificationsDataTypes,
  getNotificationsFrequency,
  getNotificationsEmail,
  getNotificationsEmailError,
  getNotificationsName,
  getNotificationsSuccess,
  getNotificationsPaused
} from '../../selectors/notifications';
import {
  closeNotifications,
  setNotificationDrawingShape,
  setNotificationStep,
  setNotificationDataTypes,
  setNotificationFrequency,
  setNotificationEmail,
  setNotificationName,
  saveNotification,
  resetNotificationForm
} from '../../actions/notification-actions';

import classNames from 'classnames';
import styles from './notifications.scss';

class Notifications extends Component {
  state = { emailTimeout: null, email: null, emailValidated: false };

  componentDidUpdate(prevProps) {
    if (prevProps.email && this.props.email === null) {
      this.setState({ email: null }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  nextStep = () => this.props.setNotificationStep(this.props.step + 1);
  prevStep = () => this.props.setNotificationStep(this.props.step - 1);
  setFrequency = event => this.props.setNotificationFrequency(event.target.value);
  setName = event => this.props.setNotificationName(event.target.value);

  save = () => {
    const { shape, dataTypes, frequency, email, name } = this.props;
    this.props.saveNotification(shape, dataTypes, frequency, email, name);
  };

  setEmail = event => {
    const email = event.target.value;
    if (this.state.emailTimeout) {
      clearTimeout(this.state.emailTimeout);
    }
    this.setState({
      email,
      emailValidated: false,
      emailTimeout: setTimeout(() => {
        this.setState({ emailValidated: true });
        this.props.setNotificationEmail(email);
      }, 500)
    });
  };

  setType = event => {
    const { value, checked } = event.target;
    const { dataTypes } = this.props;
    let types = [...dataTypes];
    if (checked && dataTypes.indexOf(value) < 0) {
      types.push(value);
    } else if (!checked && dataTypes.indexOf(value) > -1) {
      types = dataTypes.filter(dataType => dataType !== value);
    }
    this.props.setNotificationDataTypes(types);
  };

  checkStepComplete = () => {
    const { step, shape, dataTypes, frequency, email, emailError, shapeError } = this.props;
    if (step >= 1 && (!shape || shapeError)) {
      return false;
    }
    if (step >= 2 && dataTypes.length === 0) {
      return false;
    }
    if (step >= 3 && (frequency === null || email === null || emailError)) {
      return false;
    }
    return true;
  };

  clearShape = () => {
    this.props.setNotificationDrawingShape(null);
  };

  getDataTypesList = () => {
    const [...dataTypes] = this.props.dataTypes;
    const last = dataTypes.pop();
    if (dataTypes.length) {
      let types = [];
      if (dataTypes.length > 1) {
        types = dataTypes.map(dataType => {
          return <div key={dataType}><span>{dataType}</span>,&nbsp;</div>;
        });
      } else {
        types.push(<div key={dataTypes[0]}><span>{dataTypes[0]}</span>&nbsp;</div>);
      }
      types.push(<div key={last}>and&nbsp;<span>{last}</span></div>);
      return types;
    }
    return <div key={last}><span>{last}</span></div>;
  };

  render() {
    if (!this.props.visible) {
      return null;
    }
    const disabled = !this.checkStepComplete();
    const { step, dataTypes, frequency, email, name, shape, success, emailError, shapeError, paused } = this.props;
    const frequencyOptions = { 1: 'Daily', 2: 'Weekly', 3: 'Monthly' };
    if (paused) {
      return <PausedPage />;
    } else if (!success) {
      return (
        <div className={styles.container}>
          <div className={styles.header}>
            <div>Receive Activity Updates</div>
            <div>
              <Icon translate="no" onClick={this.props.closeNotifications}>close</Icon>
            </div>
          </div>
          <div className={styles.content}>
            {step === 1 &&
              <Fragment>
                {!shapeError &&
                  <div>Draw an enclosed area on the map where you want to get notified of new activities.</div>
                }
                {shapeError &&
                  <div className={styles.errorNotification}>
                    <Icon translate="no" className={styles.errorIcon}>error</Icon>
                    {shapeError.area &&
                      <div>
                        The area you drew is too large (<span className={styles.data}>{shapeError.area}</span>).<br />
                        Please draw an area smaller than <span className={styles.data}>100 acre</span>.
                      </div>
                    }
                  </div>
                }
              </Fragment>
            }
            {step === 2 &&
              <div>
                <div>Notify me about:</div>
                <div className={styles.typeContainer}>
                  <div className={styles.typeInnerContainer}>
                    <div className={styles.notificationType}>
                      <Checkbox
                        classes={{ root: styles.typeCheck }}
                        onChange={event => this.setType(event)}
                        value={'road closures'}
                        checked={dataTypes.indexOf('road closures') > -1}
                        style={{ color: dataTypes.indexOf('road closures') > -1 ? '#4285f4' : '' }} />
                      <div>Road closures</div>
                    </div>
                    <div className={styles.notificationType}>
                      <Checkbox
                        classes={{ root: styles.typeCheck }}
                        onChange={event => this.setType(event)}
                        value={'permits'}
                        checked={dataTypes.indexOf('permits') > -1}
                        style={{ color: dataTypes.indexOf('permits') > -1 ? '#4285f4' : '' }} />
                      <div>Permits</div>
                    </div>
                  </div>
                  <div className={styles.typeInnerContainer}>
                    <div className={styles.notificationType}>
                      <Checkbox
                        classes={{ root: styles.typeCheck }}
                        onChange={event => this.setType(event)}
                        value={'events'}
                        checked={dataTypes.indexOf('events') > -1}
                        style={{ color: dataTypes.indexOf('events') > -1 ? '#4285f4' : '' }} />
                      <div>Events</div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {step === 3 &&
              <div className={styles.userInfo}>
                <div className={styles.frequency}>
                  <label>Email me</label>
                  <FormControl fullWidth className={styles.selectWrap}>
                    <Select
                      onChange={event => this.setFrequency(event)}
                      value={frequency}
                      inputProps={{
                      }}
                      classes={{ root: styles.select, select: styles.selectInput }}
                      MenuProps={{ keepMounted: true }}
                    >
                      <MenuItem key={1} value={1}>Daily</MenuItem>
                      <MenuItem key={2} value={2}>Weekly</MenuItem>
                      <MenuItem key={3} value={3}>Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.email}>
                  <label>At</label>
                  <Input
                    classes={{ root: styles.input }}
                    onChange={event => this.setEmail(event)}
                    error={emailError}
                    value={this.state.email}
                    placeholder="example@domain.com"
                    aria-describedby="component-error-text" />
                </div>
                {emailError &&
                  <FormHelperText className={styles.error} id="email-error-text">Enter a valid email</FormHelperText>
                }
              </div>
            }
            {step === 4 &&
              <div className={styles.name}>
                <label>Name this area <span>(Optional)</span></label>
                <Input
                  classes={{ root: styles.input }}
                  onChange={event => this.setName(event)}
                  value={name}
                  placeholder="Ex. Near home, near work..." />
              </div>
            }
            {step === 5 &&
              <div className={styles.summary}>
                <div>In summary, you&apos;ll receive</div>
                <ul>
                  <li><div>
                    <span>{frequencyOptions[frequency]}</span>&nbsp;notifications
                  </div></li>
                  <li><div>About&nbsp;{this.getDataTypesList()}&nbsp;in this area
                    {name && <div>&nbsp;(<span>{name}</span>)</div>}</div></li>
                  <li><div>At&nbsp;<span>{email}</span></div></li>
                </ul>
              </div>
            }
          </div>
          <div className={styles.footer}>
            <div className={styles.steps}>
              Step <span>{step}</span> of <span>5</span>
            </div>
            <div>
              {step === 1 && shape &&
                <Button onClick={this.clearShape}>Clear</Button>
              }
              {step > 1 && <Button onClick={this.prevStep}>Back</Button>}
              {step < 5 &&
                <Button
                  classes={{ disabled: styles.disabled }}
                  disabled={disabled}
                  onClick={this.nextStep}
                  style={{ color: !disabled ? '#4285f4' : '' }}>
                  Next
                </Button>
              }
              {step === 5 &&
                <Button
                  className={styles.save}
                  onClick={this.save}
                  style={{ color: '#4285f4' }}
                >
                  Save
                </Button>
              }
            </div>
          </div>
          <div
            className={classNames(styles.progressBar, { [styles.complete]: step >= 5 })}
            style={{ width: `${Math.min((20 * step), 100)}%` }} />
          <NotificationDrawingTools mapRef={this.props.mapRef} />
        </div>
      );
    }
    return <ConfirmationPage />;
  }
}

Notifications.propTypes = {
  closeNotifications: PropTypes.func,
  dataTypes: PropTypes.array,
  email: PropTypes.string,
  emailError: PropTypes.bool,
  frequency: PropTypes.number,
  mapRef: PropTypes.object,
  name: PropTypes.string,
  paused: PropTypes.bool,
  resetNotificationForm: PropTypes.func,
  saveNotification: PropTypes.func,
  setNotificationDataTypes: PropTypes.func,
  setNotificationDrawingShape: PropTypes.func,
  setNotificationEmail: PropTypes.func,
  setNotificationFrequency: PropTypes.func,
  setNotificationName: PropTypes.func,
  setNotificationStep: PropTypes.func,
  shape: PropTypes.array,
  shapeError: PropTypes.object,
  step: PropTypes.number,
  success: PropTypes.bool,
  visible: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    visible: getNotificationsVisible(state),
    shape: getNotificationsDrawingShape(state),
    shapeError: getNotificationsShapeError(state),
    step: getNotificationsStep(state),
    dataTypes: getNotificationsDataTypes(state),
    frequency: getNotificationsFrequency(state),
    email: getNotificationsEmail(state),
    emailError: getNotificationsEmailError(state),
    name: getNotificationsName(state),
    success: getNotificationsSuccess(state),
    paused: getNotificationsPaused(state)
  };
};

export default connect(mapStateToProps, {
  closeNotifications,
  setNotificationDrawingShape,
  setNotificationStep,
  setNotificationDataTypes,
  setNotificationFrequency,
  setNotificationEmail,
  setNotificationName,
  saveNotification,
  resetNotificationForm
})(Notifications);
