import { createSelector } from 'reselect';

import { getMapIcon } from '../utilities/map-utilities';

import { getFilters, getFiltersFlatList, getFiltersFlat, getSelectedDateRange } from './filters';

const getData = state => state.map.data;
const getHighlight = state => state.map.highlight || {};
export const getViewport = state => state.map.viewport;
export const getStreetView = state => state.map.streetView;
export const getMapTypeId = state => state.map.mapTypeId;
export const getTraffic = state => state.map.traffic;
export const getDrawingMode = state => state.map.drawing.mode;
export const getDrawingArea = state => state.map.drawing.area;
export const getMeasureToolActive = state => state.map.measureTool.active;
export const getMeasureToolLength = state => state.map.measureTool.length;
export const getUserLocation = state => state.map.userLocation;
export const getDevSecret = state => state.map.devSecret;
export const getRequiresDevSecret = state => state.map.requiresDevSecret;
export const getError404Visible = state => state.map.error404Visible;


export const getDevSecretParams = createSelector(
  [getDevSecret, getRequiresDevSecret],
  (secret, required) => required ? { secret } : {}
);

export const getZoom = createSelector(
  [getViewport],
  viewport => viewport.zoom
);

export const getBbox = createSelector(
  [getViewport],
  viewport => viewport.bbox
);

const annotateStyleAndFilterData = (mapData, filtersFlat, dateRange) => {
  const annotatedData = {};
  Object.values(mapData).forEach(layerData => {
    const { requestUrl, mapStyle, uiStyle, ...filter } = filtersFlat[layerData.id];
    annotatedData[layerData.id] = {
      ...layerData,
      ...filter,
      url: requestUrl,
      mapStyle: { ...mapStyle, clickable: false }, // with radius select, we don't need the map items to be clickable
      uiStyle,
      mapIcon: getMapIcon(uiStyle.marker),
      activeMapIcon: getMapIcon(uiStyle.activeMarker, true),
      list: layerData.list.filter(item => !item.date_ranges || item.date_ranges[dateRange.id])
    };
  });
  return annotatedData;
};

const annotateVisibilityForTree = (mapData, filterTree) => {
  let annotatedData = {};
  filterTree.forEach(filter => {
    const data = mapData[filter.id];
    if (data.checked) {
      annotatedData[filter.id] = { ...data, visible: true };
      if (filter.children) {
        annotatedData = { ...annotatedData, ...annotateVisibilityForTree(mapData, filter.children) };
      }
    }
  });
  return annotatedData;
};

export const getMapData = createSelector(
  [getData, getSelectedDateRange, getFilters, getFiltersFlat],
  (mapData, dateRange, filters, filtersFlat) => {
    if (mapData && filters && filtersFlat) {
      const styledData = annotateStyleAndFilterData(mapData, filtersFlat, dateRange);
      return { ...styledData, ...annotateVisibilityForTree(styledData, filters) };
    }
    return {};
  }
);

export const getVisibleMapDataList = createSelector(
  [getMapData, getFiltersFlatList],
  (mapData, filtersList) => {
    const sortedMapData = filtersList.map(({ id }) => mapData[id]);
    return sortedMapData.filter(data => data.visible);
  }
);

export const getHighlightedElements = createSelector(
  [getHighlight],
  highlights => {
    const layers = {};
    Object.values(highlights).forEach(({ layerId, itemId }) => {
      if (!layers[layerId]) {
        layers[layerId] = {};
      }
      layers[layerId][itemId] = true;
    });
    return layers;
  }
);

export const getLoading = createSelector(
  [getVisibleMapDataList, getStreetView],
  (mapData, streetView) => {
    let loading = false;
    mapData.forEach(dataType => {
      if (dataType.checked && dataType.url && dataType.loading && !streetView.visible) {
        loading = true;
      }
    });
    return loading;
  }
);

