/* eslint-disable  */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Marker } from 'react-google-maps';
import DotMapsOverlayView from '../../../../shared/dotmaps-google-map/dotmaps-overlay-view';

import styles from './point.scss';

class Point extends PureComponent {
  render = props => {
    const { highlighted, ...passThroughProps } = this.props;
    if (highlighted) {
      const { icon, ...markerProps} = passThroughProps;
      return (
        <DotMapsOverlayView
          mapPaneName={'floatPane'}
          {...markerProps}
          style= {{
            msTransform: `translateX(-${icon.anchor.x}px) translateY(-${icon.anchor.y}px)`,
            transform: `translateX(-${icon.anchor.x}px) translateY(-${icon.anchor.y}px)`
          }}
          className={styles.selectedPoint}
        >
          <img src={icon.url} />
        </DotMapsOverlayView>
      )
    }
    return (
      <Marker {...passThroughProps} />
    )
  }
}

Point.propTypes = {
  highlighted: PropTypes.bool
};

export default Point;
