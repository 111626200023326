import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';

import LargeMedia from '../../../shared/media/large';
import MobileCarousel from '../mobile-carousel';
import SmallMedia from '../../../shared/media/small';

import { closeLegend } from '../../../../actions/ui-actions';

import styles from './sub-legend.scss';

class SubLegend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover: null
    };

    this.handleInfoClick = this.handleInfoClick.bind(this);
    this.setPopoverElement = this.setPopoverElement.bind(this);
    this.itemsAsLayers = this.itemsAsLayers.bind(this);
  }

  handleInfoClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setPopoverElement(event.currentTarget);
  };

  setPopoverElement = (element) => {
    this.setState({ popover: element });
  };

  itemsAsLayers = (items) => items.map(([label, icon], index) => ({
    id: index,
    uiStyle: {
      showInLegend: true,
      legendIcon: icon,
      legendLabel: label
    }
  }));

  render() {
    const splitItems = [[], []];
    const { label, items, closeLegend: closeLegendFunction, ...passThroughProps } = this.props;
    if (items) {
      let [...remaining] = items;
      while (remaining && remaining.length > 0) {
        const [even, odd, ...rest] = remaining;
        remaining = rest;
        splitItems[0].push(even);
        splitItems[1].push(odd);
      }
      if (!splitItems[1]) {
        splitItems[1].pop();
      }
    }

    return (
      <Fragment>
        <Icon translate="no"
          {...passThroughProps}
          onClick={this.handleInfoClick}
          classes={{ root: styles.subLegendIcon }}
        >
          help
        </Icon>
        <LargeMedia>
          <Popover
            open={Boolean(this.state.popover)}
            anchorEl={this.state.popover}
            onClose={() => this.setPopoverElement(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <div className={styles.mapLegend} >
              <div className={styles.title}>{label}</div>
              <div className={styles.legend}>
                {splitItems.map((listItems, listIndex) => {
                  return (
                    <List key={listIndex} classes={{ root: styles.legendColumn }}>
                      {listItems.map(([label_, icon], index) => {
                        return (
                          <ListItem key={index} classes={{ root: styles.legendItem }}>
                            <ListItemIcon>
                              {icon &&
                                <img src={icon} className={styles.legendIcon} alt=""/>
                              }
                            </ListItemIcon>
                            <ListItemText
                              classes={{ root: styles.legendText }}
                              primary={label_}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  );
                })}
              </div>
            </div>
          </Popover>
        </LargeMedia>
        <SmallMedia>
          <Drawer
            open={Boolean(this.state.popover)}
            anchor="bottom"
            onClose={() => this.setPopoverElement(null)}
            ModalProps={{
              classes: { root: styles.subModal },
              hideBackdrop: true,
              disableBackdropClick: true
            }}
            classes={{ paper: styles.subDrawer }}
          >
            <div className={styles.slideHeader}>
              <IconButton
                className={styles.slideBackButton}
                onClick={() => this.setPopoverElement(null)}
              >
                <Icon translate="no">arrow_back</Icon>
              </IconButton>
              <div className={styles.slideTitle}>
                {label}
              </div>
              <IconButton
                className={styles.slideCloseButton}
                onClick={closeLegendFunction}
              >
                <Icon translate="no">close</Icon>
              </IconButton>
            </div>
            <MobileCarousel layers={this.itemsAsLayers(items)} />
          </Drawer>
        </SmallMedia>
      </Fragment>
    );
  }
}

SubLegend.propTypes = {
  closeLegend: PropTypes.func,
  items: PropTypes.array,
  label: PropTypes.string
};

export default connect(null, { closeLegend })(SubLegend);
