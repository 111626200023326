import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'; 

import { getSelectionAreaWithBbox } from '../../../../selectors/map-selection';

import { fetchJsonMapData } from '../../../../actions/map-actions';

import { renderUrlParam } from '../../../../utilities/url-utilities';

const DEFAULT_WFS_PARAMS = {
  service: 'WFS', //WMS service
  version: '2.0.0', //WMS version
  request: 'GetFeature', //WMS operation
  srsName: 'EPSG:4326',
  outputFormat: 'application/json'
};

class WfsLoader extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  loadData = () => {
    const { layerId, url, params, area } = this.props;
    if (area && area.bbox) {
      const textParams = [
        ...Object.entries({...DEFAULT_WFS_PARAMS, ...params}).map(([key, value]) => renderUrlParam(key, value)),
        `bbox=${[...area.bbox, 'EPSG:4326']}`  //set bounding box for tile
      ];
      const request = `${url}?${textParams.join('&')}`;  //return WMS URL for the tile
      this.props.fetchJsonMapData(layerId, request, true);
    }
  };

  render() {
    return null;
  }
}

WfsLoader.propTypes = {
  area: PropTypes.object,
  fetchJsonMapData: PropTypes.func,
  layerId: PropTypes.number,
  params: PropTypes.shape({
    typeNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
  }).isRequired,
  url: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    area: getSelectionAreaWithBbox(state)
  };
};

export default connect(mapStateToProps, { fetchJsonMapData })(WfsLoader);
