/* eslint-disable import/no-named-as-default */
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getError404Visible } from '../selectors/map';
import Map from './map';
import Error404 from './error-404';
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  render() {
    if (this.props.error404Visible) {
      return <Error404 />;
    }
    return (
      <div>
        <Switch>
          <Redirect exact from="/" to="/map" />
          <Route path="/map" component={Map} />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  error404Visible: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    error404Visible: getError404Visible(state)
  };
};

export default hot(module)(withRouter(connect(mapStateToProps, {})(App)));
