import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';

import LargeMedia from '../../shared/media/large';
import SmallMedia from '../../shared/media/small';

import styles from './info-popup.scss';


const stopPropagation = (event) => {
  event.stopPropagation();
};

class InfoPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popover: null
    };
  
    this.handleInfoClick = this.handleInfoClick.bind(this);
    this.setPopoverElement = this.setPopoverElement.bind(this);
  }

  handleInfoClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setPopoverElement(event.currentTarget);
  };

  setPopoverElement = (element) => {
    this.setState({popover: element});
  };

  render() {
    const { label, children, ...passThroughProps } = this.props;
    return (
      <Fragment>
        <Icon translate="no" onClick={this.handleInfoClick} {...passThroughProps}> help </Icon>
        <span onClick={stopPropagation} >
          <LargeMedia>
            <Popover
              open={Boolean(this.state.popover)}
              anchorEl={this.state.popover}
              onClose={() => this.setPopoverElement(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              keepMounted
            >
              <div className={styles.tooltipLabel}>{label}</div>
              <div className={styles.tooltipText}>{children}</div>
            </Popover>
          </LargeMedia>
          <SmallMedia>
            <Drawer
              open={Boolean(this.state.popover)}
              anchor="bottom"
              onClose={() => this.setPopoverElement(null)}
              keepMounted
            >
              <div className={styles.tooltipLabel}>{label}</div>
              <div className={styles.tooltipText}>{children}</div>
            </Drawer>
          </SmallMedia>
        </span>
      </Fragment>
    );
  }
}

InfoPopup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string
};

export default InfoPopup;
