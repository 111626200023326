import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Drawer from '@material-ui/core/Drawer';
import FilterTray from '../../filter-tray';
import InfoTray from '../info-tray';
import MobileMapLegend from '../map-legend/mobile';
import SmallMedia from '../../shared/media/small';
import LargeMedia from '../../shared/media/large';

import { getStreetView, getDevSecret, getRequiresDevSecret } from '../../../selectors/map';
import { getSearchFocused } from '../../../selectors/search';
import { getSelectionElements } from '../../../selectors/map-selection';
import { getLegend, getlayerFilters, getMobileMaxDetails } from '../../../selectors/ui';
import { getNotificationsVisible } from '../../../selectors/notifications';

import { closeLegend, closeMobileMaxDetails } from '../../../actions/ui-actions';

import styles from './map-trays.scss';

class MapTrays extends Component {
  render() {
    const { mapRef, searchFocused, streetView, devSecret, requiresDevSecret } = this.props;
    if (this.props.notificationsVisible) {
      return null;
    }
    return (
      <Fragment>
        {(!requiresDevSecret || devSecret) &&
          <Fragment>
            <SmallMedia>
              {!searchFocused &&
                <FilterTray />
              }
            </SmallMedia>
            <LargeMedia>
              <FilterTray defaultOpen />
            </LargeMedia>
          </Fragment>
        }
        <SmallMedia>
          {!searchFocused && !streetView.visible && !this.props.notificationsVisible &&
            <div className={styles.mapDrawers}>
              <Drawer
                variant="persistent"
                anchor="bottom"
                open={this.props.legend.visible}
                onClose={this.props.closeLegend}
              >
                <MobileMapLegend />
              </Drawer>
              {this.props.mapSelection &&
                <Drawer
                  variant="permanent"
                  anchor="bottom"
                  open={this.props.mobileMaxDetails.visible}
                  onClose={this.props.closeMobileMaxDetails}
                  classes={{
                    paper: classNames(
                      styles.mobileDetailDrawer,
                      { [styles.mobileFullDetailDrawer]: this.props.mobileMaxDetails.visible }
                    )
                  }}
                >
                  {this.props.mapSelection &&
                    <InfoTray mapRef={mapRef} />
                  }
                </Drawer>
              }
            </div>
          }
        </SmallMedia>
      </Fragment>
    );
  }
}

MapTrays.propTypes = {
  closeLegend: PropTypes.func,
  closeMobileMaxDetails: PropTypes.func,
  devSecret: PropTypes.string,
  drawingVisible: PropTypes.bool,
  layerFilters: PropTypes.object,
  legend: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  mapSelection: PropTypes.array,
  mobileMaxDetails: PropTypes.object,
  notificationsVisible: PropTypes.bool,
  requiresDevSecret: PropTypes.bool,
  searchFocused: PropTypes.bool,
  streetView: PropTypes.object
};

const mapStateToProps = state => {
  return {
    legend: getLegend(state),
    mapSelection: getSelectionElements(state),
    layerFilters: getlayerFilters(state),
    mobileMaxDetails: getMobileMaxDetails(state),
    searchFocused: getSearchFocused(state),
    streetView: getStreetView(state),
    devSecret: getDevSecret(state),
    requiresDevSecret: getRequiresDevSecret(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, { closeLegend, closeMobileMaxDetails })(MapTrays);
