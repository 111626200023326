import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import LargeMedia from '../../../shared/media/large';
import SmallMedia from '../../../shared/media/small';
import StaticStreetView from '../../../shared/static-street-view';
import DetailText from './detail-text';

import { apiKey } from '../../../../constants/google-maps';

import DetailHeader from './detail-header';

import { getMobileMaxDetails } from '../../../../selectors/ui';
import { getSelectionElementsCount } from '../../../../selectors/map-selection';

import {
  setHighlight,
  clearHighlight,
  clearAreaSelection,
  clearItemSelection,
  setLinkedElementInactive
} from '../../../../actions/map-actions';
import { closeMobileMaxDetails } from '../../../../actions/ui-actions';

import { resolveFields, getNoTranslate, getNoTranslateLabel } from '../../../../utilities/map-data-utilities';
import { getBboxCenter } from '../../../../utilities/geometry-utilities';

import styles from './info-detail.scss';

class InfoDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { hrefValidated: null };
    this.closeDetails = this.closeDetails.bind(this);
    this.closeMobileDetails = this.closeMobileDetails.bind(this);
    this.openStreetView = this.openStreetView.bind(this);
  }

  closeDetails() {
    this.props.clearAreaSelection();
    this.props.clearItemSelection();
    this.props.setLinkedElementInactive();
  }

  closeMobileDetails() {
    this.props.closeMobileMaxDetails();
  }

  openStreetView() {
    const { mapRef, detailData: { data } } = this.props;
    if (data.center) {
      const panorama = mapRef.getStreetView();
      const { lng, lat } = data.center;
      panorama.setPosition({ lng, lat });
      panorama.setVisible(true);
    }
  }

  componentDidMount() {
    const { detailData: { data, layerData } } = this.props;
    this.props.setHighlight('detail', layerData.id, data.id);
  }

  componentWillUnmount() {
    this.props.clearHighlight('detail');
  }

  componentDidUpdate() {
    const { detailData: { data, layerData } } = this.props;
    this.props.setHighlight('detail', layerData.id, data.id);
  }

  render() {
    const { detailData, mapRef } = this.props;
    const { data, layerData } = detailData;
    const { lng, lat } = data.center || getBboxCenter(data.bbox).geometry;
    const detailAttributes = data.attrs;
    const detailAliases = layerData.aliases.filter(([, label]) => label !== 'id' && label !== 'title');
    const valueMap = layerData.uiStyle.valueMap || null;
    const streetView = layerData.uiStyle.marker;
    return (
      <div className={styles.infoDetailContainer}>
        <LargeMedia>
          {this.props.areaCount > 1 &&
            <IconButton
              className={styles.backButton}
              onClick={this.props.clearItemSelection}
            >
              <Icon translate="no">arrow_back</Icon>
            </IconButton>
          }
          <IconButton
            className={styles.closeButton}
            onClick={this.closeDetails}
          >
            <Icon translate="no">close</Icon>
          </IconButton>
        </LargeMedia>
        <SmallMedia>
          {this.props.mobileMaxDetails.visible &&
            <IconButton
              className={
                classNames(
                  styles.mobileCollapseButton,
                  {
                    [styles.mobileCollapseButtonNonStreetView]: !streetView
                  }
                )
              }
              onClick={this.closeMobileDetails}
            >
              <Icon translate="no">arrow_back</Icon>
            </IconButton>
          }
        </SmallMedia>
        {streetView &&
          <div className={styles.streetView} onClick={this.openStreetView} >
            <StaticStreetView
              className={styles.streetViewInner}
              lng={lng}
              lat={lat}
              apiKey={apiKey}
            />
            <div className={styles.streetViewOverlay} />
            <Icon translate="no" classes={{ root: styles.streetViewIcon }} >360</Icon>
          </div>
        }
        {!streetView &&
          <div className={styles.streetViewPlaceHolder} />
        }
        <DetailHeader detailData={detailData} mapRef={mapRef} />
        <div className={styles.details} >
          {detailAliases && detailAliases.map(([fields, label], index) => {
            const value = resolveFields(fields, detailAttributes, valueMap);
            const notranslate = getNoTranslate(label, layerData);
            const nostranslateLabel = getNoTranslateLabel(label, layerData);
            return (
              <DetailText
                key={index}
                label={label}
                value={value}
                notranslateLabel={nostranslateLabel}
                notranslateValue={notranslate}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

InfoDetail.propTypes = {
  areaCount: PropTypes.number,
  clearAreaSelection: PropTypes.func,
  clearHighlight: PropTypes.func,
  clearItemSelection: PropTypes.func,
  closeMobileMaxDetails: PropTypes.func,
  detailData: PropTypes.object.isRequired,
  mapRef: PropTypes.object,
  mapsShape: PropTypes.object,
  mobileMaxDetails: PropTypes.object,
  setHighlight: PropTypes.func,
  setLinkedElementInactive: PropTypes.func
};

const mapStateToProps = state => {
  return {
    areaCount: getSelectionElementsCount(state),
    mobileMaxDetails: getMobileMaxDetails(state)
  };
};

export default connect(
  mapStateToProps,
  { setHighlight, clearHighlight, clearAreaSelection, clearItemSelection, closeMobileMaxDetails, setLinkedElementInactive }
)(InfoDetail);
