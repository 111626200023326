import React from 'react';
import PropTypes from 'prop-types';

import { MAP } from 'react-google-maps/lib/constants';

class OverlayMapType extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      overlayMapType: null
    };
  }
  
  componentDidMount() {
    const { overlayMapType } = this.props;
    this.context[MAP].overlayMapTypes.insertAt(0, overlayMapType);
    this.setState({overlayMapType});
  }

  componentWillUnmount() {
    const { overlayMapType } = this.state;
    const index = this.context[MAP].overlayMapTypes.indexOf(overlayMapType);
    if (index >= 0) {
      this.context[MAP].overlayMapTypes.removeAt(index);
    }
  }

  render() {
    return false;
  }
}

OverlayMapType.contextTypes = {
  [MAP]: PropTypes.object
};

OverlayMapType.propTypes = {
  overlayMapType: PropTypes.object
};

export default OverlayMapType;
