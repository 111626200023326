/* global google */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MAP } from 'react-google-maps/lib/constants';

import DotMapsOverlayView from '../../../shared/dotmaps-google-map/dotmaps-overlay-view';

import { PROXIMITY_CLUSTER_MAX_ZOOM } from '../../../../constants/google-maps';

import {selectArea} from '../../../../actions/map-actions';
import { remToPx } from '../../../../utilities/dom-utilities';
import { googleMapsBoundsToBbox, bboxToGoogleMapsBounds } from '../../../../utilities/geometry-utilities';


import styles from './overlap-count-marker.scss';

class OverlapCountMarker extends Component {
  constructor(props) {
    super(props);
    this.markerClick = this.markerClick.bind(this);
  }

  markerClick() {
    const { cluster, mapRef } = this.props;
    if (cluster.overlaps.length > 1) {
      const mapBbox = googleMapsBoundsToBbox(mapRef.getBounds());
      const mapWidth = mapBbox[2] - mapBbox[0];
      const mapHeight = mapBbox[3] - mapBbox[1];
      const clusterWidth = cluster.bbox[2] - cluster.bbox[0];
      const clusterHeight = cluster.bbox[3] - cluster.bbox[1];
      // Compare relative sizes of bboxes to ensure google maps uses an
      // eased transition instead of snapping to position
      if (clusterWidth < (0.2 * mapWidth) && clusterHeight < (0.2 * mapHeight) && mapRef.getZoom() < 21) {
        const intermediateBbox = [
          (mapBbox[0] * 0.2 + cluster.bbox[0] * 0.8),
          (mapBbox[1] * 0.2 + cluster.bbox[1] * 0.8),
          (mapBbox[2] * 0.2 + cluster.bbox[2] * 0.8),
          (mapBbox[3] * 0.2 + cluster.bbox[3] * 0.8)
        ];
        mapRef.fitBounds(bboxToGoogleMapsBounds(intermediateBbox));
        setTimeout(() => this.markerClick(), 250);
      } else {
        if (clusterWidth > (0.4 * mapWidth) || clusterHeight > (0.4 * mapHeight)) {
          const currentZoom = mapRef.getZoom();
          if (currentZoom <= PROXIMITY_CLUSTER_MAX_ZOOM) {
            mapRef.context[MAP].setZoom(currentZoom + 1);
          }
        } else {
          mapRef.fitBounds(bboxToGoogleMapsBounds(cluster.bbox));
        }
      }
    } else {
      this.props.selectArea(
        new google.maps.LatLng(cluster.marker.lat, cluster.marker.lng),
        mapRef,
        remToPx(1.25)
      );
    }
  }

  render() {
    const { selectionCount, cluster: {center, marker, count, classes, overlaps }} = this.props;
    const isMax = classes.indexOf('max') >= 0;
    const isCluster = overlaps.length > 1;
    const selected = selectionCount > 1;
    const displayCount = selected && !isCluster ? selectionCount : count;
    return (
      <DotMapsOverlayView
        mapPaneName={'floatPane'}
        position={isCluster ? center : marker}
        zIndex={(selected ? 20000 : 20) + Math.min(displayCount, 10000)}
        className={classNames(styles.overlay, {[styles.overlapSelected]: !isCluster && selected})}
      >
        {!isCluster && 
          <div className={classNames(...classes, styles.markerOverlap, {[styles.selected]: selected}, styles.stackedOverlap)}/>
        }
        <div onClick={this.markerClick}
          className={classNames(...classes, styles.markerOverlap, {[styles.selected]: selected})}
          role="presentation"
        >
          <div className={styles.count}>
            {isMax ? `${(displayCount / 1000).toFixed()}K` : displayCount}
          </div>
        </div>
      </DotMapsOverlayView>
    );
  }
}

OverlapCountMarker.propTypes = {
  cluster: PropTypes.object,
  mapRef: PropTypes.object,
  prefix: PropTypes.string,
  selectArea: PropTypes.func,
  selectionCount: PropTypes.number
};

export default connect(null, {selectArea})(OverlapCountMarker);
