// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-map-components-___map-components__legend-button{width:2.375rem;min-width:2.375rem;height:2.375rem;padding:.25em;background-color:#fff;border-radius:3px;cursor:pointer;color:rgba(0,0,0,0.4)}.src-components-map-map-components-___map-components__legend-button span{font-size:1.25em}@media only screen and (min-device-width: 768px){.src-components-map-map-components-___map-components__legend-button{margin:1.25rem 0.5rem 0 0;box-shadow:0 2px 4px 1px rgba(183,183,183,0.5)}}.src-components-map-map-components-___map-components__legend-button:hover{background-color:#fff}.src-components-map-map-components-___map-components__active-legend-button{color:#4284F4}.src-components-map-map-components-___map-components__translate-container{margin:1.25rem 1.25rem 0 0}.src-components-map-map-components-___map-components__map-tools{overflow:visible;height:3.625rem;width:10rem;z-index:10}
`, "",{"version":3,"sources":["webpack://./src/components/map/map-components/map-components.scss"],"names":[],"mappings":"AAGA,oEACE,cAAe,CACf,kBAAmB,CACnB,eAAgB,CAChB,aAAc,CACd,qBAAsB,CACtB,iBAAkB,CAClB,cAAe,CACf,qBAAyB,CAR3B,yEAWI,gBAAiB,CAClB,iDAZH,oEAeI,yBAA0B,CAC1B,8CAAkD,CAErD,CACD,0EACE,qBAAsB,CACvB,2EAEC,aAAc,CACf,0EAGC,0BAA2B,CAC5B,gEAGC,gBAAiB,CACjB,eAAgB,CAChB,WAAY,CACZ,UAAW","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.legend-button {\n  width: 2.375rem;\n  min-width: 2.375rem;\n  height: 2.375rem;\n  padding: .25em;\n  background-color: #fff;\n  border-radius: 3px;\n  cursor: pointer;\n  color: rgba(0, 0, 0, 0.4);\n\n  span {\n    font-size: 1.25em;\n  }\n\n  @media only screen and (min-device-width: $desktop-device-width) {\n    margin: 1.25rem 0.5rem 0 0;\n    box-shadow: 0 2px 4px 1px rgba(183, 183, 183, 0.5);\n  }\n}\n.legend-button:hover {\n  background-color: #fff;\n}\n.active-legend-button {\n  color: #4284F4;\n}\n\n.translate-container {\n  margin: 1.25rem 1.25rem 0 0;\n}\n\n.map-tools {\n  overflow: visible;\n  height: 3.625rem;\n  width: 10rem;\n  z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend-button": `src-components-map-map-components-___map-components__legend-button`,
	"legendButton": `src-components-map-map-components-___map-components__legend-button`,
	"active-legend-button": `src-components-map-map-components-___map-components__active-legend-button`,
	"activeLegendButton": `src-components-map-map-components-___map-components__active-legend-button`,
	"translate-container": `src-components-map-map-components-___map-components__translate-container`,
	"translateContainer": `src-components-map-map-components-___map-components__translate-container`,
	"map-tools": `src-components-map-map-components-___map-components__map-tools`,
	"mapTools": `src-components-map-map-components-___map-components__map-tools`
};
export default ___CSS_LOADER_EXPORT___;
