import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';

import DetailHeader from '../info-detail/detail-header';

import LargeMedia from '../../../shared/media/large';
import SmallMedia from '../../../shared/media/small';

import { getMapData } from '../../../../selectors/map';
import { getSelectedArea } from '../../../../selectors/map-selection';

import { getTitles } from '../../../../utilities/map-data-utilities';

import { clearAreaSelection, selectItem, setHighlight, clearHighlight } from '../../../../actions/map-actions';

import styles from './identify-card.scss';

class IdentifyCard extends Component {
  constructor(props) {
    super(props);
    this.dataClick = this.dataClick.bind(this);
    this.setHighlight = this.setHighlight.bind(this);
    this.clearHighlight = this.clearHighlight.bind(this);
  }

  componentWillUnmount() {
    this.clearHighlight();
  }

  dataClick() {
    const { index, selectedArea } = this.props;
    this.props.selectItem(selectedArea, index);
    if (this.props.onClick) {
      this.props.onClick(index);
    }
  }

  setHighlight() {
    const { layerData, data } = this.props.detailData;
    this.props.setHighlight('identify', layerData.id, data.id);
  }

  clearHighlight() {
    this.props.clearHighlight('identify');
  }

  render() {
    const { mapData, mapRef, detailData } = this.props;
    const { layerData } = detailData;

    const titles = getTitles(mapData, detailData);
    const title = titles.pop();
    const [firstSub, ...subTitles] = titles;

    const iconUrl = layerData.uiStyle.marker || layerData.uiStyle.legendIcon;
    return (
      <Fragment>
        <LargeMedia>
          <div
            className={styles.title}
            onMouseEnter={this.setHighlight}
            onMouseLeave={this.clearHighlight}
            onClick={this.dataClick}
          >
            {iconUrl &&
              <div className={styles.markerIcon}>
                <img src={iconUrl} alt="" />
              </div>
            }
            <div className={styles.arrow}>
              <Icon translate="no">chevron_right</Icon>
            </div>
            {firstSub &&
              <div className={styles.type} >
                <div {...firstSub.props}> {firstSub.value} </div>
                {subTitles.map(({ value, props }, index) =>
                  <Fragment key={index}><div className={styles.dot}>•</div><div {...props}>{value}</div></Fragment>
                )}
              </div>
            }
            <div className={styles.name} {...title.props}>{title.value}</div>
          </div>
        </LargeMedia>
        <SmallMedia>
          <DetailHeader detailData={detailData} mapRef={mapRef} onClick={this.dataClick} />
        </SmallMedia>
      </Fragment>
    );
  }
}

IdentifyCard.propTypes = {
  clearAreaSelection: PropTypes.func,
  clearHighlight: PropTypes.func,
  detailData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  mapData: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  selectItem: PropTypes.func,
  selectedArea: PropTypes.object,
  setHighlight: PropTypes.func
};

const mapStateToProps = state => {
  return {
    mapData: getMapData(state),
    selectedArea: getSelectedArea(state)
  };
};

export default connect(mapStateToProps, { clearAreaSelection, selectItem, setHighlight, clearHighlight })(IdentifyCard);
