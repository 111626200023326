import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LoadingPage from './loading-page';
import LoginPage from './dev-login';
import MapSearchAutocomplete from '../top-bar/map-search-autocomplete';
import DisclaimerText from '../disclaimer/disclaimer-body';
import TranslateButton from '../shared/translate-button';

import { getDevSecret, getRequiresDevSecret } from '../../selectors/map';

import cdotLogoWhite from '../../static/cdot-logo-white.png';

import styles from './landing-page.scss';

class LandingPage extends Component {

  render() {
    const { devSecret, requiresDevSecret } = this.props;
    const searchParams = window.location.search ? window.location.search.substr(1).split('=') : null;
    const params = new URLSearchParams(window.location.search);
    const showLogin = requiresDevSecret && !devSecret;
    return (
      <div className={styles.container}>
        {showLogin &&
          <LoginPage />
        }
        {searchParams && !showLogin &&
          <LoadingPage mapType={searchParams[0]} mapTypeValue={searchParams[1]} deepLink={params.get('link_type')} />
        }
        {!searchParams && !showLogin &&
          <div className={styles.translate}>
            <TranslateButton />
          </div>
        }
        <div className={styles.top}>
          <div className={styles.gradientOverlay} />
          <div className={styles.logo}>
            <a href="https://www.cityofchicago.org/city/en/depts/cdot.html" target="_blank" rel="noopener noreferrer">
              <img src={cdotLogoWhite} alt=""/>
            </a>
          </div>
          <div className={styles.contentContainer}>
            <label translate="no">ChiStreetWork</label>
            <div className={styles.search}>
              <MapSearchAutocomplete mapRef={this.props.mapRef} showMagnifier showMyLocation landing />
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.disclaimer}>
            <DisclaimerText />
          </div>
          <div className={styles.links}>
            <div className={styles.linkButton}>
              <a href="https://www.chicago.gov/city/en/general/privacy.html" target="_blank" rel="noopener noreferrer">
                <span className={styles.filterFooterText}>Privacy policy</span>
              </a>
            </div>
            <div className={styles.filterFooterDivider}>|</div>
            <div className={styles.linkButton}>
              <a href="https://goo.gl/forms/Vq5BQt8vHnFmjdDI2" target="_blank" rel="noopener noreferrer">
                <span className={styles.filterFooterText}>Send feedback</span>
              </a>
            </div>
          </div>
          <div className={styles.poweredBy}>
            {'Powered by '}
            <a translate="no" href="https://www.cityofchicago.org/city/en/depts/doit.html" target="_blank" rel="noopener noreferrer">DoIT</a>
            {', '}
            <a translate="no" href="https://www.cityofchicago.org/city/en/depts/cdot.html" target="_blank" rel="noopener noreferrer">CDOT</a>
            {', '}
            <a translate="no" href="https://www.collinsengr.com/" target="_blank" rel="noopener noreferrer">Collins Engineers</a>
            {', '}
            <a translate="no" href="https://sadasystems.com/" target="_blank" rel="noopener noreferrer">SADA Systems</a>
            {', '}
            <a translate="no" href="https://www.google.com/maps/about/" target="_blank" rel="noopener noreferrer">Google Maps</a>
          </div>
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  devSecret: PropTypes.string,
  mapRef: PropTypes.object,
  requiresDevSecret: PropTypes.bool
};

const mapStateToProps = state => ({
  devSecret: getDevSecret(state),
  requiresDevSecret: getRequiresDevSecret(state)
});

export default connect(mapStateToProps, {})(LandingPage);
