import { createSelector, createSelectorCreator, weakMapMemoize } from 'reselect';
import { getViewportData } from './clusters';


import {isEqual} from 'lodash';

export const getLabels = state => state.map.labelGrid;

const createDeepEqualSelector = createSelectorCreator(
  weakMapMemoize,
  isEqual
);

const getLabeledPolygonDataA = createSelector(
  [getViewportData],
  (viewportData) => Object.values(viewportData).filter(data => data.uiStyle && data.uiStyle.polygonLabel)
);

export const getLabeledPolygonData = createDeepEqualSelector(
  [getLabeledPolygonDataA],
  data => (data)
);
