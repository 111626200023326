import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';

import InfoPopup from '../info-popup';
import FilterToggle from './filter-toggle';
import FilterSub from './filter-sub';

import { getMapData } from '../../../selectors/map';
import { getlayerFilters } from '../../../selectors/ui';

import { toggleFilter } from '../../../actions/filter-actions';

import styles from './filter-card.scss';

class FilterCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      popover: null
    };

    this.toggleCardVisibility = this.toggleCardVisibility.bind(this);
    this.mainToggleHandler = this.mainToggleHandler.bind(this);
  }

  toggleCardVisibility = (id, open) => {
    this.setState({ open: !open });
  };

  mainToggleHandler = (event, id) => {
    event.stopPropagation();
    this.setState({ open: !this.props.mapData[id].checked });
    this.props.toggleFilter(id);
  };

  render() {
    const { children, id, label, layerFilters, mapData } = this.props;
    const { checked, mapStyle, uiStyle: { filterIcon, infoText, infoLink } } = mapData[id];
    const { open } = this.state;
    const visibleChildren = children && children.some(child => !child.uiStyle.hidden);
    return (
      <div className={styles.filterCard}>
        <div className={styles.filterCardToggle} onClick={() => this.toggleCardVisibility(id, open)}>
          <FilterToggle
            color={mapStyle && mapStyle.strokeColor}
            className={styles.toggle}
            checked={checked}
            onClick={(event) => this.mainToggleHandler(event, id)}
            icon={filterIcon}
          />
          <label className={classNames({ [styles.activeLabel]: checked })}>{label}</label>
          {infoText &&
            <InfoPopup label={label} className={styles.helpIcon}>
              {infoText}
              {infoLink &&
                <p><a href={infoLink.href} target="_blank" rel="noopener noreferrer">{infoLink.label}</a></p>
              }
            </InfoPopup>
          }
          {visibleChildren &&
            <div className={styles.arrowIcon}>
              {!open &&
                <Icon translate="no">keyboard_arrow_down</Icon>
              }
              {open &&
                <Icon translate="no">keyboard_arrow_up</Icon>
              }
            </div>
          }
        </div>
        {open && layerFilters.visible && visibleChildren &&
          <Fragment>
            {
              children.filter(filter => !filter.uiStyle.hidden).map(filter => (
                <div key={`filter-body-${filter.id}`} className={styles.filterCardBody}>
                  <FilterSub
                    {...filter}
                    mapData={mapData}
                    toggleFilter={this.props.toggleFilter}
                    disabled={!checked}
                  />
                </div>
              ))
            }
          </Fragment>
        }
      </div>
    );
  }
}

FilterCard.propTypes = {
  children: PropTypes.array,
  id: PropTypes.number,
  label: PropTypes.string,
  layerFilters: PropTypes.object,
  mapData: PropTypes.object,
  name: PropTypes.string,
  toggleFilter: PropTypes.func
};

const mapStateToProps = state => {
  return {
    layerFilters: getlayerFilters(state),
    mapData: getMapData(state)
  };
};

export default connect(mapStateToProps, { toggleFilter })(FilterCard);
