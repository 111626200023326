import React, { PureComponent } from 'react';
// import classNames from 'classnames';

import styles from '../common.scss';

import translateChrome from '../../../../static/translate-chrome.svg';
import chromeMore from '../../../../static/chrome-more.svg';
import translateChromeMobile1 from '../../../../static/translate-chrome-mobile-1.png';
import translateChromeMobile2 from '../../../../static/translate-chrome-mobile-2.png';

export default class ChromeMobile extends PureComponent {
  render() {
    return (
      <div className={styles.instructions}>
        <header>
          <img src={translateChrome} height="16" width="16" alt="" />Google Chrome
        </header>
        <div>
          1. At the top right, tap
          <span>
            <img className={styles.icon} src={chromeMore} height="16" width="16" alt="more icon"/>
            &gt; Translate
          </span>.<br />
          <img src={translateChromeMobile1} height="227" width="125" alt="translate instructions"/>
        </div>
        <div>
          2. At the bottom right, tap
          <span>
            <img className={styles.icon} src={chromeMore} height="16" width="16" alt="more icon"/>
            &gt; More Languages
          </span>.<br />
          <img src={translateChromeMobile2} height="124" width="172" alt="more language instructions"/>
        </div>
        <div>
          3. Select the language you want to translate to.
        </div>
      </div>
    );
  }
}
