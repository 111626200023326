/*global google*/
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getZoom, getDrawingMode } from '../../../selectors/map';

import { pixelDistanceToMeters } from '../../../utilities/map-utilities';
import { mapConfig } from '../../../constants/component-configs';

import { MAP } from 'react-google-maps/lib/constants';

class MouseSelectShadow extends Component {
  constructor (props) {
    super(props);

    this.updateCircle = this.updateCircle.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);

    this.state = {
      circle: new google.maps.Circle(),
      event: this.props.mapRef.context[MAP].addListener('mousemove', this.onMouseMove),
      touchStart: google.maps.event.addDomListener(this.props.mapRef.context[MAP].getDiv(), 'touchstart', () => {
        this.onTouchStart();
      }),
      touchEnd: google.maps.event.addDomListener(this.props.mapRef.context[MAP].getDiv(), 'touchend', () => {
        this.onTouchEnd();
      }),
      position: null,
      touchActive: false
    };
  }
  
  componentDidMount () {
    this.updateCircle();
  }

  componentDidUpdate () {
    this.updateCircle();
  }

  componentWillUnmount () {
    this.state.circle.setMap(null);
    google.maps.event.removeListener(this.state.event);
  }
  
  onMouseMove = event => {
    if (!this.props.drawingMode && !this.state.touchActive) {
      this.state.circle.setMap(this.props.mapRef.context[MAP]);
    }
    this.setState({
      position: event.latLng
    });
    this.updateCircle();
  };

  onTouchStart = () => {
    this.setState({touchActive: true});
    this.state.circle.setMap(null);
  };

  onTouchEnd = () => {
    setTimeout(() => {
      this.setState({touchActive: false});
    }, 500);
  };

  updateCircle () {
    if (this.state.position) {
      const radius = pixelDistanceToMeters(this.state.position, this.props.mapRef.context[MAP], 20);
      const center = this.state.position;
      if (radius && center) {
        const options = {
          ...mapConfig.highlightCircleOptions,
          center,
          radius
        };
        this.state.circle.setOptions(options);
      }
    } else {
      this.state.circle.setMap(null);
    }
  }

  render() {
    return null;
  }
}

MouseSelectShadow.propTypes = {
  drawingMode: PropTypes.string,
  mapRef: PropTypes.object,
  zoom: PropTypes.number
};

const mapStateToProps = state => {
  return {
    zoom: getZoom(state),
    drawingMode: getDrawingMode(state)
  };
};

export default connect(mapStateToProps, {})(MouseSelectShadow);
