/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import MapControl from '../../shared/map-control';
import MapData from '../map-data';
import MapLabels from '../map-labels';
import SearchMarker from '../search-marker';
import LargeMedia from '../../shared/media/large';
import SmallMedia from '../../shared/media/small';
import TranslateButton from '../../shared/translate-button';
import MapLegend from '../map-legend/legend';
import MarkerOverlaps from '../marker-overlaps';
import LocationButton from '../location-button';
import MapSearchAutocomplete from '../../top-bar/map-search-autocomplete';
import MapTools from '../map-tools';
import MapTypes from '../map-types';
import MeasurementCard from '../measurement-card';
import MobileMapTools from '../mobile-map-tools';
import MobileClearButton from '../mobile-clear-button';
import Notifications from '../../notifications';
import DrawingMode from '../../notifications/drawing-mode';

import { getMapData, getVisibleMapDataList } from '../../../selectors/map';
import { getSelectionElements } from '../../../selectors/map-selection';
import { getSearchMarker } from '../../../selectors/search';
import { getLegend } from '../../../selectors/ui';
import { getNotificationsVisible } from '../../../selectors/notifications';

import { openLegend, closeLegend } from '../../../actions/ui-actions';
import { makeVisible } from '../../../actions/filter-actions';

import styles from './map-components.scss';

class MapComponents extends Component {

  componentDidUpdate(prevProps) {
    const { mapData } = this.props;
    if (Object.keys(prevProps.mapData).length !== Object.keys(mapData).length) {
      Object.values(mapData).forEach(({ id, visible, uiStyle: { linkable } }) => {
        if (linkable) {
          const params = new URLSearchParams(window.location.search);
          if (params.get('link_type') && params.get('link_type') === mapData[id].name && !visible) {
            this.props.makeVisible(id);
          }
        }
      });
    }
  }

  render() {
    const { mapRef, legend, mapData, visibleDataList } = this.props;
    const visibleDataTypes = [];
    if (!this.props.notificationsVisible) {
      Object.values(mapData).forEach(({ id, visible, url, uiStyle: { searchable } }) => {
        if (searchable) {
          if (visible && url) {
            visibleDataTypes.unshift(
              <MapData key={`datatype-${id}`} id={id} mapRef={mapRef} />
            );
          }
          visibleDataTypes.push(
            <MapData key={`datatype-highlight-${id}`} id={id} searchable mapRef={mapRef} />
          );
        } else if (visible) {
          visibleDataTypes.push(
            <MapData key={`datatype-${id}`} id={id} mapRef={mapRef} />
          );
        }
      });
    }

    if (mapRef) {
      const legendButtonAction = legend.visible ? this.props.closeLegend : this.props.openLegend;
      return (
        <div>
          <SmallMedia>
            <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM} mapRef={mapRef}>
              <MobileMapTools legendButtonAction={legendButtonAction} mapRef={mapRef} />
            </MapControl>
            <MapControl position={google.maps.ControlPosition.TOP_CENTER} mapRef={mapRef}>
              <MobileClearButton />
            </MapControl>
          </SmallMedia>
          <LargeMedia>
            <MapControl className={styles.translateContainer} position={google.maps.ControlPosition.TOP_RIGHT} mapRef={mapRef}>
              <TranslateButton />
            </MapControl>
            <MapControl position={google.maps.ControlPosition.TOP_RIGHT} mapRef={mapRef}>
              <Tooltip title="Legend" placement="bottom">
                <Button
                  classes={{ root: classNames(styles.legendButton, { [styles.activeLegendButton]: legend.visible }) }}
                  onClick={legendButtonAction}
                >
                  <Icon translate="no">info</Icon>
                </Button>
              </Tooltip>
            </MapControl>
            <MapControl position={google.maps.ControlPosition.TOP_RIGHT} mapRef={mapRef}>
              <MapTypes />
            </MapControl>
            <MapControl position={google.maps.ControlPosition.TOP_RIGHT} mapRef={mapRef} className={styles.mapTools}>
              <MapTools mapRef={mapRef} />
            </MapControl>
            <MapControl position={google.maps.ControlPosition.RIGHT_TOP} mapRef={mapRef}>
              <MapLegend defaultOpen mapData={visibleDataList} />
            </MapControl>
            <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM} mapRef={mapRef}>
              <LocationButton mapRef={mapRef} />
            </MapControl>
            <MapControl position={google.maps.ControlPosition.TOP_RIGHT} mapRef={mapRef}>
              <MapSearchAutocomplete mapRef={mapRef} showClearIcon showMagnifier />
            </MapControl>
            <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER} mapRef={mapRef}>
              <Notifications mapRef={mapRef} />
            </MapControl>
            <DrawingMode />
          </LargeMedia>
          <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER} mapRef={mapRef}>
            <MeasurementCard />
          </MapControl>
          <MarkerOverlaps mapRef={mapRef} />
          {visibleDataTypes}
          {!this.props.notificationsVisible && <MapLabels />}
          {this.props.showSearchMarker && <SearchMarker />}
        </div>
      );
    }
    return null;
  }
}

MapComponents.propTypes = {
  closeLegend: PropTypes.func,
  legend: PropTypes.object,
  makeVisible: PropTypes.func,
  mapData: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  mapSelection: PropTypes.array,
  notificationsVisible: PropTypes.bool,
  openLegend: PropTypes.func,
  showSearchMarker: PropTypes.bool,
  visibleDataList: PropTypes.array
};

const mapStateToProps = state => {
  return {
    legend: getLegend(state),
    mapData: getMapData(state),
    mapSelection: getSelectionElements(state),
    showSearchMarker: getSearchMarker(state),
    visibleDataList: getVisibleMapDataList(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, { openLegend, closeLegend, makeVisible })(MapComponents);
