/* global google */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import OverlayMapType from '../../../shared/dotmaps-google-map/dotmaps-overlay-map-type';

import { googleToBBox } from 'global-mercator';
import { renderUrlParam } from '../../../../utilities/url-utilities';

const DEFAULT_WMS_PARAMS = {
  service: 'WMS', //WMS service
  version: '1.1.0', //WMS version
  request: 'GetMap', //WMS operation
  format: 'image/png',
  transparent: 'true',
  srs: 'EPSG:4326',
  height: 256,
  width: 256
};

class WmsMapType extends PureComponent {
  constructor(props) {
    super(props);
    const { url, params } = this.props;
    // bind props to this, and make them unchangable after constructor
    this.url = url;
    this.params = { ...DEFAULT_WMS_PARAMS, ...params };
    this.tileSize = new google.maps.Size(this.params.height, this.params.width);
  }

  getTileUrl = (coord, zoom) => {
    const { url, params } = this;
    if (url) {
      const bbox = googleToBBox([coord.x, coord.y, zoom]).join(',');
      const textParams = [
        ...Object.entries(params).map(([key, value]) => renderUrlParam(key, value)),
        `bbox=${bbox}`  //set bounding box for tile
      ];
      return `${url}?${textParams.join('&')}`;  //return WMS URL for the tile
    }
    return '';
  };

  getTile = (coord, zoom, ownerDocument) => {
    const tileImg = ownerDocument.createElement('img');
    if (coord.x && coord.y) {
      const tileUrl = this.getTileUrl(coord, zoom);

      tileImg.setAttribute('errors', '0');
      tileImg.onerror = () => {
        let error_count = Number(tileImg.getAttribute('errors'));
        error_count += 1;
        tileImg.setAttribute('errors', String(error_count));
        if (error_count < 5) {
          const old_src = this.src;
          setTimeout(
            () => {
              this.src = old_src;
            },
            1000 * error_count
          );
        }
      };
      tileImg.src = tileUrl;
      tileImg.style.width = `${this.tileSize.width}px`;
      tileImg.style.height = `${this.tileSize.height}px`;
      tileImg.zIndex = 100;
    }
    return tileImg;
  };

  render() {
    return <OverlayMapType overlayMapType={this} />;
  }
}

WmsMapType.propTypes = {
  params: PropTypes.shape({
    layers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
  }).isRequired,
  url: PropTypes.string.isRequired
};

export default WmsMapType;
