import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ConfirmIcon from '../confirm-icon';
import LargeMedia from '../../shared/media/large';
import SmallMedia from '../../shared/media/small';
import { getNotificationsVisible } from '../../../selectors/notifications';
import { closeNotifications, resetNotificationForm, resendConfirmation } from '../../../actions/notification-actions';

import styles from './confirmation-page.scss';


class ConfirmationPage extends Component {
  closeWindow = () => {
    this.props.closeNotifications();
    this.props.resetNotificationForm();
  };
  render() {
    return (
      <div className={styles.container}>
        <div>
          <LargeMedia><div className={styles.confirmIcon}><ConfirmIcon /></div></LargeMedia>
          <div className={styles.confirmationHeader}>Confirm your email address</div>
          <SmallMedia><div className={styles.confirmIcon}><ConfirmIcon /></div></SmallMedia>
          <div className={styles.content}>
            We’ve sent you an email to verify your email address.<br /><br />
            Please check your inbox for a confirmation email. If you can’t find the email,
            check your spam folder or <span className={styles.link} onClick={this.props.resendConfirmation}>
              resend confirmation email.</span>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <LargeMedia>
            <Button variant="flat" classes={{ root: styles.ok }} onClick={this.closeWindow} size="small">OK</Button>
          </LargeMedia>
          <SmallMedia>
            <Button variant="flat" classes={{ root: styles.ok }} onClick={this.closeWindow} fullWidth>OK</Button>
          </SmallMedia>
        </div>
      </div>
    );
  }
}

ConfirmationPage.propTypes = {
  closeNotifications: PropTypes.func,
  resendConfirmation: PropTypes.func,
  resetNotificationForm: PropTypes.func
};

const mapStateToProps = state => {
  return {
    visible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, {
  closeNotifications,
  resetNotificationForm,
  resendConfirmation
})(ConfirmationPage);
