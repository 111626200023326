import React, { PureComponent } from 'react';
// import classNames from 'classnames';

import styles from '../common.scss';

import translateChrome from '../../../../static/translate-chrome.svg';
import chromeMore from '../../../../static/chrome-more.svg';
import translateChrome1 from '../../../../static/translate-chrome-1.png';
import translateChrome2 from '../../../../static/translate-chrome-2.png';

export default class ChromeDesktop extends PureComponent {
  render() {
    return (
      <div className={styles.instructions}>
        <header>
          <img src={translateChrome} height="16" width="16" alt=""/>Google Chrome
        </header>
        <div>
          1. <span>Right click</span> on the page and choose <span>Translate to English</span>.<br />
          <img src={translateChrome1} height="164" width="150" alt="translate instructions"/>
        </div>
        <div>
          2. Click on more
          <span>
            <img className={styles.icon} src={chromeMore} height="16" width="16" alt="more icon"/>
            &gt; Choose Another Language
          </span> to select the language you&apos;d like to use.<br />
          <img src={translateChrome2} height="120" width="248" alt="language instructions"/>
        </div>
        <div>
          3. Click <span>Translate</span> to apply the changes.
        </div>
      </div>
    );
  }
}
