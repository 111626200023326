/* global google */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@material-ui/core/Icon';
import Drawer from '@material-ui/core/Drawer';
import LocationButton from '../location-button';
import MapTypes from '../map-types';

import { getMobileLayers, getLegend } from '../../../selectors/ui';
import { getDrawingMode } from '../../../selectors/map';
import { getDrawingVisible } from '../../../selectors/map-selection';
import { getNotificationsVisible } from '../../../selectors/notifications';
import { showMobileLayers, hideMobileLayers, showDrawingOverlay } from '../../../actions/ui-actions';
import { selectDrawingPolygonArea, setDrawingMode } from '../../../actions/map-actions';
import { MAP } from 'react-google-maps/lib/constants';

import styles from './mobile-map-tools.scss';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class MobileMapTools extends Component {
  state = { shape: null, drawingActive: false, touchStartListener: null };

  componentWillReceiveProps(nextProps) {
    if (this.state.shape && !nextProps.drawingVisible) {
      this.clearShape();
    }
    if (this.state.drawingActive && nextProps.drawingMode === '') {
      const map = this.props.mapRef.context[MAP];
      google.maps.event.removeListener(this.state.touchStartListener);
      this.enableMap(map);
      this.setState({ shape: null, drawingActive: false });
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  disableMap = (map) => map.setOptions({ draggable: false, scrollwheel: false });
  enableMap = (map) => map.setOptions({ draggable: true, scrollwheel: true });
  setFreehand = () => this.props.setDrawingMode('freehand');

  clearShape = () => {
    this.state.shape.setMap(null);
    this.setState({ shape: null, drawingActive: false });
  };

  drawFreeHand = (map) => {
    let poly = new google.maps.Polyline({ map, clickable: false });
    const move = google.maps.event.addListener(map, 'mousemove', event => {
      poly.getPath().push(event.latLng);
    });

    google.maps.event.addDomListenerOnce(map.getDiv(), 'touchend', () => {
      enableBodyScroll(document.querySelector('#app'));
      google.maps.event.removeListener(move);
      const path = poly.getPath();
      poly.setMap(null);
      poly = new google.maps.Polygon({ map, path });
      this.setState({ shape: poly });
      this.props.selectDrawingPolygonArea(path.getArray());
      google.maps.event.removeListener(this.state.touchStartListener);
      this.enableMap(map);
    });
  };

  startDrawing = () => {
    if (this.state.drawingActive) {
      return;
    }
    this.setFreehand();
    this.props.showDrawingOverlay();
    if (this.state.shape) {
      this.state.shape.setMap(null);
    }
    this.setState({ drawingActive: true });
    const map = this.props.mapRef.context[MAP];
    disableBodyScroll(document.querySelector('#app'));
    this.disableMap(map);
    this.setState({
      touchStartListener: google.maps.event.addDomListener(map.getDiv(), 'touchstart', () => {
        this.drawFreeHand(map);
      })
    });
  };

  render() {
    return (
      <Fragment>
        <div>
          <div className={styles.buttonGroup}>
            {!this.props.notificationsVisible &&
              <Fragment>
                <div className={styles.button} onClick={this.props.showMobileLayers}>
                  <div className={this.props.mobileLayers.visible ? styles.selected : null}><Icon translate="no">layers</Icon></div>
                </div>
                <div className={styles.button} onClick={this.props.legendButtonAction}>
                  <div className={this.props.legend.visible ? styles.selected : null}><Icon translate="no">info</Icon></div>
                </div>
                <div className={styles.button} onClick={this.startDrawing}>
                  <div className={this.state.drawingActive ? styles.selected : null}><Icon translate="no">touch_app</Icon></div>
                </div>
              </Fragment>
            }
            <div className={classNames(styles.button, { [styles.notifications]: this.props.notificationsVisible })}>
              <LocationButton />
            </div>
          </div>
        </div>
        {!this.props.notificationsVisible &&
          <Drawer anchor="bottom" open={this.props.mobileLayers.visible} onClose={this.props.hideMobileLayers}>
            <div className={styles.layersContainer}>
              <label className={styles.header}>Map Layers</label>
              <MapTypes />
            </div>
          </Drawer>
        }
      </Fragment>
    );
  }
}

MobileMapTools.propTypes = {
  drawingMode: PropTypes.string,
  drawingVisible: PropTypes.bool,
  hideMobileLayers: PropTypes.func,
  legend: PropTypes.object,
  legendButtonAction: PropTypes.func,
  mapRef: PropTypes.object,
  mobileLayers: PropTypes.object,
  notificationsVisible: PropTypes.bool,
  selectDrawingPolygonArea: PropTypes.func,
  setDrawingMode: PropTypes.func,
  showDrawingOverlay: PropTypes.func,
  showMobileLayers: PropTypes.func
};

const mapStateToProps = state => {
  return {
    drawingVisible: getDrawingVisible(state),
    legend: getLegend(state),
    mobileLayers: getMobileLayers(state),
    drawingMode: getDrawingMode(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, {
  showMobileLayers, hideMobileLayers, selectDrawingPolygonArea, showDrawingOverlay, setDrawingMode
})(MobileMapTools);

