import axios from 'axios';

import {
  MAP_DATA_FETCH_SUCCESS,
  MAP_SELECT_AREA,
  MAP_INVALIDATE_SELECT_AREA,
  MAP_SELECT_ITEM,
  MAP_CLEAR_HIGHLIGHT,
  MAP_HIGHLIGHT,
  MAP_VIEWPORT_CHANGE,
  MAP_SET_CENTER,
  MAP_SET_ZOOM,
  MAP_SET_STREET_VIEW_VISIBLE,
  MAP_SET_TYPE_ID,
  MAP_TOGGLE_TRAFFIC,
  MAP_SET_DRAWING_MODE,
  MAP_SELECT_AREA_DRAWING,
  MAP_MEASURE_ACTIVE,
  MAP_MEASURE_LENGTH,
  MAP_SET_USER_LOCATION,
  MAP_SET_LABEL_GRID,
  MAP_SET_DEV_SECRET_REQUIRED,
  MAP_SET_DEV_SECRET,
  SET_LINKED_ELEMENT,
  SET_LINKED_ELEMENT_ACTIVE,
  SET_404_PAGE
} from '../constants/action-types';

import { mapDataConfig, jsonMapDataConfig } from '../utilities/map-data-config';
import { googleMapsBoundsToBbox, bboxToGoogleMapsBounds } from '../utilities/geometry-utilities';
import { pixelDistanceToMeters } from '../utilities/map-utilities';
import { calculateLabels } from '../utilities/map-labels';
import { getFiltersFlat, getInitialUrlFilter } from '../selectors/filters';
import { getMapData, getViewport } from '../selectors/map';
import { closeLayerFilters } from './ui-actions';

const getSetViewportAction = (center, zoom, bbox) => ({
  type: MAP_VIEWPORT_CHANGE,
  viewport: {
    center,
    zoom,
    bbox
  }
});

export const setViewport = map => (dispatch, getState) => {
  const existing = getViewport(getState());
  const center = map.getCenter();
  const zoom = map.getZoom();
  const bbox = googleMapsBoundsToBbox(map.getBounds());
  let newCenter = null;
  if (center) { 
    const newLat = center.lat();
    const newLng = center.lng();
    if (newLat !== existing.center?.lat || newLng !== existing.center?.lng) {
      newCenter = {
        lat: newLat,
        lng: newLng
      };
    } else {
      newCenter = existing.center;
    }
  }
  dispatch(getSetViewportAction(
    newCenter,
    zoom,
    bbox
  ));
};

const fetchMapDataSuccess = (id, payload, replace = false) => ({
  type: MAP_DATA_FETCH_SUCCESS,
  id,
  replace,
  payload
});

const cancelTokens = {};
const checkAndCreateCancelTokens = id => {
  if (cancelTokens[id]) {
    cancelTokens[id].cancel();
  }
  cancelTokens[id] = axios.CancelToken.source();
  return cancelTokens[id].token;
};

export const fetchMapData = (id, url, params = {}) => (dispatch, getState) => {
  if (window.location.protocol === 'https:') {
    const parsedUrl = url.split(':');
    parsedUrl[0] = 'https';
    url = parsedUrl.join(':');
  }

  const state = getState();
  const filter = getFiltersFlat(state)[id];
  const search = window.location.search;
  if (search && filter.uiStyle.filterable && getInitialUrlFilter(state)) {
    const [type, value] = search.substr(1).split(',')[0].split('=');
    if (type !== 'link_type') {
      params = { ...params, type, value };
    }
  }
  axios.get(
    url,
    { params, cancelToken: checkAndCreateCancelTokens(id), ...mapDataConfig }
  ).then(({ data }) => {
    dispatch(fetchMapDataSuccess(id, data));
  }).catch(thrown => {
    if (!axios.isCancel(thrown)) {
      throw thrown;
    }
  });
};

export const fetchJsonMapData = (id, url, replace = false) => (dispatch, getState) => {
  if (window.location.protocol === 'https:') {
    const parsedUrl = url.split(':');
    parsedUrl[0] = 'https';
    url = parsedUrl.join(':');
  }
  axios.get(
    url,
    { cancelToken: checkAndCreateCancelTokens(id), ...jsonMapDataConfig }
  ).then(({ data }) => {
    const filter = getFiltersFlat(getState())[id];
    if (filter && filter.aliases) {
      const idField = filter.aliases.filter(([, label]) => label === 'id')[0];
      if (idField) {
        data.results.forEach(result => {
          result.id = result.attrs[idField[0]];
        });
      }
    }
    dispatch(fetchMapDataSuccess(id, data, replace));
  }).catch(thrown => {
    if (!axios.isCancel(thrown)) {
      throw thrown;
    }
  });
};

const setLinkedElement = (layerData, data) => ({
  type: SET_LINKED_ELEMENT,
  layerData,
  data
});

export const setLinkedElementActive = () => ({
  type: SET_LINKED_ELEMENT_ACTIVE,
  active: true
});

export const setLinkedElementInactive = () => ({
  type: SET_LINKED_ELEMENT_ACTIVE,
  active: false
});

export const set404Page = () => ({
  type: SET_404_PAGE
});

export const deepLinkDataFetch = (dataTypeId, url, remoteId, mapRef, params = {}) => (dispatch, getState) => {
  const layerData = getMapData(getState())[dataTypeId];
  axios.get(
    `${url}?remote_id=${remoteId}`,
    { params, ...mapDataConfig }
  ).then(({ data }) => {
    if (!data.results.length) {
      dispatch(set404Page());
    } else {
      dispatch(fetchMapDataSuccess(dataTypeId, data));
      dispatch(setLinkedElement(layerData, data.results[0]));
      dispatch(setLinkedElementActive());
      mapRef.fitBounds(bboxToGoogleMapsBounds(data.results[0].bbox));
      dispatch(closeLayerFilters());
    }
  });
};

const selectAreaAction = area => ({
  type: MAP_SELECT_AREA,
  area
});

const setDrawingAreaAction = area => ({
  type: MAP_SELECT_AREA_DRAWING,
  area
});

const getCircle = (latLng, googleMap, pixelRadius = 20, meters = false) => {
  return {
    lng: latLng.lng(),
    lat: latLng.lat(),
    radius: !meters ? pixelDistanceToMeters(latLng, googleMap, pixelRadius) : pixelRadius
  };
};

export const selectArea = (latLng, googleMap, pixelRadius = 20, meters = false) => dispatch => {
  const area = getCircle(latLng, googleMap, pixelRadius, meters);
  dispatch(setLinkedElementInactive);
  dispatch(selectAreaAction(area));
};

export const selectDrawingArea = (latLng, googleMap, pixelRadius = 20, meters = false) => dispatch => {
  const area = getCircle(latLng, googleMap, pixelRadius, meters);
  dispatch(setDrawingAreaAction(area));
};

export const selectDrawingPolygonArea = (coordinates) => dispatch => {
  const area = {
    coordinates: [[...coordinates.map(coord => [coord.lng(), coord.lat()]), [coordinates[0].lng(), coordinates[0].lat()]]]
  };
  dispatch(setDrawingAreaAction(area));
};

const clearSelectionAction = () => ({
  type: MAP_SELECT_AREA,
  area: null
});

const clearItemSelectionAction = () => ({
  type: MAP_SELECT_ITEM,
  item: null
});

export const clearAreaSelection = () => dispatch => {
  dispatch(clearSelectionAction());
  dispatch(clearItemSelectionAction());
};

const invalidateAreaSelectionAction = () => ({
  type: MAP_INVALIDATE_SELECT_AREA
});

export const invalidateAreaSelection = () => dispatch => {
  dispatch(invalidateAreaSelectionAction());
};

const selectItemAction = (area, index) => ({
  type: MAP_SELECT_ITEM,
  item: { area: { ...area }, index }
});

export const selectItem = (area, index) => dispatch => {
  dispatch(selectItemAction(area, index));
};

export const clearItemSelection = () => dispatch => {
  dispatch(clearItemSelectionAction());
};

const clearHighlightAction = (name) => ({
  type: MAP_CLEAR_HIGHLIGHT,
  name
});

export const clearHighlight = name => dispatch => {
  dispatch(clearHighlightAction(name));
};

const setHighlightAction = (name, layerId, itemId) => ({
  type: MAP_HIGHLIGHT,
  name,
  layerId,
  itemId
});

export const setHighlight = (name, layerId, itemId) => dispatch => {
  dispatch(setHighlightAction(name, layerId, itemId));
};

const getSetCenterAction = center => ({
  type: MAP_SET_CENTER,
  center
});

export const setCenter = center => dispatch => {
  dispatch(getSetCenterAction(center));
};

const getSetZoomAction = zoom => ({
  type: MAP_SET_ZOOM,
  zoom
});

export const setZoom = zoom => dispatch => {
  dispatch(getSetZoomAction(zoom));
};

const getStreetViewVisibleAction = visible => ({
  type: MAP_SET_STREET_VIEW_VISIBLE,
  visible
});

export const setStreetViewVisible = visible => dispatch => {
  dispatch(getStreetViewVisibleAction(visible));
};

const newMapTypeId = payload => ({
  type: MAP_SET_TYPE_ID,
  payload
});

export const setMapTypeId = mapTypeId => dispatch => dispatch(newMapTypeId(mapTypeId));

export const toggleTraffic = () => dispatch => dispatch({ type: MAP_TOGGLE_TRAFFIC });

export const setDrawingMode = mode => dispatch => dispatch({ type: MAP_SET_DRAWING_MODE, mode });

export const setMeasureActive = active => dispatch => dispatch({ type: MAP_MEASURE_ACTIVE, active });

export const setMeasureLength = length => dispatch => dispatch({ type: MAP_MEASURE_LENGTH, length });

export const setUserLocation = userLocation => dispatch => dispatch({ type: MAP_SET_USER_LOCATION, userLocation });

const getSetLabelGridAction = labelGrid => ({
  type: MAP_SET_LABEL_GRID, labelGrid
});

export const updateLabelGrid = (bbox, polygonData) => dispatch => {
  calculateLabels(polygonData, bbox).then(
    results => {
      dispatch(getSetLabelGridAction(results));
    },
    () => { } // Do nothing on reject
  );
};

const getSetDevSecretRequiredAction = () => ({
  type: MAP_SET_DEV_SECRET_REQUIRED
});

const getSetDevSecretAction = secret => ({
  type: MAP_SET_DEV_SECRET,
  secret
});

export const requestAuthSecret = () => dispatch => {
  dispatch(getSetDevSecretAction(null));
  dispatch(getSetDevSecretRequiredAction());
};

export const setAuthSecret = (secret) => dispatch => {
  dispatch(getSetDevSecretAction(secret));
};
