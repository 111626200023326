import { connectRouter } from 'connected-react-router';
import map from './map-reducer';
import ui from './ui-reducer';
import filters from './filter-reducer';
import search from './search-reducer';
import notifications from './notification-reducer';

const getReducers = history => ({
  map,
  ui,
  filters,
  search,
  notifications,
  router: connectRouter(history)
});

export default getReducers;
