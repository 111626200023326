import { Children } from 'react';
import { Carousel } from 'react-responsive-carousel';

export default class PatchedCarousel extends Carousel {
  setupCarousel() {
    this.bindEvents();

    if (this.state.autoPlay && Children.count(this.props.children) > 1) {
      this.setupAutoPlay();
    }

    this.setState(
      {
        initialized: true
      },
      () => {
        const initialImage = this.getInitialImage();
        if (initialImage) {
          // if it's a carousel of images, we set the mount state after the first image is loaded
          initialImage.addEventListener('load', this.setMountState);
        } else {
          this.setMountState();
        }
      }
    );
  }
}
