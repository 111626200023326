/* eslint-disable max-depth */
import { createSelector } from 'reselect';

import bbox from '@turf/bbox';
import circle from '@turf/circle';
import { polygon } from '@turf/helpers';

import { getVisibleMapDataList, getHighlightedElements, getDrawingArea } from './map';

import { bboxIntersects, pointDistanceFunctions, polygonIntersectionFunctions } from '../utilities/geometry-utilities';

let lastConfirmedArea = null;
let lastConfirmedResults = null;

const getSelection = state => state.map.selection;
const getLinkedElement = state => state.map.linkedElement;
const getLinkedElementActive = state => state.map.linkedElementActive;

const getSelectedItemIndex = createSelector(
  [getSelection],
  selection => selection && selection.item
);

const getSelectionArea = createSelector(
  [getSelection],
  selection => {
    let area = selection && selection.area || null;
    if (area && area.invalidated) {
      area = lastConfirmedArea;
    }
    return area;
  }
);

const getAreaBbox = createSelector(
  [getSelectionArea],
  area => {
    if (!area) {
      return null;
    }
    const {
      radius = null,
      lng,
      lat
    } = area;
    if (radius !== null) {
      const center = [lng, lat];
      return bbox(circle(center, radius, { units: 'meters' }));
    }
    return bbox(polygon(area.coordinates));
  }
);

export const getSelectionAreaWithBbox = createSelector(
  [getSelectionArea, getAreaBbox],
  (area, bbox_) => {
    return { ...area, bbox_ };
  }
);

const filterUnselectableData = mapDataList => {
  if (mapDataList) {
    return mapDataList.filter(data => data.list && data.list.length > 0);
  }
  return [];
};

const getSelectionResults = createSelector(
  [getSelectionArea, getAreaBbox, getVisibleMapDataList, getDrawingArea, getLinkedElement, getLinkedElementActive],
  (area, areaBbox, visibleData, drawingArea, linkedElement, linkedElementActive) => {
    const selectableData = filterUnselectableData(visibleData);
    // If there is no selection area, return empty results
    if (!area) {
      lastConfirmedArea = null;
      lastConfirmedResults = null;
      if (Object.entries(linkedElement).length && linkedElementActive) {
        lastConfirmedResults = [linkedElement];
        return {
          area: {},
          items: [linkedElement]
        };
      }
      return {
        area: null,
        items: null
      };
    }
    const { radius } = area;
    const center = [area.lng, area.lat];
    const items = [];
    const unitOptions = { units: 'meters' };
    selectableData.forEach(layerData => {
      layerData.list.forEach(data => {
        const dataBbox = data.bbox;
        // BBOX intersects
        if (bboxIntersects(areaBbox, dataBbox)) {
          if (radius) {
            const distanceFunction = pointDistanceFunctions[data.shape.type];
            if (distanceFunction) {
              const dist = distanceFunction(data.shape, center, unitOptions);
              if (dist < radius) {
                items.push({
                  selectionDistance: dist,
                  layerData,
                  data
                });
              }
            }
          } else {
            const intersectionFunction = polygonIntersectionFunctions[data.shape.type];
            if (intersectionFunction && intersectionFunction(data.shape.coordinates, area.coordinates)) {
              items.push({
                selectionDistance: null,
                layerData,
                data
              });
            }
          }
        }
      });
    });
    if (items.length > 0 || area === drawingArea) {
      // eslint-disable-next-line id-length
      items.sort((a, b) => {
        if (a.layerData.uiStyle.resultsOrder < b.layerData.uiStyle.resultsOrder) {
          return -1;
        }
        if (a.layerData.uiStyle.resultsOrder > b.layerData.uiStyle.resultsOrder) {
          return 1;
        }
        if (a.distance < b.distance) {
          return -1;
        }
        if (b.distance < a.distance) {
          return 1;
        }
        if (a.layer < b.layer) {
          return -1;
        }
        if (b.layer < a.layer) {
          return 1;
        }
        if (a.data.id < b.data.id) {
          return -1;
        }
        if (b.data.id < a.data.id) {
          return 1;
        }
        return 0;
      });
      // If there are results for the selected area, then we confirm the selection and save it
      // We maintain and show the last confirmed selection if future selelctions are considered invalid.
      lastConfirmedArea = area;
      lastConfirmedResults = items;
      return {
        area,
        items
      };
    }

    if (area === lastConfirmedArea) {
      // Selection area didn't change, but became empty and invalid.
      // This probably means they turned off some layers and now we have an empty result set.
      // Normally we reject this selection, but we had already confirmed it when it had ressults.
      // But now there is nothing to show, so we show an empty results set even though we normally don't allow this.
      lastConfirmedResults = items;
    }

    // The current selection is invalid and the last valid results are returned
    return {
      area: lastConfirmedArea,
      items: lastConfirmedResults
    };
  }
);

export const getDrawingVisible = createSelector(
  [getSelectionResults, getDrawingArea],
  ({ area }, drawingArea) => {
    return drawingArea && area === drawingArea;
  }
);

export const getSelectionElements = createSelector(
  [getSelectionResults],
  ({ items }) => items
);

export const getSelectionElementsCount = createSelector(
  [getSelectionElements],
  items => items && items.length
);

export const getSelectedArea = createSelector(
  [getSelectionResults],
  ({ area }) => area
);

export const getSelectedIndex = createSelector(
  [getSelectedItemIndex, getSelectionResults],
  (itemSelection, areaSelection) => {
    const {
      area: {
        lng: itemLng = null,
        lat: itemLat = null,
        zoom: itemZoom = null
      } = {},
      index: itemIndex
    } = itemSelection || {};
    const { 
      area: {
        lng: selectionLng = null,
        lat: selectionLat = null,
        zoom: selectionZoom = null
      } = {},
      items: selectionItems
    } = areaSelection || {};
    if (selectionItems) {
      if (
        itemLng === selectionLng &&
        itemLat === selectionLat &&
        itemZoom === selectionZoom &&
        selectionItems[itemIndex]
      ) {
        return itemIndex;
      }
    }
    return 0;
  }
);

export const getSelectedElement = createSelector(
  [getSelectedItemIndex, getSelectionResults],
  (itemSelection, areaSelection) => {
    const {
      area: {
        lng: itemLng = null,
        lat: itemLat = null,
        zoom: itemZoom = null
      } = {},
      index: itemIndex
    } = itemSelection || {};
    const { 
      area: {
        lng: selectionLng = null,
        lat: selectionLat = null,
        zoom: selectionZoom = null
      } = {},
      items: selectionItems
    } = areaSelection || {};
    if (selectionItems) {
      if (selectionItems.length === 1) {
        return selectionItems[0];
      }
      if (
        itemLng === selectionLng &&
        itemLat === selectionLat &&
        itemZoom === selectionZoom &&
        selectionItems[itemIndex]
      ) {
        return selectionItems[itemIndex];
      }
    }
    return null;
  }
);

export const getHighlightedOrSelectedElements = createSelector(
  [getSelectionElements, getHighlightedElements],
  (selectedItems, highlights) => {
    if (Object.keys(highlights).length) {
      return highlights;
    }

    const layers = {};
    if (selectedItems) {
      Object.values(selectedItems).forEach(({ layerData: { id: layerId }, data: { id: itemId } }) => {
        if (!layers[layerId]) {
          layers[layerId] = {};
        }
        layers[layerId][itemId] = true;
      });
    }
    return layers;
  }
);
