import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { clearInitialUrlFilter } from '../../../actions/filter-actions';
import { getInitialUrlFilter } from '../../../selectors/filters';
import { getLinkedElement } from '../../../selectors/search';

import styles from './mobile-clear-button.scss';

class MobileClearButton extends Component {
  render() {
    const { linkedElement, initialUrlFilter } = this.props;
    if (linkedElement && initialUrlFilter) {
      return (
        <div className={styles.container}>
          <div className={styles.label}>{linkedElement.label}</div>
          <div className={styles.button} onClick={this.props.clearInitialUrlFilter}>CLEAR</div>
        </div>
      );
    } 
    return null;
  }
}

MobileClearButton.propTypes = {
  clearInitialUrlFilter: PropTypes.func,
  initialUrlFilter: PropTypes.bool,
  linkedElement: PropTypes.object
};

const mapStateToProps = state => {
  return {
    initialUrlFilter: getInitialUrlFilter(state),
    linkedElement: getLinkedElement(state)
  };
};

export default connect(mapStateToProps, {clearInitialUrlFilter})(MobileClearButton);
