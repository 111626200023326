import React from 'react';
import PropTypes from 'prop-types';

import MediaQuery from 'react-responsive';

export default function SmallMedia(props) {
  return (
    <MediaQuery minDeviceWidth={768}>
      {(matches) => {
        if (!matches) {
          return props.children || null;
        }
        return null;
      }}
    </MediaQuery>
  );
}

SmallMedia.propTypes = {
  children: PropTypes.node.isRequired
};
