// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-error-404-___error-404__container{padding-top:8.75em;text-align:center;color:#000}.src-components-error-404-___error-404__container .src-components-error-404-___error-404__title{font-size:5em;font-weight:500}.src-components-error-404-___error-404__container .src-components-error-404-___error-404__content{font-size:1.25em;margin-bottom:2.5rem}.src-components-error-404-___error-404__container img{margin:3em 0 5.25em 0}.src-components-error-404-___error-404__container a{color:#4284f4;text-decoration:none}
`, "",{"version":3,"sources":["webpack://./src/components/error-404/error-404.scss"],"names":[],"mappings":"AAIA,kDACI,kBAAmB,CACnB,iBAAkB,CAClB,UAAW,CAHf,gGAMQ,aAAc,CACd,eAAgB,CAPxB,kGAWQ,gBAAiB,CACjB,oBAAqB,CAZ7B,sDAgBQ,qBAAsB,CAhB9B,oDAoBQ,aAAc,CACd,oBAAqB","sourcesContent":["@import '../../styles/mixins';\n@import '../../styles/vars';\n\n\n.container {\n    padding-top: 8.75em;\n    text-align: center;\n    color: #000;\n\n    .title {\n        font-size: 5em;\n        font-weight: 500;\n    }\n\n    .content {\n        font-size: 1.25em;\n        margin-bottom: 2.5rem;\n    }\n\n    img {\n        margin: 3em 0 5.25em 0;\n    }\n\n    a {\n        color: #4284f4;\n        text-decoration: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-error-404-___error-404__container`,
	"title": `src-components-error-404-___error-404__title`,
	"content": `src-components-error-404-___error-404__content`
};
export default ___CSS_LOADER_EXPORT___;
