import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import MobileCarousel from '../mobile-carousel';

import { getVisibleMapDataList } from '../../../../selectors/map';
import { getLegend } from '../../../../selectors/ui';

import { closeLegend } from '../../../../actions/ui-actions';

import styles from './map-legend-mobile.scss';

class MobileMapLegend extends Component {
  componentDidMount() {
    this.props.closeLegend();
  }

  render() {
    const { legend, visibleDataList } = this.props;
    if (legend.visible) {
      const legendLayers = visibleDataList.filter(layerData => layerData.uiStyle.showInLegend);
      return (
        <Fragment>
          <div className={styles.legendTitle}>
            Legend
          </div>
          <IconButton
            className={styles.closeButton}
            onClick={this.props.closeLegend}
          >
            <Icon translate="no">close</Icon>
          </IconButton>
          <MobileCarousel layers={legendLayers} />
        </Fragment>
      );
    }
    return null;
  }
}

MobileMapLegend.propTypes = {
  closeLegend: PropTypes.func,
  legend: PropTypes.object,
  visibleDataList: PropTypes.array
};

const mapStateToProps = state => {
  return {
    legend: getLegend(state),
    visibleDataList: getVisibleMapDataList(state)
  };
};

export default connect(mapStateToProps, { closeLegend })(MobileMapLegend);
