import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import InfoPopup from '../../info-popup';

import classNames from 'classnames';

import { getNoTranslate } from '../../../../utilities/map-data-utilities';

import styles from './filter-sub.scss';

export default class FilterSub extends PureComponent {
  render() {
    const { id, label, children, disabled, mapData, toggleFilter } = this.props;
    const { checked, uiStyle: { filterIcon: icon, infoText, infoLink } } = mapData[id];
    const notranslate = getNoTranslate('label', mapData[id]);
    const labelProps = {};
    if (notranslate) {
      labelProps.translate = 'no';
    }
    return (
      <Fragment>
        <div className={styles.filterCardItem}>
          <div className={styles.checkbox}>
            <Checkbox
              checked={checked}
              onChange={() => toggleFilter(id)}
              disableRipple
              disabled={disabled}
              classes={{
                root: classNames(
                  styles.checkboxRoot,
                  { [styles.checkboxDisabled]: disabled },
                  { [styles.checkboxChecked]: checked },
                  { [styles.checkboxUncheckedDisabled]: disabled && !checked }
                )
              }}
            />
          </div>
          {icon &&
            <div><img src={icon} alt={icon} /></div>
          }
          <label
            className={classNames({ [styles.activeLabel]: checked && !disabled })}
            {...labelProps}
          >{label}</label>
          {infoText &&
            <InfoPopup label={label} className={styles.helpIcon}>
              {infoText}
              {infoLink &&
                <p><a href={infoLink.href} target="_blank" rel="noopener noreferrer">{infoLink.label}</a></p>
              }
            </InfoPopup>
          }
        </div>
        {children && children.length > 0 &&
          <div className={styles.filterChildSection}>
            {children.filter(child => !child.uiStyle.hidden).map(child => (
              <div key={`subheader-${child.id}`} >
                <FilterSub
                  {...child}
                  mapData={mapData}
                  toggleFilter={toggleFilter}
                  disabled={disabled || !checked}
                />
              </div>
            ))}
          </div>
        }
      </Fragment>
    );
  }
}

FilterSub.propTypes = {
  children: PropTypes.array,
  disabled: PropTypes.bool,
  id: PropTypes.number,
  label: PropTypes.string,
  mapData: PropTypes.object,
  name: PropTypes.string,
  toggleFilter: PropTypes.func
};
