// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-header{color:rgba(0,0,0,0.4);font-size:0.875em;line-height:1.25em;min-height:1.25em;margin-bottom:0.25em}.src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-data{color:rgba(0,0,0,0.8)}.src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-link{display:block}.src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-data,.src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-link{line-height:1.25em;min-height:1.25em;margin-bottom:1em;text-decoration:none;font-style:normal}@media only screen and (min-device-width: 768px){.src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-data,.src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-link{font-size:0.875em}}
`, "",{"version":3,"sources":["webpack://./src/components/map/info-tray/info-detail/detail-text/detail-text.scss"],"names":[],"mappings":"AAGA,oFACE,qBAAsB,CACtB,iBAAkB,CAClB,kBAAmB,CACnB,iBAAkB,CAClB,oBAAqB,CACtB,kFAGC,qBAAsB,CACvB,kFAGC,aAAc,CACf,oKAGC,kBAAmB,CACnB,iBAAkB,CAClB,iBAAkB,CAClB,oBAAqB,CACrB,iBAAkB,CAElB,iDAPF,oKAQI,iBAAkB,CAErB","sourcesContent":["@import '../../../../../styles/mixins';\n@import '../../../../../styles/vars';\n\n.detail-header{\n  color: rgba(0,0,0,0.4);\n  font-size: 0.875em;\n  line-height: 1.25em;\n  min-height: 1.25em;\n  margin-bottom: 0.25em;\n}\n\n.detail-data {\n  color: rgba(0,0,0,0.8);\n}\n\n.detail-link {\n  display: block;\n}\n\n.detail-data, .detail-link{\n  line-height: 1.25em;\n  min-height: 1.25em;\n  margin-bottom: 1em;\n  text-decoration: none;\n  font-style: normal;\n\n  @media only screen and (min-device-width: $desktop-device-width) {\n    font-size: 0.875em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detail-header": `src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-header`,
	"detailHeader": `src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-header`,
	"detail-data": `src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-data`,
	"detailData": `src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-data`,
	"detail-link": `src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-link`,
	"detailLink": `src-components-map-info-tray-info-detail-detail-text-___detail-text__detail-link`
};
export default ___CSS_LOADER_EXPORT___;
