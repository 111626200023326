import {
  MAP_SELECT_ITEM,
  OPEN_DISCLAIMER_DIALOG,
  CLOSE_DISCLAIMER_DIALOG,
  OPEN_DATE_DIALOG,
  CLOSE_DATE_DIALOG,
  OPEN_MOBILE_FILTERS,
  CLOSE_MOBILE_FILTERS,
  OPEN_MOBILE_LINKS,
  CLOSE_MOBILE_LINKS,
  OPEN_MOBILE_MAX_DETAILS,
  CLOSE_MOBILE_MAX_DETAILS,
  OPEN_LEGEND,
  CLOSE_LEGEND,
  SET_DETAILE_TRAY_COLLAPSE,
  SET_MOBILE_MAP_LAYERS,
  SET_DRAWING_OVERLAY,
  SET_TRANSLATE_DIALOG
} from '../constants/action-types';
import initialState from '../store/initial-state';

const dialogReducer = (state = initialState.ui, action) => {
  switch (action.type) {
  case OPEN_DISCLAIMER_DIALOG:
    return {...state, dialog: {...state.dialog, disclaimerVisible: true}};
  case CLOSE_DISCLAIMER_DIALOG:
    return {...state, dialog: {...state.dialog, disclaimerVisible: false}};
  case OPEN_DATE_DIALOG:
    return {...state, dialog: {...state.dialog, dateVisible: true}};
  case CLOSE_DATE_DIALOG:
    return {...state, dialog: {...state.dialog, dateVisible: false}};
  case OPEN_MOBILE_FILTERS:
    return {...state, layerFilters: {...state.layerFilters, visible: true}};
  case CLOSE_MOBILE_FILTERS:
    return {...state, layerFilters: {...state.layerFilters, visible: false}};
  case OPEN_MOBILE_LINKS:
    return {...state, mobileLinks: {...state.mobileLinks, visible: true}};
  case CLOSE_MOBILE_LINKS:
    return {...state, mobileLinks: {...state.mobileLinks, visible: false}};
  case OPEN_MOBILE_MAX_DETAILS:
    return {...state, mobileMaxDetails: {...state.mobileMaxDetails, visible: true}};
  case MAP_SELECT_ITEM:
  case CLOSE_MOBILE_MAX_DETAILS:
    return {...state, mobileMaxDetails: {...state.mobileMaxDetails, visible: false}};
  case OPEN_LEGEND:
    return {...state, legend: {...state.legend, visible: true}};
  case CLOSE_LEGEND:
    return {...state, legend: {...state.legend, visible: false}};
  case SET_DETAILE_TRAY_COLLAPSE: {
    const { collapsed } = action;
    return {...state, detailTray: {...state.detailTray, collapsed}};
  }
  case SET_MOBILE_MAP_LAYERS: {
    const { visible } = action;
    return {...state, mobileLayers: {...state.mobileLayers, visible}};
  }
  case SET_DRAWING_OVERLAY: {
    const { visible } = action;
    return {...state, drawingOverlay: {...state.drawingOverlay, visible}};
  }
  case SET_TRANSLATE_DIALOG: {
    const { visible } = action;
    return {...state, translateDialog: visible};
  }
  default:
    return state;
  }
};

export default dialogReducer;
