import {
  OPEN_DISCLAIMER_DIALOG,
  CLOSE_DISCLAIMER_DIALOG,
  OPEN_DATE_DIALOG,
  CLOSE_DATE_DIALOG,
  OPEN_MOBILE_FILTERS,
  CLOSE_MOBILE_FILTERS,
  OPEN_MOBILE_LINKS,
  CLOSE_MOBILE_LINKS,
  OPEN_MOBILE_MAX_DETAILS,
  CLOSE_MOBILE_MAX_DETAILS,
  OPEN_LEGEND,
  CLOSE_LEGEND,
  SET_DETAILE_TRAY_COLLAPSE,
  SET_MOBILE_MAP_LAYERS,
  SET_DRAWING_OVERLAY,
  SET_TRANSLATE_DIALOG
} from '../constants/action-types';

const openDisclaimerAction = () => ({
  type: OPEN_DISCLAIMER_DIALOG
});

const closeDisclaimerAction = () => ({
  type: CLOSE_DISCLAIMER_DIALOG
});

export const openDisclaimerDialog = () => dispatch => {
  return dispatch(openDisclaimerAction());
};

export const closeDisclaimerDialog = () => dispatch => {
  return dispatch(closeDisclaimerAction());
};

const openDateAction = () => ({
  type: OPEN_DATE_DIALOG
});

const closeDateAction = () => ({
  type: CLOSE_DATE_DIALOG
});

export const openDateDialog = () => dispatch => {
  return dispatch(openDateAction());
};

export const closeDateDialog = () => dispatch => {
  return dispatch(closeDateAction());
};

const openLayerFiltersAction = () => ({
  type: OPEN_MOBILE_FILTERS
});

const closeLayerFiltersAction = () => ({
  type: CLOSE_MOBILE_FILTERS
});

export const openLayerFilters = () => dispatch => {
  return dispatch(openLayerFiltersAction());
};

export const closeLayerFilters = () => dispatch => {
  return dispatch(closeLayerFiltersAction());
};

const openMobileLinksAction = () => ({
  type: OPEN_MOBILE_LINKS
});

const closeMobileLinksAction = () => ({
  type: CLOSE_MOBILE_LINKS
});

export const openMobileLinks = () => dispatch => {
  return dispatch(openMobileLinksAction());
};

export const closeMobileLinks = () => dispatch => {
  return dispatch(closeMobileLinksAction());
};

const openMobileMaxDetailsAction = () => ({
  type: OPEN_MOBILE_MAX_DETAILS
});

const closeMobileMaxDetailsAction = () => ({
  type: CLOSE_MOBILE_MAX_DETAILS
});

export const openMobileMaxDetails = () => dispatch => {
  return dispatch(openMobileMaxDetailsAction());
};

export const closeMobileMaxDetails = () => dispatch => {
  return dispatch(closeMobileMaxDetailsAction());
};

const openLegendAction = () => ({
  type: OPEN_LEGEND
});

const closeLegendAction = () => ({
  type: CLOSE_LEGEND
});

export const openLegend = () => dispatch => {
  return dispatch(openLegendAction());
};

export const closeLegend = () => dispatch => {
  return dispatch(closeLegendAction());
};

const setDetailTrayCollapseAction = collapsed => ({
  type: SET_DETAILE_TRAY_COLLAPSE,
  collapsed
});

export const collapseDetailTray = () => dispatch => {
  return dispatch(setDetailTrayCollapseAction(true));
};

export const showDetailTray = () => dispatch => {
  return dispatch(setDetailTrayCollapseAction(false));
};


const setMobileLayersAction = visible => ({
  type: SET_MOBILE_MAP_LAYERS,
  visible
});

export const showMobileLayers = () => dispatch => {
  return dispatch(setMobileLayersAction(true));
};

export const hideMobileLayers = () => dispatch => {
  return dispatch(setMobileLayersAction(false));
};

const setDrawingOverlay = visible => ({
  type: SET_DRAWING_OVERLAY,
  visible
});

export const showDrawingOverlay = () => dispatch => {
  return dispatch(setDrawingOverlay(true));
};

export const hideDrawingOverlay = () => dispatch => {
  return dispatch(setDrawingOverlay(false));
};

const setTranslateDialog = visible => ({
  type: SET_TRANSLATE_DIALOG,
  visible
});

export const openTranslateDialog = () => dispatch => {
  return dispatch(setTranslateDialog(true));
};

export const closeTranslateDialog = () => dispatch => {
  return dispatch(setTranslateDialog(false));
};
