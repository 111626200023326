/* global google */
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MAP } from 'react-google-maps/lib/constants';
import { mapConfig } from '../../../../constants/component-configs';
import { setNotificationDrawingShape } from '../../../../actions/notification-actions';
import { getNotificationsVisible, getNotificationsDrawingShape } from '../../../../selectors/notifications';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class MobileDrawingTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapsShape: null,
      touchStartListener: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shape !== this.props.shape && this.props.shape === null) {
      this.state.mapsShape.setMap(null);
    }

    if (!prevProps.active && this.props.active) {
      this.startDrawing();
    } else if (prevProps.active && !this.props.active) {
      this.stopDrawing();
    }
  }

  componentWillUnmount() {
    this.stopDrawing();
    if (this.state.mapsShape) {
      this.state.mapsShape.setMap(null);
    }
  }

  disableMap = (map) => map.setOptions({ draggable: false, scrollwheel: false });
  enableMap = (map) => map.setOptions({ draggable: true, scrollwheel: true });

  drawFreeHand = (map) => {
    let poly = new google.maps.Polyline({ map, clickable: false });
    const move = google.maps.event.addListener(map, 'mousemove', (event) => {
      poly.getPath().push(event.latLng);
    });

    google.maps.event.addDomListenerOnce(map.getDiv(), 'touchend', () => {
      enableBodyScroll(document.querySelector('#app'));
      google.maps.event.removeListener(move);
      const path = poly.getPath();
      poly.setMap(null);
      poly = new google.maps.Polygon({ map, path, ...mapConfig.notificationDrawingFigureOptions });

      if (this.state.mapsShape) {
        this.state.mapsShape.setMap(null);
      }
      this.setState({ mapsShape: poly });
      const notificationShape = [...poly.getPath().getArray()];
      if (notificationShape.length) {
        this.props.setNotificationDrawingShape(notificationShape);
        google.maps.event.removeListener(this.state.touchStartListener);
        this.enableMap(map);
      }
    });
  };

  startDrawing = () => {
    const map = this.props.mapRef.context[MAP];
    disableBodyScroll(document.querySelector('#app'));
    this.disableMap(map);
    this.setState({
      touchStartListener: google.maps.event.addDomListener(map.getDiv(), 'touchstart', () => {
        this.drawFreeHand(map);
      })
    });
  };

  stopDrawing = () => {
    clearAllBodyScrollLocks();
    enableBodyScroll(document.querySelector('#app'));
    this.enableMap(this.props.mapRef.context[MAP]);
    google.maps.event.removeListener(this.state.touchStartListener);
  };

  render() {
    return true;
  }
}

MobileDrawingTools.propTypes = {
  active: PropTypes.bool,
  drawingMode: PropTypes.string,
  drawingVisible: PropTypes.bool,
  mapRef: PropTypes.object,
  setNotificationDrawingShape: PropTypes.func,
  shape: PropTypes.array
};

const mapStateToProps = state => {
  return {
    shape: getNotificationsDrawingShape(state),
    visible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, { setNotificationDrawingShape })(MobileDrawingTools);

