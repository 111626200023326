// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-info-tray-info-identify-identify-list-___identify-list__identify-card{display:flex;flex-direction:column;height:100%}@media only screen and (min-device-width: 768px){.src-components-map-info-tray-info-identify-identify-list-___identify-list__identify-card{box-shadow:inset 0 -1px 0 0 #EBEBEB;height:4.5em}}
`, "",{"version":3,"sources":["webpack://./src/components/map/info-tray/info-identify/identify-list/identify-list.scss"],"names":[],"mappings":"AAIA,0FACE,YAAa,CACb,qBAAsB,CACtB,WAAY,CAEZ,iDALF,0FAMI,mCAAoC,CACpC,YAAa,CAEhB","sourcesContent":["@import '../../../../../styles/mixins';\n@import '../../../../../styles/vars';\n\n\n.identify-card {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  @media only screen and (min-device-width: $desktop-device-width) {\n    box-shadow: inset 0 -1px 0 0 #EBEBEB;\n    height: 4.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"identify-card": `src-components-map-info-tray-info-identify-identify-list-___identify-list__identify-card`,
	"identifyCard": `src-components-map-info-tray-info-identify-identify-list-___identify-list__identify-card`
};
export default ___CSS_LOADER_EXPORT___;
