import { createSelector } from 'reselect';

export const getFilters = state => state.filters.layers;
export const getDateRanges = state => state.filters.dateRanges;
export const getSelectedDateRangeIndex = state => state.filters.selectedDateRange;
export const getInitialUrlFilter = state => state.filters.initialUrlFilter;

function flatten(filter) {
  const flattened = [];
  flattened.push(filter);
  if (filter.children) {
    filter.children.forEach(child => {
      Array.prototype.push.apply(flattened, flatten(child));
    });
  }
  return flattened;
}

export const getFiltersFlatList = createSelector(
  [getFilters],
  filters => {
    const flatFilters = [];
    Object.values(filters).forEach(filter => {
      flatten(filter).forEach(flat => {
        flatFilters.push(flat);
      });
    });
    return flatFilters;
  }
);

export const getFiltersFlat = createSelector(
  [getFiltersFlatList],
  filtersList => {
    const filtersDict = {};
    filtersList.forEach(filter => {
      filtersDict[filter.id] = filter;
    });
    return filtersDict;
  }
);

export const getSelectedDateRange = createSelector(
  [getDateRanges, getSelectedDateRangeIndex], 
  (ranges, index) => ranges[index]
);
