import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { getMobileMaxDetails } from '../../../../../selectors/ui';
import { getMapData } from '../../../../../selectors/map';

import { bboxToGoogleMapsBounds } from '../../../../../utilities/geometry-utilities';
import { getTitles } from '../../../../../utilities/map-data-utilities';
import { getDirectionsUrl } from '../../../../../constants/endpoints';

import styles from './detail-header.scss';

class DetailHeader extends Component {
  constructor(props) {
    super(props);
    this.zoomToDetail = this.zoomToDetail.bind(this);
  }

  zoomToDetail (event) {
    event.stopPropagation();
    const { mapRef, detailData: {data: {bbox} } } = this.props;
    const bounds = bboxToGoogleMapsBounds(bbox);
    mapRef.panToBounds(bounds);
    mapRef.fitBounds(bounds);
  }

  render() {
    const {
      detailData,
      mapData,
      mobileMaxDetails,
      mapRef, //eslint-disable-line no-unused-vars
      ...passThroughProps
    } = this.props;
    const titles = getTitles(mapData, detailData);
    const title = titles.pop();
    const [firstSub, ...subTitles] = titles;
    const { data: {center}, layerData} = detailData;
    const { uiStyle } = layerData;

    return (
      <Fragment>
        <div
          className={styles.title}
          {...passThroughProps}
        >
          {firstSub &&
            <div className={styles.type} >
              <div {...firstSub.props}>{firstSub.value}</div>
              {subTitles.map(({value, props}, index) =>
                <Fragment key={index} ><div className={styles.dot}>•</div><div {...props}>{value}</div></Fragment>
              )}
            </div>
          }
          <div className={styles.name} {...title.props}>{title.value}</div>
        </div>
        <div className={styles.navigationButtons}>
          <Button
            className={
              classNames(styles.zoomButton, {[styles.hidden]: mobileMaxDetails.visible})
            }
            onClick={this.zoomToDetail}
          >
            <Icon translate="no">zoom_in</Icon><div className={styles.label} >ZOOM TO...</div>
          </Button>
          {uiStyle.directionsLink &&
            <div className={styles.separator}/>
          }
          {uiStyle.directionsLink && center && 
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={getDirectionsUrl(center.lat, center.lng)}
              className={styles.directionsButton}
            >
              <Button
                className={
                  classNames(styles.button, {[styles.hidden]: mobileMaxDetails.visible})
                }
              >
                <Icon translate="no">directions</Icon>
                <div className={styles.label}>
                  GET DIRECTIONS
                </div>
              </Button>
            </a>
          }
        </div>
      </Fragment>
    );
  }
}

DetailHeader.propTypes = {
  detailData: PropTypes.object.isRequired,
  mapData: PropTypes.object,
  mapRef: PropTypes.object,
  mobileMaxDetails: PropTypes.object
};

const mapStateToProps = state => {
  return {
    mapData: getMapData(state),
    mobileMaxDetails: getMobileMaxDetails(state)
  };
};

export default connect(mapStateToProps, {})(DetailHeader);
