import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../shared/loading-indicator';

import styles from './loading-page.scss';

class LoadingPage extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.loadingScreen}>
          <div className={styles.gradientOverlay} />
          <div className={styles.content}>
            <LoadingIndicator />
            {this.props.deepLink &&
              <label>Taking you to the map...</label>
            }
            {!this.props.deepLink &&
              <label>Taking you to <span>{this.props.mapType}</span> {this.props.mapTypeValue} map...</label>
            }
          </div>
        </div>
      </div>
    );
  }
}

LoadingPage.propTypes = {
  deepLink: PropTypes.string,
  mapType: PropTypes.string,
  mapTypeValue: PropTypes.string
};

export default LoadingPage;
