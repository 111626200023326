// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-measurement-card-___measurement-card__container{background-color:#fff;width:14em;margin-left:-7em;font-size:.875em;margin-bottom:1em;padding:1em;display:flex}.src-components-map-measurement-card-___measurement-card__container label{font-weight:500}.src-components-map-measurement-card-___measurement-card__container .src-components-map-measurement-card-___measurement-card__close-icon{position:absolute;cursor:pointer;font-size:1.5em;top:.5em;right:.5em}
`, "",{"version":3,"sources":["webpack://./src/components/map/measurement-card/measurement-card.scss"],"names":[],"mappings":"AAGA,oEACE,qBAAsB,CACtB,UAAW,CACX,gBAAiB,CACjB,gBAAiB,CACjB,iBAAkB,CAClB,WAAY,CACZ,YAAa,CAPf,0EAUI,eAAgB,CAVpB,yIAcI,iBAAkB,CAClB,cAAe,CACf,eAAgB,CAChB,QAAS,CACT,UAAW","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/vars';\n\n.container {\n  background-color: #fff;\n  width: 14em;\n  margin-left: -7em;\n  font-size: .875em;\n  margin-bottom: 1em;\n  padding: 1em;\n  display: flex;\n\n  label {\n    font-weight: 500;\n  }\n  \n  .close-icon {\n    position: absolute;\n    cursor: pointer;\n    font-size: 1.5em;\n    top: .5em;\n    right: .5em;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-components-map-measurement-card-___measurement-card__container`,
	"close-icon": `src-components-map-measurement-card-___measurement-card__close-icon`,
	"closeIcon": `src-components-map-measurement-card-___measurement-card__close-icon`
};
export default ___CSS_LOADER_EXPORT___;
