import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import IdentifyCard from '../../identify-card';

import { getSelectionElements } from '../../../../../selectors/map-selection';

import styles from './identify-list.scss';

export function withIdentifyList(WrappedComponent = Fragment) {
  class IdentifyList extends Component {
    render() {
      const { mapSelection, onClickItem, mapRef, ...passThroughProps } = this.props;
      return (
        <WrappedComponent {...passThroughProps}>
          {mapSelection.map((detail, index) => {
            return (
              <div className={styles.identifyCard} key={`${detail.layerData.id}-${detail.data.id}`}>
                <IdentifyCard detailData={detail} index={index} onClick={onClickItem} mapRef={mapRef} />
              </div>
            );
          })
          }
        </WrappedComponent>
      );
    }
  }
  IdentifyList.displayName = `withIdentifyList(${WrappedComponent.displayName || WrappedComponent.name}`;

  IdentifyList.propTypes = {
    mapRef: PropTypes.object.isRequired,
    mapSelection: PropTypes.array,
    onClickItem: PropTypes.func
  };

  const mapStateToProps = state => {
    return {
      mapSelection: getSelectionElements(state)
    };
  };

  return connect(mapStateToProps, {})(IdentifyList);
}

export default withIdentifyList();
