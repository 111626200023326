import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { centerOnBoundingBox } from '../../../utilities/map-utilities';

import styles from './mobile-search-results.scss';

import {
  setSearchSelection,
  clearSearchSuggestions
} from '../../../actions/search-actions';
import { getSuggestions } from '../../../selectors/search';

class MobileSearchResults extends Component {
  clickHandler = (suggestion) => {
    if (!suggestion.disabled) {
      const { mapRef } = this.props;
      this.props.setSearchSelection(suggestion);
      if (suggestion.bbox && mapRef) {
        centerOnBoundingBox(suggestion.bbox, mapRef);
      }
    }
  };

  render() {
    const { suggestions } = this.props;
    return (
      <div className={styles.resultsContainer}>
        { suggestions && suggestions.map((suggestion, index) => {
          if (Object.prototype.hasOwnProperty.call(suggestion, 'header')) {
            return <div key={`suggestion-${index}`} className={styles.header}>{suggestion.label}</div>;
          }
          return (
            <div
              key={`suggestion-${index}`}
              className={styles.resultItem}
              onClick={() => this.clickHandler(suggestion)}
            >
              {suggestion.type === 'address' &&
                <div>
                  <div className={styles.main}>{suggestion.mainText}</div>
                  <div className={styles.secondary}>{suggestion.secondaryText}</div>
                </div>
              }
              {suggestion.type !== 'address' && 
                suggestion.label
              }
            </div>
          );
        })}
      </div>
    );
  }
}

MobileSearchResults.propTypes = {
  clearSearchSuggestions: PropTypes.func,
  mapRef: PropTypes.object,
  setSearchSelection: PropTypes.func,
  suggestions: PropTypes.array
};

const mapStateToProps = state => {
  return {
    suggestions: getSuggestions(state)
  };
};

export default connect(mapStateToProps, {
  clearSearchSuggestions,
  setSearchSelection
})(MobileSearchResults);

