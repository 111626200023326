/* global google */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { MAP } from 'react-google-maps/lib/constants';

import DotMapsGoogleMap from '../shared/dotmaps-google-map';
import {
  getViewport, getStreetView, getMapTypeId, getTraffic,
  getMeasureToolActive, getLoading
} from '../../selectors/map';
import { getSearchMarker, getSearchFocused } from '../../selectors/search';
import { getNotificationsVisible } from '../../selectors/notifications';
import { setViewport, setStreetViewVisible, selectArea } from '../../actions/map-actions';
import { setSearchMarker } from '../../actions/search-actions';
import { StreetViewPanorama } from 'react-google-maps';

import Disclaimer from '../disclaimer';
import LandingPage from '../landing-page';
import TopBar from '../top-bar';
import TranslateInstructions from '../shared/translate-instructions';
import MapComponents from './map-components';
import MapTrays from './map-trays';
import SmallMedia from '../shared/media/small';
import MobileSearchResults from './mobile-search-results';
import MouseSelectShadow from './mouse-select-shadow';
import DrawingOverlay from './drawing-overlay';
import CollapsableInfoTray from './collapsable-info-tray';
import MobileNotifications from '../notifications/mobile-notifications';

import classNames from 'classnames';
import { remToPx } from '../../utilities/dom-utilities';

import { ignoreIfOverlay } from '../shared/dotmaps-google-map/dotmaps-overlay-view/events';
import { debounce } from 'lodash';

import styles from './map.scss';
import LargeMedia from '../shared/media/large';
import LoadingIndicator from '../shared/loading-indicator';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = { viewportSet: false, initialElementLinked: false };
    this.onMapMounted = this.onMapMounted.bind(this);
    this.onSelectArea = this.onSelectArea.bind(this);
    this.updateViewport = debounce(this.updateViewport.bind(this), 600);
    this.onBoundsChanged = this.onBoundsChanged.bind(this);
    this.onStreetViewVisibleChanged = this.onStreetViewVisibleChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { streetView, notificationsVisible } = this.props;
    const { mapRef = null } = this.state;
    if (mapRef && prevProps.streetView.visible !== streetView.visible) {
      google.maps.event.trigger(mapRef.context[MAP], 'resize');
      google.maps.event.trigger(mapRef.getStreetView(), 'resize');
    }
    if (mapRef && prevProps.notificationsVisible !== notificationsVisible && notificationsVisible) {
      mapRef.context[MAP].setOptions({ minZoom: 16 });
    } else if (mapRef && prevProps.notificationsVisible !== notificationsVisible && !notificationsVisible) {
      mapRef.context[MAP].setOptions({ minZoom: null });
    }
  }

  onMapMounted(mapRef) {
    this.setState({ mapRef });
  }

  onSelectArea(event) {
    if (this.state.mapRef && !this.props.measureActive) {
      this.props.selectArea(
        event.latLng,
        this.state.mapRef,
        remToPx(1.25)
      );
    }
  }

  updateViewport() {
    if (!this.state.viewportSet && this.props.searchMarker) {
      this.setState({ viewportSet: true });
    } else if (this.state.viewportSet && this.props.searchMarker) {
      this.props.setSearchMarker(false);
      this.setState({ viewportSet: false });
    }
  }

  onBoundsChanged() {
    this.props.setViewport(this.state.mapRef);
  }

  onStreetViewVisibleChanged() {
    this.props.setStreetViewVisible(this.state.mapRef.getStreetView().getVisible());
  }

  render() {
    const { viewport, streetView, searchFocused, mapTypeId, traffic } = this.props;
    return (
      <div className={styles.mapContainer}>
        <LargeMedia>
          {!viewport.center &&
            <LandingPage mapRef={this.state.mapRef} />
          }
        </LargeMedia>
        <SmallMedia>
          {!viewport.center && !searchFocused &&
            <LandingPage mapRef={this.state.mapRef} />
          }
          <div className={styles.mapTopBar}>
            <TopBar mapRef={this.state.mapRef} />
          </div>
          {this.props.notificationsVisible && <MobileNotifications mapRef={this.state.mapRef} />}
          <DrawingOverlay />
        </SmallMedia>
        {this.state.mapRef &&
          <MapTrays mapRef={this.state.mapRef} />
        }
        <div
          className={classNames(
            styles.mapContent,
            { [styles.streetView]: streetView.visible, [styles.searchContent]: searchFocused }
          )}
        >
          {this.props.loading && !this.props.notificationsVisible &&
            <div className={styles.loadingContainer}><LoadingIndicator /></div>
          }
          {searchFocused &&
            <SmallMedia>
              <div className={styles.mobileSearchResults}>
                <MobileSearchResults mapRef={this.state.mapRef} />
              </div>
            </SmallMedia>
          }
          <TranslateInstructions />
          <Disclaimer />
          {this.state.mapRef && !streetView.visible &&
            <LargeMedia>
              <CollapsableInfoTray mapRef={this.state.mapRef} />
            </LargeMedia>
          }
          <DotMapsGoogleMap
            containerElement={
              <div className={classNames(
                styles.mapElementContainer, { [styles.loading]: this.props.loading && !this.props.notificationsVisible })} />
            }
            mapElement={<div className={styles.mapElement} />}
            mapTypeId={mapTypeId}
            traffic={traffic}
            components={this.state.mapRef &&
              <Fragment>
                <MapComponents mapRef={this.state.mapRef} />
                <StreetViewPanorama
                  onVisibleChanged={this.onStreetViewVisibleChanged}
                />
                {!this.props.notificationsVisible && <MouseSelectShadow mapRef={this.state.mapRef} />}
              </Fragment>
            }
            onClick={!this.props.notificationsVisible ? ignoreIfOverlay(this.onSelectArea) : null}
            onMapLoad={this.onMapMounted}
            onIdle={this.onBoundsChanged}
            onBoundsChanged={this.updateViewport}
            center={viewport.center}
            zoom={viewport.zoom}
          />
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  mapTypeId: PropTypes.string,
  measureActive: PropTypes.bool,
  notificationsVisible: PropTypes.bool,
  searchFocused: PropTypes.bool,
  searchMarker: PropTypes.bool,
  selectArea: PropTypes.func,
  setSearchMarker: PropTypes.func,
  setStreetViewVisible: PropTypes.func,
  setViewport: PropTypes.func,
  streetView: PropTypes.object,
  traffic: PropTypes.bool,
  viewport: PropTypes.object
};

const mapStateToProps = state => {
  return {
    viewport: getViewport(state),
    searchMarker: getSearchMarker(state),
    searchFocused: getSearchFocused(state),
    streetView: getStreetView(state),
    mapTypeId: getMapTypeId(state),
    traffic: getTraffic(state),
    measureActive: getMeasureToolActive(state),
    loading: getLoading(state),
    notificationsVisible: getNotificationsVisible(state)
  };
};

export default connect(mapStateToProps, {
  setViewport, setStreetViewVisible, setSearchMarker, selectArea
})(Map);
