import {
  FILTERS_FETCH_SUCCESS,
  DATE_RANGE_FETCH_SUCCESS,
  TOGGLE_FILTER,
  SELECT_DATE_RANGE,
  SET_INITIAL_URL_FILTER,
  CLEAR_INITIAL_URL_FILTER
} from '../constants/action-types';
import axios from 'axios';

import { invalidateAreaSelection, requestAuthSecret } from './map-actions';

import { getApiRequestUrl } from '../constants/endpoints';

import { layerDataConfig } from '../utilities/map-data-config';

import { getMapData, getDevSecretParams } from '../selectors/map';


const fetchFilterStructureSuccess = payload => ({
  type: FILTERS_FETCH_SUCCESS,
  payload
});

export const fetchFilterStructure = () => (dispatch, getState) => {
  axios.get(
    getApiRequestUrl('layer'),
    { ...layerDataConfig, params: getDevSecretParams(getState()) }
  ).then(({ data }) => {
    dispatch(fetchFilterStructureSuccess(data));
  }).catch(error => {
    if (error.response && error.response.status === 403) {
      dispatch(requestAuthSecret());
    } else {
      throw (error);
    }
  });
};

const fetchDateRangesSuccess = payload => ({
  type: DATE_RANGE_FETCH_SUCCESS,
  payload
});

export const fetchDateRanges = () => (dispatch, getState) => {
  axios.get(
    getApiRequestUrl('daterange'),
    { ...layerDataConfig, params: getDevSecretParams(getState()) }
  ).then(({ data }) => {
    dispatch(fetchDateRangesSuccess(data));
  });
};

const toggleFilterAction = id => ({
  type: TOGGLE_FILTER,
  id
});

export const toggleFilter = id => (dispatch) => {
  dispatch(invalidateAreaSelection());
  dispatch(toggleFilterAction(id));
};

export const makeVisible = id => (dispatch, getState) => {
  const mapData = getMapData(getState());
  const ids = [id];
  while (ids.length > 0) {
    const top = ids.pop();
    if (!mapData[top].checked) {
      dispatch(toggleFilter(top));
    }
    if (mapData[top].parentId) {
      ids.push(mapData[top].parentId);
    }
  }
};

const selectDateRangeAction = index => ({
  type: SELECT_DATE_RANGE,
  index
});

export const selectDateRange = index => (dispatch) => {
  dispatch(selectDateRangeAction(index));
};

export const setInitialUrlFilter = () => dispatch => dispatch({ type: SET_INITIAL_URL_FILTER });
export const clearInitialUrlFilter = () => dispatch => dispatch({ type: CLEAR_INITIAL_URL_FILTER });
